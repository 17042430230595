// Globals
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Spinner, StyledSpinnerWrap } from '@/ui';
import { StyledTitles, PayoutListWrapper } from './PayoutDetailsListStyles';
import { StyledInfoTitles, StyledDividerBlock } from '../Styled';
import PayoutDetailsCard from '../PayoutDetailsCard/PayoutDetailsCard';
import RevenueTransferDetailsCard from '../FundingRoundDetailsCard/RevenueTransferDetailsCard';

// Modules
import { IPayoutDetails, RevenueTransferItem } from '@/models/payout.model';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Helpers
import { useInfiniteScroll } from '@/hooks';

interface IPayoutDetailsList {
  payout?: {
    items: IPayoutDetails[] | null;
    revenueTransfers: RevenueTransferItem[];
    hasMore: boolean;
  };
  isLoading: boolean;
  getNextPayouts: () => void;
}

const PayoutDetailsList = ({
  payout,
  isLoading,
  getNextPayouts,
}: IPayoutDetailsList) => {
  const { t } = useTranslation(['payout']);
  const loaderRef = useRef<HTMLDivElement>(null);

  const mappedPayoutList = useMemo(() => {
    return payout?.items?.map((data) => {
      return (
        <PayoutDetailsCard data={data} key={data?.id} isLoading={isLoading} />
      );
    });
  }, [payout]);

  const mappedRevenueTransferItems = useMemo(() => {
    return payout?.revenueTransfers?.map((revenueTransfer) => (
      <RevenueTransferDetailsCard
        data={revenueTransfer}
        key={`${revenueTransfer.transferType}_${revenueTransfer.network}_${revenueTransfer.currency}`}
      />
    ));
  }, [payout?.revenueTransfers]);

  useInfiniteScroll({
    callback: getNextPayouts,
    ref: loaderRef,
    isLoading,
    hasMore: !!payout?.hasMore,
  });

  return (
    <PayoutListWrapper>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('revenue_transfer_type')}</StyledTitles>
          <StyledTitles>{t('revenue_transfer_amount_title')}</StyledTitles>
          <StyledTitles>{t('payout_funding_round_network')}</StyledTitles>
          <StyledTitles>{t('payout_funding_round_currency')}</StyledTitles>
          <StyledTitles>{t('revenue_transfer_status_title')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedRevenueTransferItems}
      <StyledDividerBlock />

      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('payout_kol_title')}</StyledTitles>
          <StyledTitles>{t('payout_campaign_date_title')}</StyledTitles>
          <StyledTitles>{t('payout_project_name_title')}</StyledTitles>
          <StyledTitles>{t('payout_campaign_name_title')}</StyledTitles>
          <StyledTitles>{t('payout_payout_token_title')}</StyledTitles>
          <StyledTitles>{t('payout_network_title')}</StyledTitles>
          <StyledTitles>{t('payout_payout_amount_title')}</StyledTitles>
          <StyledTitles>{t('payout_document_title')}</StyledTitles>
          <StyledTitles>{t('payout_request_status_title')}</StyledTitles>
          <StyledTitles>{t('payout_finance_manager_title')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedPayoutList}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </PayoutListWrapper>
  );
};

export default PayoutDetailsList;
