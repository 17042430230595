import { IGetListParamsBase } from './api.model';
import { IUser } from '@/models/admin.model';
import { IKolsRankingItem } from '@/models/projects.model';

export enum SharedEndpoints {
  ROOT = '/admin',
}

export enum UserActionLogTypes {
  SIGNED_UP = 'SIGNED_UP',
  AFFILIATE_JOINED = 'AFFILIATE_JOINED',
}

export enum WithdrawalHistoryStatuses {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum SafeTransactionStatuses {
  PENDING = 'pending',
  SUCCESS = 'success',
  REJECTED = 'rejected',
  FAILED = 'failed',
}

export enum FormOfAddress {
  MR = 'mr',
  MRS = 'mrs',
  UNDEFINED = 'undefined',
}

export enum VerificationTypes {
  KYB = 'KYB',
  KYB_DEV = 'KYB_DEV',
  KYC = 'KYC',
  KYC_DEV = 'KYC_DEV',
}
export enum SeedHunterCreditRoles {
  KOL = 'kols',
  PARTNERS = 'partners',
}

export enum LegalAgreementRoles {
  KOL = 'kols',
  PARTNERS = 'partners',
  PROJECTS = 'projects',
}

export interface ICreditHistory {
  id: string;
  sk: string;
  userId: string;
  created: string;
  totalBalance: number;
  amount: number;
  title: string;
  adminEmail: string;
}

export interface IWithdrawalHistory {
  id: string;
  sk: string;
  recepientId: string;
  campaignId: string;
  fundingRoundId: string;
  campaignTitle: string;
  fundingRoundTitle: string;
  walletAddress: string;
  totalAffiliateWithdrawalAmount: number;
  marketingAffiliateCommissionSks: string[];
  affiliateWithdrawalTransaction: {
    id: string;
    sk: string;
    status: SafeTransactionStatuses;
  };
  status: WithdrawalHistoryStatuses;
  created: string;
  modified: string;
}

export interface IFundingRoundAffiliate {
  sk: string;
  type: string;
  recipientId: string;
  invitedUserId: string;
  invitedUserName: string;
  invitedUserRole: string;
  fundingRoundId: string;
  fundingRoundTitle: string;
  participationId: string;
  participationSk: string;
  fundingRoundRaisedAmount: number;
  participationAmount: number;
  affiliateCommission: number;
}

export enum ContactSourceEnum {
  TELEGRAM = 'TELEGRAM',
  WHATSAPP = 'WHATSAPP',
  PHONE = 'PHONE',
}

export interface IMainContact {
  source: ContactSourceEnum;
  contact: string;
}

// ============= DTO =============

export interface ISeedHunterCreditsListDTO extends IGetListParamsBase {
  role?: SeedHunterCreditRoles;
  id?: string;
}

export interface ICreditBonusDTO {
  id?: string;
  role?: SeedHunterCreditRoles;
  title?: string;
  amount?: number;
}

export interface ILegalAgreementsDTO {
  role?: LegalAgreementRoles;
  id?: string;
  types?: UserActionLogTypes[];
}

export interface IWithdrawalHistoryDTO {
  role: LegalAgreementRoles;
  id: string;
}

export interface IGetInvitedUsersDTO extends IGetListParamsBase {
  startId: string;
  userId: string;
}

export interface IFundingRoundAffiliateDTO {
  role: LegalAgreementRoles;
  id: string;
  limit?: number;
  startSk?: string;
}

// ============== Response ==============

export interface IGetInvitedUsersResponse {
  items: IUser[];
  hasMore: boolean;
}

export interface IGetKolsRankingResponse {
  items: IKolsRankingItem[];
  hasMore: boolean;
}

export interface ISeedHunterCreditsListResponse {
  items: ICreditHistory[];
  hasMore: boolean;
  totalBalance: number;
}

export interface IFundingRoundAffiliateResponse {
  items: IFundingRoundAffiliate[];
  hasMore: boolean;
}

export interface ILegalAgreementResponse {
  created: string;
  ipAddress: string;
  type: UserActionLogTypes;
}
