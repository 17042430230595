import { CampaignStatuses } from '@/models/campaigns.model';
import { IMainCampaignGoal } from '../helpers/types';

export const maxResourcesCount = 10;

export enum CampaignTabKeys {
  CAMPAIGN = 'campaign',
  RESOURCES = 'resources',
  MARKETING_DELIVERY = 'marketing-delivery',
  EARLY_ACCESS = 'early-access',
  PARTICIPATION = 'participation',
  KOL_RANKING = 'kol-ranking',
  RESULTS = 'results',
  PROOF_OF_DELIVERY = 'proof-of-delivery',
}

export enum CampaignActiveStatuses {
  MARKETING_DELIVERY = 'Marketing delivery',
  SIGN_UP = 'Sign up',
  COMPLETED = 'Completed',
}

export const statusesConfigs = [
  {
    value: CampaignStatuses.ACTIVE,
    label: 'Active',
    statuses: [CampaignStatuses.PAUSED, CampaignStatuses.ACTIVE],
  },
  {
    value: CampaignStatuses.PAUSED,
    label: 'Paused',
    statuses: [CampaignStatuses.PAUSED, CampaignStatuses.ACTIVE],
  },
  {
    value: CampaignStatuses.CANCELED,
    label: 'Canceled',
    statuses: [CampaignStatuses.PAUSED],
  },
  {
    value: CampaignStatuses.ARCHIVED,
    label: 'Archived',
    statuses: [CampaignStatuses.CANCELED],
  },
  {
    value: CampaignStatuses.WAITING_FOR_PAYMENT,
    label: 'Waiting for payment',
    statuses: [CampaignStatuses.WAITING_FOR_PAYMENT],
  },
  {
    value: CampaignStatuses.IN_VERIFICATION,
    label: 'In verification',
    statuses: [CampaignStatuses.IN_VERIFICATION],
  },
  {
    value: CampaignStatuses.COMPLETED,
    label: 'Completed',
    statuses: [CampaignStatuses.COMPLETED],
  },
  {
    value: CampaignStatuses.PENDING,
    label: 'Pending',
    statuses: [CampaignStatuses.PENDING],
  },
  {
    value: CampaignStatuses.EXPIRED,
    label: 'Expired',
    statuses: [CampaignStatuses.EXPIRED],
  },
  {
    value: CampaignStatuses.PROJECT_PAUSED,
    label: 'Paused (project)',
    statuses: [CampaignStatuses.PROJECT_PAUSED],
  },
  {
    value: CampaignStatuses.NOT_VERIFIED,
    label: 'Not verified',
    statuses: [CampaignStatuses.NOT_VERIFIED],
  },
  {
    value: CampaignStatuses.DELIVERY_FINISHED,
    label: 'Delivery Finished',
    statuses: [CampaignStatuses.DELIVERY_FINISHED],
  },
];

export const marketingDeliveryOptions = [
  {
    value: 'Total',
    label: 'Total',
  },
];

export const currentWeekOption = [
  {
    value: 'Current Week',
    label: 'Current Week',
  },
];

export const CAMPAIGN_GOALS_WITH_SOCIALS = new Set([
  IMainCampaignGoal.ENGAGEMENT,
  IMainCampaignGoal.FOLLOWER,
]);

export const ACTIVE_SAVE_BUTTON = new Set([
  CampaignTabKeys.CAMPAIGN,
  CampaignTabKeys.RESOURCES,
  CampaignTabKeys.MARKETING_DELIVERY,
]);

export const EDITABLE_STATUSES = new Set([
  CampaignStatuses.PAUSED,
  CampaignStatuses.ACTIVE,
  CampaignStatuses.WAITING_FOR_PAYMENT,
  CampaignStatuses.IN_VERIFICATION,
  CampaignStatuses.PENDING,
  CampaignStatuses.NOT_VERIFIED,
]);
