// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

// Components
import {
  StyledBadge,
  StyledOverviewWrap,
  StyledRow,
  StyledTitle,
  StyledStatus,
  StyledOverviewDetails,
  StyledBlockWrap,
  StyledBlock,
  HorizontalDivider,
  VerticalDivider,
  StyledLabel,
  StyledSubInfoBlock,
} from './CampaignResultsStyles';
import { Progress } from '@/ui';
import CampaignRates from './CampaignRates';

// Models
import { ICampaign, IParticipationSummary } from '@/models/campaigns.model';
import { IDeliveryStatistics } from '@/models/marketingDelivery.model';

// Modules
import { useIsMobile } from '@/modules/Adaptive';

// Helpers
import {
  getDateString,
  getPercent,
  numberWithCommas,
  subtractCommission,
} from '@/utils';
import { getActiveCampaignStatus } from '../../helpers';
import { DATE_FORMATS } from '@/constants';

type TCampaignOverview = {
  campaign: ICampaign;
  participationSummary: IParticipationSummary;
  deliveryStatistics: IDeliveryStatistics;
};

const CampaignOverview = ({
  campaign,
  participationSummary,
  deliveryStatistics,
}: TCampaignOverview) => {
  const { t } = useTranslation(['campaigns']);
  const { isMobile } = useIsMobile();

  const startDate = getDateString(
    campaign?.marketingStartDate ?? '',
    DATE_FORMATS.SLASH_DATE,
  );
  const endDate = getDateString(
    campaign?.marketingEndDate ?? '',
    DATE_FORMATS.SLASH_DATE,
  );
  const totalDaysAmount = dayjs(campaign?.marketingEndDate).diff(
    dayjs(campaign?.marketingStartDate),
    'day',
  );
  const daysLeft = dayjs(campaign?.marketingEndDate).diff(dayjs(), 'day');
  const daysSpend = totalDaysAmount - daysLeft;
  const budgetWithoutCommission = subtractCommission(
    campaign?.marketingBudget ?? 0,
    campaign?.commissionPercent,
  );
  const budgetPercent = getPercent(
    campaign?.usedBudget?.total ?? 0,
    budgetWithoutCommission ?? 0,
  );

  const status = getActiveCampaignStatus(
    campaign?.marketingStartDate,
    campaign?.marketingEndDate,
  );

  const renderItem = (
    label: string,
    headerValue: string,
    subLabelOne: string,
    subLabelTwo: string,
    subValueOne: string,
    subValueTwo: string,
    percent: number,
  ) => (
    <StyledBlock>
      <StyledRow>
        <StyledLabel>{label}</StyledLabel>
        <StyledLabel $isBold>{headerValue}</StyledLabel>
      </StyledRow>
      <Progress percent={percent} isPercentShown={false} />
      <StyledRow>
        <StyledSubInfoBlock>
          <div>{subLabelOne}</div>
          <StyledLabel $isBold>{subValueOne}</StyledLabel>
        </StyledSubInfoBlock>
        <StyledSubInfoBlock>
          <div>{subLabelTwo}</div>
          <StyledLabel $isBold>{subValueTwo}</StyledLabel>
        </StyledSubInfoBlock>
      </StyledRow>
    </StyledBlock>
  );

  const divider = () => (
    <>{isMobile ? <HorizontalDivider /> : <VerticalDivider />}</>
  );

  return (
    <StyledOverviewWrap>
      <StyledRow>
        <StyledTitle>
          {t('campaign_report_campaign_overview_title')}
        </StyledTitle>
        <StyledStatus>
          <div>{t('campaign_contracts_status')}:</div>
          <StyledBadge>{status}</StyledBadge>
        </StyledStatus>
      </StyledRow>
      <StyledOverviewDetails>
        <StyledBlockWrap>
          {renderItem(
            t('campaign_overview_period'),
            t('campaign_overview_days_left', {
              days: daysLeft >= 0 ? daysLeft : 0,
            }),
            t('campaign_overview_start_date'),
            t('campaign_overview_end_date'),
            startDate,
            endDate,
            getPercent(daysSpend ?? 0, totalDaysAmount ?? 0),
          )}
          {divider()}
          {renderItem(
            t('campaign_overview_posts'),
            t('campaign_overview_percent_amount', {
              amount: getPercent(
                deliveryStatistics?.deliveredPosts ?? 0,
                deliveryStatistics?.plannedPosts ?? 0,
              ),
            }),
            t('campaign_overview_planned'),
            t('campaign_overview_delivered'),
            numberWithCommas(deliveryStatistics?.plannedPosts ?? 0),
            numberWithCommas(deliveryStatistics?.deliveredPosts ?? 0),
            getPercent(
              deliveryStatistics?.deliveredPosts ?? 0,
              deliveryStatistics?.plannedPosts ?? 0,
            ),
          )}
          {divider()}
          {renderItem(
            t('campaign_overview_budget'),
            t('campaign_overview_percent_amount', { amount: budgetPercent }),
            t('campaign_overview_planned'),
            t('campaign_overview_booked'),
            t('campaign_overview_amount', {
              amount: numberWithCommas(campaign?.marketingBudget ?? 0),
            }),
            t('campaign_overview_amount', {
              amount: numberWithCommas(campaign?.usedBudget?.total ?? 0),
            }),
            budgetPercent,
          )}
        </StyledBlockWrap>
        <HorizontalDivider />
        <CampaignRates participationSummary={participationSummary} />
      </StyledOverviewDetails>
    </StyledOverviewWrap>
  );
};

export default CampaignOverview;
