import omit from 'lodash.omit';
import { ApiResponse, httpGet, httpPost } from './httpClient';

import {
  IGetFundingRoundListDTO,
  IFundingRoundListResponse,
  FundingRoundEndpoints,
  IFundingRound,
  IUpdateFundingDetailsDTO,
  IFundingContractDTO,
  IFundingContract,
  IFundingRoundParticipation,
  IManualConfirmFundingRoundPaymentDTO,
  IFundingRoundSlugDTO,
} from '@/models/fundingRound.model';
import { IGetDocumentPreviewDataResponse } from '@/models/documents.model';

class FundingRoundAPI {
  static getFundingRoundList(
    data: IGetFundingRoundListDTO,
  ): ApiResponse<IFundingRoundListResponse> {
    return httpGet<IFundingRoundListResponse>(
      FundingRoundEndpoints.FUNDING_ROUND,
      {
        ...data,
      },
    );
  }

  static getFundingDetails(id: string): ApiResponse<IFundingRound> {
    return httpGet<IFundingRound>(
      `${FundingRoundEndpoints.FUNDING_ROUND}/${encodeURI(id)}`,
    );
  }

  static getFundingRoundParticipations(
    id: string,
  ): ApiResponse<IFundingRoundParticipation[]> {
    return httpGet<IFundingRoundParticipation[]>(
      `${FundingRoundEndpoints.FUNDING_ROUND}/${encodeURI(id)}${
        FundingRoundEndpoints.PARTICIPATIONS
      }`,
    );
  }

  static updateFundingDetails(data: IUpdateFundingDetailsDTO) {
    return httpPost<IUpdateFundingDetailsDTO, IFundingRound>(
      `${FundingRoundEndpoints.FUNDING_ROUND}/${encodeURI(data.id ?? '')}`,
      omit(data, 'id'),
    );
  }

  static generateReleaseAgreement(id: string) {
    return httpPost<string, IFundingRound>(
      `${FundingRoundEndpoints.FUNDING_ROUND}/${encodeURI(
        id ?? '',
      )}/release-agreement`,
    );
  }

  static updateFundingSlug(data: IFundingRoundSlugDTO) {
    return httpPost<IFundingRoundSlugDTO, IFundingRound>(
      `${FundingRoundEndpoints.FUNDING_ROUND}/${encodeURI(data.id ?? '')}/slug`,
      omit(data, 'id'),
    );
  }

  static updateFundingContract(data: IFundingContractDTO) {
    return httpPost<IFundingContractDTO, IFundingContractDTO>(
      `${FundingRoundEndpoints.FUNDING_ROUND}/${data.id}/contract-information/${data.documentType}`,
      omit(data, ['documentType', 'id']),
    );
  }

  static getFundingContract(
    data: Partial<IFundingContract>,
  ): ApiResponse<IFundingContract> {
    return httpGet<IFundingContract>(
      `${FundingRoundEndpoints.FUNDING_ROUND}/${data.id}/contract-information/${data.documentType}`,
    );
  }

  static getFundingContractPreview(
    data: Partial<IFundingContract>,
  ): ApiResponse<IGetDocumentPreviewDataResponse> {
    return httpGet<IGetDocumentPreviewDataResponse>(
      `${FundingRoundEndpoints.FUNDING_ROUND}/${data.id}/contract-information/${data.documentType}/${data.signingType}/preview`,
    );
  }

  static manualConfirmFundingRoundPayment(
    data: IManualConfirmFundingRoundPaymentDTO,
  ): ApiResponse<IFundingRoundParticipation> {
    return httpPost<
      IManualConfirmFundingRoundPaymentDTO,
      IFundingRoundParticipation
    >(`${FundingRoundEndpoints.FUNDING_ROUND}/transactions/verify`, data);
  }

  static fundingRoundCompletion(
    fundingRoundId: string,
  ): ApiResponse<IFundingRound> {
    return httpPost(
      `${FundingRoundEndpoints.FUNDING_ROUND}/${encodeURI(
        fundingRoundId ?? '',
      )}/complete`,
    );
  }
}

export default FundingRoundAPI;
