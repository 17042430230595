import {
  DocumentTypes,
  IClickWrapTemplateData,
  ICreateClickWrapTemplateDTO,
  DocumentsEndpoints,
  IFundingContractInfoDocumentDTO,
  IGetDocumentPreviewDataResponse,
  ISignClickContractDTO,
} from '@/models/documents.model';
import { ApiResponse, httpGet, httpPost } from './httpClient';

class DocumentsAPI {
  static getClickWrapTemplates(): ApiResponse<IClickWrapTemplateData[]> {
    return httpGet<IClickWrapTemplateData[]>(
      DocumentsEndpoints.GET_CLICKWRAP_TEMPLATES,
    );
  }

  static signClickContract({
    documentId,
    termsAccepted,
  }: ISignClickContractDTO): ApiResponse<void> {
    return httpPost(
      `/admin/clickwrap-agreement/${encodeURIComponent(documentId)}/approve`,
      { termsAccepted },
    );
  }

  static getFundingContractInformationDocument(
    data: IFundingContractInfoDocumentDTO,
  ): ApiResponse<IGetDocumentPreviewDataResponse> {
    return httpGet<IGetDocumentPreviewDataResponse>(
      `${DocumentsEndpoints.FUNDING_ROUND}/${encodeURIComponent(
        data.id,
      )}/contract-information/${data.documentId}/${data.signingType}`,
    );
  }

  static getClickWrapTemplate(
    type: DocumentTypes,
  ): ApiResponse<IClickWrapTemplateData> {
    return httpGet<IClickWrapTemplateData>(
      `${DocumentsEndpoints.GET_CLICKWRAP_TEMPLATES}/${type}`,
    );
  }

  static createClickWrapTemplate(
    data: ICreateClickWrapTemplateDTO,
  ): ApiResponse<IClickWrapTemplateData> {
    const { templateType, htmlContent } = data;
    return httpPost(
      `${DocumentsEndpoints.GET_CLICKWRAP_TEMPLATES}/${templateType}`,
      { htmlContent },
    );
  }
}

export default DocumentsAPI;
