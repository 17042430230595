import { IGetListParamsBase } from './api.model';
import { ISocialChannels } from './campaigns.model';
import { IDocumentData } from '@/models/documents.model';
import { CryptoCurrenciesEnum, WalletNetworksEnum } from '@/models/web3.model';

export enum FundingRoundEndpoints {
  FUNDING_ROUND = '/admin/funding-rounds',
  PARTICIPATIONS = '/participations',
}
export enum FundingRoundStatuses {
  PENDING = 'pending',
  WAITING_FOR_APPROVAL = 'waiting_for_approval',
  ACTIVE = 'active',
  FINISHED = 'finished',
  COMPLETED = 'completed',
  PAUSED = 'paused',
  PROJECT_PAUSED = 'project_paused',
  CANCELED = 'canceled',
  ARCHIVED = 'archived',
}

export enum FundingRoundParticipationStatus {
  PENDING = 'pending',
  WAITING_FOR_PAYMENT = 'waiting_for_payment',
  WAITING_FOR_CONFIRMATION = 'waiting_for_confirmation',
  PAYMENT_IN_PROGRESS = 'payment_in_progress',
  NEED_VERIFICATION = 'need_verification',
  ACCEPTED = 'accepted',
  EXPIRED = 'expired',
}

export enum FundingRoundParticipationTransactionStatus {
  PENDING = 'pending',
  FAILED = 'failed',
  SUCCESS = 'success',
  WAITING_FOR_APPROVAL = 'waiting_for_approval',
}

export enum FundingRoundPhases {
  PRE_SEED = 'pre_seed',
  SEED = 'seed',
  PRIVATE = 'private',
}

export const initialFundingRound = {
  title: undefined,
  description: undefined,
  plannedTGEDate: undefined,
  endDate: undefined,
  tokenomics: undefined,
  pitchDeck: undefined,
  fundingRoundBanner: undefined,
  tokenImage: undefined,
  tokenName: undefined,
  phase: undefined,
  totalTokensSupply: undefined,
  tokensAmountForSelectedPhase: undefined,
  hardCap: undefined,
  tokensAmountToManage: undefined,
  pricePerToken: undefined,
  publicPricePerToken: undefined,
  seedHunterTokenAllocationPercent: undefined,
  TGEUnlock: undefined,
  vestingPeriod: undefined,
  lockUpVestingSummary: undefined,
  progressBarAdditionalPercentage: undefined,
  walletNetwork: undefined,
  walletCurrency: undefined,
  walletAddress: undefined,
};

export const initialFundingContract = {
  additionalDefinitions: undefined,
  excludedJurisdictions: undefined,
};

export interface IAdditionalResources {
  fileName?: string;
  link?: string;
}

export interface IQuestionnaire {
  elevatorPitch: string;
  tgeDate: string;
  tokenomics: string;
  tokenomicsMetricsReview: string;
  fundingStage: string;
  roadmapOutline: string;
  marketingStrategy: string;
  marketAnalysis: string;
  competitiveLandscape: string;
  teamsAndPartners: string;
  additionalDocumentation: string[];
}

export interface IFundingRoundNFTBenefits {
  earlyAccess?: boolean;
  higherTicket?: boolean;
  nftOnly?: boolean;
}

export interface IFundingRound {
  id?: string;
  projectId?: string;
  projectTitle?: string;
  projectSubTitle?: string;
  projectDescription?: string;
  questionnaireLink?: string;
  projectImage?: string;
  projectMainGeographyTitle?: string;
  projectSecondaryGeographyTitle?: string;
  projectMainCommunityTitle?: string;
  projectSecondaryCommunityTitle?: string;
  projectWebsite?: string;
  projectWhitepaper?: string;
  projectSocialChannel?: ISocialChannels[];
  status?: FundingRoundStatuses;
  isInternal?: boolean;
  title?: string;
  description?: string;
  plannedTGEDate?: string;
  lockUpVestingSummary?: string;
  startDate?: string;
  endDate?: string;
  tokenomics?: string;
  pitchDeck?: string;
  tokenImage?: string;
  fundingRoundBanner?: string;
  additionalResources?: IAdditionalResources[];
  roadMap?: string;
  involved?: string;
  marketingStrategy?: string;
  slug?: string;
  usps?: string;
  tokenMetrics?: string;
  tokenName?: string;
  phase?: FundingRoundPhases;
  totalTokensSupply?: number;
  tokensAmountForSelectedPhase?: number;
  hardCap?: number;
  raisedAmount?: number;
  fundingTarget?: string;
  fdv?: number;
  initialMarketingCap?: number;
  pricePerToken?: number;
  publicPricePerToken?: number;
  tokensAmountToManage?: number;
  seedHunterTokenAllocationPercent?: number;
  progressBarAdditionalPercentage?: number;
  TGEUnlock?: string;
  vestingPeriod?: string;
  timeframeToDeliverMilestones?: string;
  questionnaire?: IQuestionnaire;
  walletNetwork?: WalletNetworksEnum;
  walletCurrency?: CryptoCurrenciesEnum;
  walletAddress?: string;
  benefits?: IFundingRoundNFTBenefits;
}

// ============= DTO =============

export interface IUpdateFundingDetailsDTO {
  id?: string;
  status?: FundingRoundStatuses;
  isInternal?: boolean;
  title?: string;
  description?: string;
  startDate?: string;
  questionnaireLink?: string;
  endDate?: string;
  plannedTGEDate?: string;
  tokenomics?: string;
  pitchDeck?: string;
  tokenImage?: string;
  fundingRoundBanner?: string;
  additionalResources?: IAdditionalResources[];
  roadMap?: string;
  slug?: string;
  involved?: string;
  marketingStrategy?: string;
  usps?: string;
  tokenMetrics?: string;
  tokenName?: string;
  lockUpVestingSummary?: string;
  phase?: FundingRoundPhases;
  totalTokensSupply?: number;
  tokensAmountForSelectedPhase?: number;
  hardCap?: number;
  initialMarketingCap?: number;
  pricePerToken?: number;
  publicPricePerToken?: number;
  tokensAmountToManage?: number;
  seedHunterTokenAllocationPercent?: number;
  progressBarAdditionalPercentage?: number;
  TGEUnlock?: string;
  vestingPeriod?: string;
  reason?: string;
  walletNetwork?: WalletNetworksEnum;
  walletCurrency?: CryptoCurrenciesEnum;
  walletAddress?: string;
  benefits?: IFundingRoundNFTBenefits;
}

export interface IFundingRoundSlugDTO {
  id?: string;
  slug: string;
}

export interface IGetFundingRoundListDTO extends IGetListParamsBase {
  search?: string;
}

export interface IFundingContractItem {
  title: string;
  description: string;
}

export interface IFundingContractDTO {
  id?: string;
  documentType?: string;
  isPreviewed?: boolean;
  additionalDefinitions?: string;
  excludedJurisdictions?: string;
  items?: IFundingContractItem[];
}

export interface IManualConfirmFundingRoundPaymentDTO {
  transactionPK: string;
  transactionSK: string;
  status:
    | FundingRoundParticipationTransactionStatus.SUCCESS
    | FundingRoundParticipationTransactionStatus.FAILED;
  reason?: string;
}

export interface IFundingRoundParticipation {
  id: string;
  sk: string;
  acceptedAt: string;
  projectId: string;
  participantId: string;
  created: string;
  fundingRoundId: string;
  affiliateCommissionRecipientId: string;
  status: FundingRoundParticipationStatus;
  alias: string;
  mainCommunity: string;
  secondaryCommunity: string;
  mainGeography: string;
  secondaryGeography: string;
  firstName: string;
  lastName: string;
  fundingRoundTitle: string;
  fundingRoundBanner: string;
  fundingRoundPlannedTGEDate: string;
  fundingRoundStartDate: string;
  fundingRoundEndDate: string;
  projectTitle: string;
  projectImage: string;
  projectSubtitle: string;
  tokenImage: string;
  pricePerToken: number;
  totalParticipationAmount: number;
  walletAddressForReceivingTokens: string;
  participantDocuments: IDocumentData[];
  currency: CryptoCurrenciesEnum;
  network: WalletNetworksEnum;
  transaction: {
    id: string;
    sk: string;
    status: FundingRoundParticipationTransactionStatus;
  };
}

// ============== Response ==============

export interface IFundingRoundListResponse {
  items: IFundingRound[];
  hasMore: boolean;
}

export interface IFundingContract {
  id?: string;
  documentType?: string;
  signingType?: string;
  additionalDefinitions?: string;
  excludedJurisdictions?: string;
  items?: IFundingContractItem[];
}
