import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApiError } from '@/models/apiError.model';
import { CAMPAIGNS_SLICE_NAME } from './models';

import { CampaignsAPI, ProjectsAPI } from '@/api';
import { formatApiError } from '@/utils';
import {
  ICampaign,
  ICampaignDTO,
  ICampaignDetailsResponse,
  ICampaignKols,
  ICampaignsListResponse,
  IGetCampaignParticipantsOverviewResponse,
  IGetCampaignsListDTO,
  IGetParticipationDTO,
  IParticipationResponse,
  IParticipationSummary,
  IUpdateCampaignsStatusDTO,
} from '@/models/campaigns.model';
import { IGetKolsRankingResponse } from '@/models/sharedProfile.model';
import { IGetKolsRankingDataDTO } from '@/models/projects.model';

export const getCampaignsList = createAsyncThunk<
  ICampaignsListResponse,
  IGetCampaignsListDTO,
  { serializedErrorType: IApiError }
>(
  `${CAMPAIGNS_SLICE_NAME}/getCampaignsList`,
  async (data: IGetCampaignsListDTO) => {
    const response = await CampaignsAPI.getCampaignsList({
      ...data,
      limit: 10,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getCampaignDetails = createAsyncThunk<
  ICampaignDetailsResponse,
  string,
  { serializedErrorType: IApiError }
>(
  `${CAMPAIGNS_SLICE_NAME}/getCampaignDetails`,
  async (id: string) => {
    const response = await CampaignsAPI.getCampaignDetails(id);
    return response;
  },
  { serializeError: formatApiError },
);

export const updateCampaignStatus = createAsyncThunk<
  ICampaign,
  IUpdateCampaignsStatusDTO,
  { serializedErrorType: IApiError }
>(
  `${CAMPAIGNS_SLICE_NAME}/updateCampaignStatus`,
  async (data) => {
    const response = await CampaignsAPI.updateCampaignStatus(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const updateCampaign = createAsyncThunk<
  ICampaign,
  ICampaignDTO,
  { serializedErrorType: IApiError }
>(
  `${CAMPAIGNS_SLICE_NAME}/updateCampaign`,
  async (data) => {
    const response = await CampaignsAPI.updateCampaign(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getCampaignParticipations = createAsyncThunk<
  IParticipationResponse,
  IGetParticipationDTO,
  { serializedErrorType: IApiError }
>(
  `${CAMPAIGNS_SLICE_NAME}/getCampaignParticipations`,
  async (data) => {
    const response = await CampaignsAPI.getCampaignParticipations({
      ...data,
      limit: 10,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getCampaignParticipationOverview = createAsyncThunk<
  IGetCampaignParticipantsOverviewResponse,
  IGetParticipationDTO,
  { serializedErrorType: IApiError }
>(
  `${CAMPAIGNS_SLICE_NAME}/getCampaignParticipationOverview`,
  async (data) => {
    const response = await CampaignsAPI.getCampaignParticipationOverview({
      ...data,
      limit: 10,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getCampaignParticipationSummary = createAsyncThunk<
  IParticipationSummary,
  string,
  { serializedErrorType: IApiError }
>(
  `${CAMPAIGNS_SLICE_NAME}/getCampaignParticipationSummary`,
  async (id: string) => {
    const response = await CampaignsAPI.getCampaignParticipationSummary(id);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getCampaignKols = createAsyncThunk<
  ICampaignKols[],
  string,
  { serializedErrorType: IApiError }
>(
  `${CAMPAIGNS_SLICE_NAME}/getCampaignKols`,
  async (id: string) => {
    const response = await CampaignsAPI.getCampaignKols(id);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getKolsRankingData = createAsyncThunk<
  IGetKolsRankingResponse,
  IGetKolsRankingDataDTO,
  { serializedErrorType: IApiError }
>(
  `${CAMPAIGNS_SLICE_NAME}/getKolsRankingData`,
  async (data) => {
    const response = await ProjectsAPI.getKolsRankingData({
      ...data,
      limit: 10,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);

export const simulateKolsRanking = createAsyncThunk<
  IGetKolsRankingResponse,
  string,
  { serializedErrorType: IApiError }
>(
  `${CAMPAIGNS_SLICE_NAME}/simulateKolsRanking`,
  async (projectId) => {
    const response = await ProjectsAPI.simulateKolsRanking({ projectId });
    return response.data;
  },
  { serializeError: formatApiError },
);
