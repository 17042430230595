// Globals
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import debounce from 'lodash.debounce';

// Components
import {
  StyledArrowRightIcon,
  StyledButton,
  StyledPreviewWrapper,
  StyledStatus,
  StyledStatusWrapper,
} from './CampaignDetailsContainerStyles';
import { StyledPageWrapper, Title } from '@/ui';
import {
  showErrorToast,
  showSuccessToast,
  StatusChangeBlock,
  Tabs,
  useForm,
} from '@/components';
import {
  CampaignCardModal,
  CampaignDetailsModal,
  CampaignInfo,
  CampaignMarketingDelivery,
  CampaignResources,
  CampaignResults,
  Participation,
} from '../../components';
import { StyledButtonWrapper } from '../../components/Styled';
import EarlyAccess from '../../components/EarlyAccess/EarlyAccess';
import KOLsRankingContainer from '../KOLsRankingContainer/KOLsRankingContainer';

// Store
import {
  getCampaignDetails,
  getCampaignParticipationOverview,
  getCampaignParticipations,
  getCampaignParticipationSummary,
  updateCampaign,
  updateCampaignStatus,
} from '../../feature/actionCreators';
import { campaignsSliceSelector } from '../../feature/selectors';
import {
  resetCampaignsState,
  setCampaign,
  setCampaignResources,
} from '../../feature/slice';

// Modules
import { selectAverageSocialData } from '@/modules/Settings';
import { fileLoadingSelector, uploadCampaignFile } from '@/modules/Uploaders';
import { getNormalizedLink } from '@/modules/SharedProfile';
import { getEntityRequest } from '@/modules/Requests';
import { getLogs } from '@/modules/Logs';
import { usePermissions } from '@/modules/Permissions';
import {
  getDeliveryStatistics,
  ProofOfDeliveryContainer,
  selectDeliveryStatistics,
} from '@/modules/MarketingDelivery';

// Models
import { RouteEntitiesEnum } from '@/models/api.model';
import {
  CampaignStatuses,
  ICampaign,
  IParticipationSummary,
  IResources,
} from '@/models/campaigns.model';
import { LogEntities } from '@/models/logs.model';
import { AdminActionsEnum } from '@/models/adminsList.model';

// Hooks
import { useAppDispatch, useAppState, useModal, useTabs } from '@/hooks';

// Helpers
import {
  getCampaignStatusesConfig,
  getCampaignTabsConfig,
  normalizedCampaign,
} from '../../helpers';
import { ROUTES_PATHS } from '@/router';
import { CampaignTabKeys, statusesConfigs } from '../../constants';
import { getFileType, getUserId, isFormWithErrors } from '@/utils';
import { CampaignDTO } from '../../dtos';
import {
  CAMPAIGN_GOALS_WITH_SOCIALS,
  ACTIVE_SAVE_BUTTON,
  EDITABLE_STATUSES,
} from '../../constants/common';
import { ICampaignFields, IMainCampaignGoal } from '../../helpers/types';
import { IDeliveryStatistics } from '@/models/marketingDelivery.model';
import {
  exportCampaignsParticipantsOverviewById,
  selectDataExportsIsLoading,
} from '@/modules/DataExports';

const CampaignDetailsContainer = () => {
  const { t } = useTranslation(['projectsList']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id, section } = useParams();
  const [form] = useForm();
  const {
    isOpened: isCardModalOpened,
    openModal: openCardModal,
    closeModal: closeCardModal,
  } = useModal();
  const {
    isOpened: isDetailsModalOpen,
    openModal: openDetailsModal,
    closeModal: closeDetailsModal,
  } = useModal();
  const fileLoading = useAppState(fileLoadingSelector);
  const averageSocialData = useAppState(selectAverageSocialData);
  const deliveryStatistics = useAppState(selectDeliveryStatistics);
  const exportLoading = useAppState(selectDataExportsIsLoading);
  const {
    campaignDetails,
    isLoading,
    participationsData,
    campaignParticipantsOverview,
    socialChannels,
    participationSummary,
  } = useAppState(campaignsSliceSelector);
  const isChannelsShown = CAMPAIGN_GOALS_WITH_SOCIALS.has(
    campaignDetails?.campaignGoal as IMainCampaignGoal,
  );
  const earlyAccess = campaignDetails?.earlyAccess;
  const [permissionToEdit] = usePermissions(AdminActionsEnum.WRITE_CAMPAIGN);

  const isEditable =
    EDITABLE_STATUSES.has(campaignDetails?.status as CampaignStatuses) &&
    permissionToEdit;
  const isButtonShown = ACTIVE_SAVE_BUTTON.has(section as CampaignTabKeys);

  const getRedirectUrl = (key: string) =>
    `${ROUTES_PATHS.CAMPAIGNS}/${encodeURIComponent(id ?? '')}/${key}`;
  const { onChange } = useTabs(CampaignTabKeys.CAMPAIGN, getRedirectUrl);

  const navigateToProjectsList = () => {
    navigate(ROUTES_PATHS.CAMPAIGNS);
  };

  const handleChangeStatus = useCallback(
    async (description: string, status?: string) => {
      const campaignDTO = new CampaignDTO(
        campaignDetails as ICampaign,
        socialChannels,
        campaignDetails?.resources as IResources[],
      );

      await dispatch(
        updateCampaignStatus({
          ...campaignDTO,
          reason: description,
          status: status as CampaignStatuses,
          id: getUserId(campaignDetails?.id ?? ''),
        }),
      ).unwrap();
      dispatch(
        getLogs({
          entity: LogEntities.CAMPAIGN,
          id: getUserId(campaignDetails?.id ?? ''),
        }),
      );
    },
    [dispatch, campaignDetails, socialChannels],
  );

  const handleChange = useCallback(
    async (values: ICampaign, allValues: ICampaign) => {
      dispatch(
        setCampaign({
          ...allValues,
          goalUrl: getNormalizedLink(
            allValues?.goalUrl ?? campaignDetails?.goalUrl ?? '',
          ),
          marketingEndDate: values?.marketingStartDate
            ? null
            : allValues?.marketingEndDate,
        }),
      );
      values?.marketingStartDate &&
        form?.setFieldValue(ICampaignFields.MARKETING_END_DATE, null);
    },
    [dispatch, campaignDetails],
  );

  const handleResourceChange = useCallback(
    async (values: ICampaign) => {
      const key = Object.keys(values)[0];
      const value = Object.values(values)[0] as unknown as File;
      let handledValue = null;

      if (value) {
        handledValue = await dispatch(
          uploadCampaignFile({
            fileType: getFileType(value),
            value,
            targetId: getUserId(campaignDetails?.id ?? ''),
          }),
        ).unwrap();
      }

      if (key === ICampaignFields.RESOURCES) {
        dispatch(
          setCampaignResources({
            ...campaignDetails,
            [key]: [
              {
                fileName: value.name,
                link: handledValue,
              },
            ],
          }),
        );
      } else {
        dispatch(setCampaign({ [key]: handledValue }));
        form.setFieldValue(ICampaignFields.CAMPAIGN_BANNER, handledValue);
      }
    },
    [campaignDetails, dispatch],
  );

  const handleUpdateCampaign = async () => {
    const campaignDTO = new CampaignDTO(
      campaignDetails as ICampaign,
      socialChannels,
      campaignDetails?.resources as IResources[],
    );

    if (isFormWithErrors(form.getFieldsError())) {
      return showErrorToast({
        message: t('campaign_error_campaign_tab_validation', {
          ns: 'campaigns',
        }),
      });
    }

    if (!socialChannels?.length) {
      return showErrorToast({
        message: t('campaign_error_channels_not_distributed', {
          ns: 'campaigns',
        }),
      });
    }

    await dispatch(
      updateCampaign({ ...campaignDTO, id: getUserId(id ?? '') }),
    ).unwrap();
    dispatch(
      getLogs({
        entity: LogEntities.CAMPAIGN,
        id: getUserId(campaignDetails?.id ?? ''),
      }),
    );
    showSuccessToast({
      message: t('campaign_campaign_update_success', {
        ns: 'campaigns',
      }),
    });
  };

  const handleCampaignResultsExport = useCallback(() => {
    dispatch(exportCampaignsParticipantsOverviewById(getUserId(id ?? '')));
  }, [id]);

  const getNextParticipants = useCallback(
    debounce(() => {
      if (isLoading || !participationsData?.hasMore) return;
      const lastItem = participationsData?.items?.at(-1);
      dispatch(
        getCampaignParticipations({
          startId: lastItem?.id,
          startSk: lastItem?.sk,
          id: getUserId(id ?? ''),
        }),
      );
    }, 1000),
    [isLoading, participationsData?.hasMore, dispatch],
  );

  const getNextParticipantsOverview = useCallback(
    debounce(() => {
      if (isLoading || !campaignParticipantsOverview?.hasMore) return;
      const lastItem = campaignParticipantsOverview?.items?.at(-1);
      dispatch(
        getCampaignParticipationOverview({
          startId: lastItem?.id,
          startSk: lastItem?.sk,
          id: getUserId(id ?? ''),
        }),
      );
    }, 1000),
    [isLoading, campaignParticipantsOverview?.hasMore, dispatch],
  );

  const debouncedHandleChange = debounce(handleChange, 500);

  const getSectionContent = (section?: string) => {
    if (!campaignDetails) {
      return null;
    }
    switch (section) {
      case CampaignTabKeys.CAMPAIGN:
        return (
          <CampaignInfo
            formInstance={form}
            handleChange={debouncedHandleChange}
            campaign={normalizedCampaign(campaignDetails)}
            isChannelsShown={isChannelsShown}
            disabled={!isEditable}
          />
        );
      case CampaignTabKeys.RESOURCES:
        return (
          <CampaignResources
            handleChange={handleResourceChange}
            campaign={campaignDetails}
            formInstance={form}
            disabled={!isEditable}
            fileLoading={fileLoading}
          />
        );
      case CampaignTabKeys.MARKETING_DELIVERY:
        return (
          <CampaignMarketingDelivery
            formInstance={form}
            handleChange={debouncedHandleChange}
            campaign={normalizedCampaign(campaignDetails)}
            disabled={!isEditable}
            averageSocialData={averageSocialData}
          />
        );
      case CampaignTabKeys.EARLY_ACCESS:
        return (
          <EarlyAccess
            formInstance={form}
            handleChange={debouncedHandleChange}
            campaign={normalizedCampaign(campaignDetails)}
            disabled={!isEditable}
          />
        );
      case CampaignTabKeys.PARTICIPATION:
        return (
          <Participation
            participants={participationsData}
            isLoading={isLoading}
            getNextParticipant={getNextParticipants}
          />
        );
      case CampaignTabKeys.RESULTS:
        return (
          <CampaignResults
            campaign={campaignDetails}
            participationSummary={participationSummary as IParticipationSummary}
            participants={campaignParticipantsOverview}
            isLoading={isLoading}
            deliveryStatistics={deliveryStatistics as IDeliveryStatistics}
            getNextParticipantsOverview={getNextParticipantsOverview}
            exportLoading={exportLoading}
            handleCampaignResultsExport={handleCampaignResultsExport}
          />
        );
      case CampaignTabKeys.KOL_RANKING:
        return <KOLsRankingContainer />;
      case CampaignTabKeys.PROOF_OF_DELIVERY:
        return (
          <ProofOfDeliveryContainer
            projectId={campaignDetails?.projectId}
            isCampaign
          />
        );
    }
  };

  useEffect(() => {
    campaignDetails &&
      dispatch(
        getEntityRequest({
          entity: RouteEntitiesEnum.CAMPAIGN,
          targetId: getUserId(campaignDetails?.id ?? ''),
        }),
      );
  }, [campaignDetails]);

  useEffect(() => {
    if (id) {
      dispatch(getCampaignDetails(getUserId(id)));
    }
  }, [id, campaignDetails?.status]);

  useEffect(() => {
    if (section === CampaignTabKeys.RESULTS) {
      dispatch(getCampaignParticipationSummary(getUserId(id ?? '')));
      dispatch(
        getDeliveryStatistics({
          id: getUserId(campaignDetails?.projectId ?? ''),
          campaignId: getUserId(id ?? ''),
        }),
      );
    }
  }, [section, campaignDetails, id]);

  useEffect(
    () => (): void => {
      dispatch(resetCampaignsState());
    },
    [dispatch],
  );

  return (
    <StyledPageWrapper>
      <StyledStatusWrapper>
        <Title $type="h3">{campaignDetails?.title}</Title>
        <StyledStatus>
          <Title $type="h2">{t('projects_general_info_status')}</Title>
          <StatusChangeBlock
            status={campaignDetails?.status}
            handleRequest={handleChangeStatus}
            isEditable={permissionToEdit}
            statusesConfigs={getCampaignStatusesConfig(
              campaignDetails?.status ?? CampaignStatuses.PENDING,
              statusesConfigs,
            )}
            withStatus
          />
        </StyledStatus>
      </StyledStatusWrapper>
      <StyledButton type="ghost" onClick={navigateToProjectsList}>
        <StyledArrowRightIcon />
        {t('projects_list_details_go_back')}
      </StyledButton>

      <StyledPreviewWrapper>
        <StyledButton type="ghost" onClick={openCardModal}>
          {t('projects_list_preview_card')}
        </StyledButton>
        <StyledButton type="ghost" onClick={openDetailsModal}>
          {t('projects_list_preview_page')}
        </StyledButton>
      </StyledPreviewWrapper>

      <Tabs
        sections={getCampaignTabsConfig(!!earlyAccess)}
        activeTab={section}
        onChange={onChange}
      />
      {getSectionContent(section)}
      {isEditable && isButtonShown && (
        <StyledButtonWrapper>
          <StyledButton
            disabled={isLoading}
            type="primary"
            onClick={handleUpdateCampaign}
          >
            {t('campaigns_save_button', { ns: 'campaigns' })}
          </StyledButton>
        </StyledButtonWrapper>
      )}

      <CampaignCardModal
        campaign={campaignDetails as ICampaign}
        isOpened={isCardModalOpened}
        handleClose={closeCardModal}
      />
      <CampaignDetailsModal
        campaign={campaignDetails as ICampaign}
        isOpened={isDetailsModalOpen}
        handleClose={closeDetailsModal}
      />
    </StyledPageWrapper>
  );
};

export default CampaignDetailsContainer;
