// Globals
import React, { useCallback } from 'react';
import omit from 'lodash.omit';
import { useTranslation } from 'react-i18next';

// Components
import { StyledInfo } from './ContactPersonStyles';
import ContactPersonDetailsBlock from '../ContactPersonDetailsBlock/ContactPersonDetailsBlock';
import ContactPersonEditBlock from '../ContactPersonEditBlock/ContactPersonEditBlock';
import {
  EditButton,
  showErrorToast,
  showSuccessToast,
  useForm,
} from '@/components';

// Modules
import { DesktopOnly, MobileOnly } from '@/modules/Adaptive';

// Models
import { IProject } from '@/models/projects.model';

// Hooks | helpers
import { useAppDispatch, useBoolean } from '@/hooks';
import { checkFieldValidation, getUserId } from '@/utils';
import { updateProjectDetails } from '../../feature/actionCreators';
import { isProjectDisabled } from '../../helpers';

interface IContactPerson {
  projectDetails?: IProject | null;
  permissionToEdit?: boolean;
  isLoading: boolean;
}

const ContactPerson = ({
  projectDetails,
  permissionToEdit,
  isLoading,
}: IContactPerson) => {
  const { value: isEditActive, toggle: toggleIsEditActive } = useBoolean();
  const { t } = useTranslation(['projectsList']);
  const dispatch = useAppDispatch();
  const [form] = useForm();

  const handleSubmit = useCallback(
    async (values: IProject) => {
      await checkFieldValidation(values, form);

      try {
        projectDetails?.contactPerson &&
          (await dispatch(
            updateProjectDetails(
              omit(
                { ...values, id: getUserId(projectDetails.id ?? '') },
                'email',
              ),
            ),
          ).unwrap());
        toggleIsEditActive();
        showSuccessToast({
          message: t('projects_general_success_toast'),
        });
      } catch (e: unknown) {
        showErrorToast({
          message: t('projects_general_error_toast'),
        });
      }
    },
    [form, dispatch, projectDetails],
  );

  if (!projectDetails?.contactPerson) return null;
  return (
    <>
      <StyledInfo>
        {isEditActive ? (
          <ContactPersonEditBlock
            projectDetails={projectDetails}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            formInstance={form}
          />
        ) : (
          <ContactPersonDetailsBlock projectDetails={projectDetails} />
        )}
      </StyledInfo>

      {permissionToEdit && (
        <>
          <StyledInfo>
            <DesktopOnly>
              <EditButton
                isEditActive={isEditActive}
                setIsEditActive={toggleIsEditActive}
                isVisible={!isProjectDisabled(projectDetails?.status)}
              />
            </DesktopOnly>
          </StyledInfo>
          <MobileOnly>
            <EditButton
              isEditActive={isEditActive}
              setIsEditActive={toggleIsEditActive}
              position={'1rem'}
              isVisible={!isProjectDisabled(projectDetails?.status)}
            />
          </MobileOnly>
        </>
      )}
    </>
  );
};

export default ContactPerson;
