import {
  FundingRoundPhases,
  FundingRoundStatuses,
  IAdditionalResources,
  IFundingRound,
  IFundingRoundNFTBenefits,
  IUpdateFundingDetailsDTO,
} from '@/models/fundingRound.model';
import {
  defaultAdditionalPercentage,
  ENABLED_FUNDING_ROUND_TOKENOMICS_STATUSES,
} from '../constants';
import { CryptoCurrenciesEnum, WalletNetworksEnum } from '@/models/web3.model';

class FundingContractDTO implements IUpdateFundingDetailsDTO {
  isInternal?: boolean;
  title?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
  plannedTGEDate?: string;
  tokenomics?: string;
  pitchDeck?: string;
  tokenImage?: string;
  fundingRoundBanner?: string;
  additionalResources?: IAdditionalResources[];
  roadMap?: string;
  involved?: string;
  marketingStrategy?: string;
  usps?: string;
  tokenMetrics?: string;
  tokenName?: string;
  phase?: FundingRoundPhases;
  totalTokensSupply?: number;
  tokensAmountForSelectedPhase?: number;
  hardCap?: number;
  fdv?: number;
  initialMarketingCap?: number;
  pricePerToken?: number;
  publicPricePerToken?: number;
  tokensAmountToManage?: number;
  seedHunterTokenAllocationPercent?: number;
  TGEUnlock?: string;
  vestingPeriod?: string;
  reason?: string;
  lockUpVestingSummary?: string;
  progressBarAdditionalPercentage?: number;
  walletCurrency?: CryptoCurrenciesEnum;
  walletNetwork?: WalletNetworksEnum;
  walletAddress?: string;
  questionnaireLink?: string;
  benefits?: IFundingRoundNFTBenefits;

  constructor(values: IFundingRound, fundingRound: IFundingRound) {
    this.isInternal = values?.isInternal;
    this.title = values?.title;
    this.questionnaireLink = values?.questionnaireLink;
    this.description = values?.description;
    this.startDate = values?.startDate;
    this.endDate = values?.endDate;
    this.progressBarAdditionalPercentage =
      values?.progressBarAdditionalPercentage ??
      fundingRound?.progressBarAdditionalPercentage ??
      defaultAdditionalPercentage;
    this.plannedTGEDate = values?.plannedTGEDate;
    this.walletCurrency = values?.walletCurrency;
    this.walletNetwork = WalletNetworksEnum.POLYGON;
    this.walletAddress = values?.walletAddress;
    this.tokenomics = values?.tokenomics;
    this.pitchDeck = values?.pitchDeck;
    this.tokenImage = values?.tokenImage;
    this.fundingRoundBanner = values?.fundingRoundBanner;
    this.additionalResources = values?.additionalResources;
    this.roadMap = values?.roadMap;
    this.involved = values?.involved;
    this.marketingStrategy = values?.marketingStrategy;
    this.usps = values?.usps;
    this.tokenMetrics = values?.tokenMetrics;

    if (
      ENABLED_FUNDING_ROUND_TOKENOMICS_STATUSES.has(
        fundingRound?.status as FundingRoundStatuses,
      )
    ) {
      this.tokenName = values?.tokenName;
      this.phase = values?.phase;
      this.totalTokensSupply = values?.totalTokensSupply;
      this.tokensAmountForSelectedPhase = values?.tokensAmountForSelectedPhase;
      this.hardCap = values?.hardCap;
      this.tokensAmountToManage = values?.tokensAmountToManage;
      this.pricePerToken = values?.pricePerToken;
      this.seedHunterTokenAllocationPercent =
        values?.seedHunterTokenAllocationPercent;
      this.TGEUnlock = values?.TGEUnlock;
      this.vestingPeriod = values?.vestingPeriod;
      this.lockUpVestingSummary = values?.lockUpVestingSummary;
    }
    this.publicPricePerToken = values?.publicPricePerToken;

    this.benefits = values?.benefits;

    this.initialMarketingCap =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      values?.initialMarketingCap === '' ? 0 : values?.initialMarketingCap;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.fdv = values?.fdv === '' ? 0 : values?.fdv;
  }
}

export default FundingContractDTO;
