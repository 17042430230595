// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MessageType } from 'antd/es/message/interface';

// Components
import {
  StyledPopupWrap,
  StyledSubmitButton,
} from './FundingRefundDetailsCardStyles';
import { StyledTitle } from '../Styled';
import { Modal, ModalSize } from '@/components';

interface IPayoutTransferModal {
  isOpened: boolean;
  isEthereum?: boolean;
  isLoading: boolean;
  handleClose: () => void;
  startTransaction: () => void | MessageType | null;
  walletAddress?: string;
}

const PayoutTransferModal = ({
  isOpened,
  isLoading,
  handleClose,
  isEthereum,
  startTransaction,
  walletAddress,
}: IPayoutTransferModal) => {
  const { t } = useTranslation(['payout']);
  return (
    <Modal visible={isOpened} onCancel={handleClose} size={ModalSize.MEDIUM}>
      <StyledPopupWrap>
        {isEthereum ? (
          <>
            <StyledTitle>
              {t('payout_funding_round_ethereum_popup')}
            </StyledTitle>
            <span>{walletAddress}</span>
          </>
        ) : (
          <StyledTitle>{t('payout_popup_transfer')}</StyledTitle>
        )}
        <StyledSubmitButton
          type="primary"
          onClick={startTransaction}
          disabled={isLoading}
        >
          {t('payout_start_transaction_button')}
        </StyledSubmitButton>
      </StyledPopupWrap>
    </Modal>
  );
};

export default PayoutTransferModal;
