// Globals
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import omit from 'lodash.omit';

// Components
import { useForm } from 'antd/es/form/Form';
import {
  StyledInfoWrapper,
  StyledInfo,
  StyledButton,
} from './GeneralInfoStyles';
import { Text } from '@/ui';
import { EditButton, showErrorToast, showSuccessToast } from '@/components';
import GeneralInfoEditBlock from '../GeneralInfoEditBlock/GeneralInfoEditBlock';
import GeneralInfoDetailsBlock from '../GeneralInfoDetailsBlock/GeneralInfoDetailsBlock';

// Modules
import { DesktopOnly, MobileOnly } from '@/modules/Adaptive';
import { getOwnRequests } from '@/modules/Requests/feature/actionCreators';

// Models
import { IKOL } from '@/models/kolsList.model';
import { RequestersRoles } from '@/models/requests.model';
import { LogEntities } from '@/models/logs.model';
import { AdminActionsEnum } from '@/models/adminsList.model';

// Hooks | Utils
import { useAppDispatch, useBoolean } from '@/hooks';
import { checkFieldValidation, getUserId, validateWalletFields } from '@/utils';

// Modules
import { ProfileRequestList } from '@/modules/Requests';
import { GeneralInfoFieldsNames } from '@/modules/KolsList/helpers';
import { LogContainer, getLogs } from '@/modules/Logs';
import { ContractInfo } from '@/modules/SharedProfile';
import { usePermissions } from '@/modules/Permissions';

// Helpers
import { updateKOL } from '../../feature/actionCreators';
import { ROUTES_PATHS } from '@/router';

interface IGeneralInfo {
  kolDetails: IKOL | null;
  isLoading: boolean;
}

const GeneralInfo = ({ kolDetails, isLoading }: IGeneralInfo) => {
  const { t } = useTranslation(['kolsList']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { value: isEditActive, toggle: setIsEditActive } = useBoolean();
  const [permissionToEdit] = usePermissions(AdminActionsEnum.WRITE_KOL);
  const [form] = useForm();

  const navigateToRequests = () => {
    navigate(ROUTES_PATHS.REQUESTS);
  };

  useEffect(() => {
    kolDetails &&
      dispatch(
        getOwnRequests({
          role: RequestersRoles.KOL,
          id: getUserId(kolDetails?.id ?? ''),
        }),
      );
  }, [kolDetails]);

  const handleSubmit = useCallback(
    async (values: IKOL) => {
      await checkFieldValidation(values, form);
      const isWalletFieldsFilled =
        !!values[GeneralInfoFieldsNames.WALLET_ADDRESS] &&
        !!values[GeneralInfoFieldsNames.WALLET_CURRENCY];

      if (isWalletFieldsFilled) {
        const isValid = await validateWalletFields(
          form,
          dispatch,
          kolDetails?.id,
        );

        if (!isValid) return;
      }

      try {
        kolDetails &&
          (await dispatch(
            updateKOL({
              kol: omit(values, 'email'),
              id: getUserId(kolDetails.id ?? ''),
            }),
          ).unwrap());
        showSuccessToast({ message: t('kols_general_success_toast') });
        dispatch(
          getLogs({
            entity: LogEntities.KOL,
            id: getUserId(kolDetails?.id ?? ''),
          }),
        );
        setIsEditActive();
      } catch (e: unknown) {
        showErrorToast({ message: t('kols_general_error_toast') });
      }
    },
    [form, dispatch, kolDetails],
  );

  return (
    <>
      <StyledInfoWrapper>
        {isEditActive ? (
          <GeneralInfoEditBlock
            kolDetails={kolDetails}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            formInstance={form}
          />
        ) : (
          <GeneralInfoDetailsBlock kolDetails={kolDetails} />
        )}
        <StyledInfo>
          {permissionToEdit && (
            <DesktopOnly>
              <EditButton
                isEditActive={isEditActive}
                setIsEditActive={setIsEditActive}
              />
            </DesktopOnly>
          )}
        </StyledInfo>
        <StyledInfo>
          <StyledButton type="ghost" onClick={navigateToRequests}>
            <Text>{t('kols_go_to_requests')}</Text>
          </StyledButton>
          <ProfileRequestList />
        </StyledInfo>
        {permissionToEdit && (
          <MobileOnly>
            <EditButton
              isEditActive={isEditActive}
              setIsEditActive={setIsEditActive}
              position={'1rem'}
            />
          </MobileOnly>
        )}
      </StyledInfoWrapper>
      <ContractInfo userData={kolDetails} />
      <LogContainer entity={LogEntities.KOL} id={kolDetails?.id} />
    </>
  );
};

export default GeneralInfo;
