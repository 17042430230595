// Globals
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import debounce from 'lodash.debounce';

// Components
import { Title } from '@/ui';
import { GoBackButton } from '@/components';
import {
  PayoutDetailsList,
  StyledButton,
  StyledExportIcon,
  StyledHeader,
} from '../../components';

// Helpers | Utils
import { getUserId } from '@/utils';
import { useAppDispatch, useAppState } from '@/hooks';
import { payoutSliceSelector } from '../../feature/selectors';
import { getPayoutDetailsList } from '../../feature/actionCreators';
import { resetPayoutDetailsList } from '../../feature/slice';
import { PayoutTabKeys } from '../../constants';
import { useExportPayoutsData } from '../../hooks';

const CampaignDetailsContainer = () => {
  const { t } = useTranslation(['payout']);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { payoutListDetailsData, isLoading } = useAppState(payoutSliceSelector);
  const { isLoading: isExportLoading, handleExport } = useExportPayoutsData({
    activeTab: PayoutTabKeys.CAMPAIGN,
    campaignId: id,
  });

  const getNextPayoutList = useCallback(
    debounce(() => {
      if (isLoading || !payoutListDetailsData.hasMore) return;
      const lastItem =
        payoutListDetailsData?.items?.[
          payoutListDetailsData?.items?.length - 1
        ];
      dispatch(
        getPayoutDetailsList({
          campaignId: getUserId(id ?? ''),
          startId: lastItem?.id,
          startSk: lastItem?.sk,
        }),
      );
    }, 1000),
    [isLoading, payoutListDetailsData.hasMore, dispatch],
  );

  useEffect(
    () => (): void => {
      dispatch(resetPayoutDetailsList());
    },
    [dispatch],
  );

  return (
    <>
      <StyledHeader>
        <Title $type="h3">{t('payout_title')}</Title>
        <StyledButton
          type="primary"
          onClick={handleExport}
          loading={isExportLoading || isLoading}
        >
          {t('payout_export')}
          <StyledExportIcon />
        </StyledButton>
      </StyledHeader>
      <GoBackButton>{t('payout_go_back_button')}</GoBackButton>
      <PayoutDetailsList
        payout={payoutListDetailsData}
        isLoading={isLoading}
        getNextPayouts={getNextPayoutList}
      />
    </>
  );
};

export default CampaignDetailsContainer;
