// Globals
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledBadge,
  StyledButton,
  StyledLink,
  StyledInfoWrapper,
  StyledRoundArrowsIcon,
  StyledTransferButton,
} from './PayoutDetailsCardStyles';
import { showErrorToast } from '@/components';
import { StyledText, StyledArrowRightIcon } from '../Styled';

// Models
import { IPayoutDetails, PayoutStatuses } from '@/models/payout.model';

// Helpers
import { useAppDispatch } from '@/hooks';
import { payoutTransaction } from '../../feature/actionCreators';
import { CampaignStatuses } from '@/models/campaigns.model';

interface IPayoutActionsBadges {
  data: IPayoutDetails;
  isLoading: boolean;
}

const PayoutActionsBadges = ({ data, isLoading }: IPayoutActionsBadges) => {
  const { t } = useTranslation(['payout']);
  const dispatch = useAppDispatch();

  const startTransaction = useCallback(() => {
    if (data?.totalPayoutAmount === 0) {
      return showErrorToast({ message: t('payout_total_error') });
    }
    dispatch(payoutTransaction({ payoutId: data.id, payoutSk: data.sk }));
  }, [data]);

  if (data?.payoutTransactionStatus) {
    return (
      <StyledInfoWrapper>
        <StyledBadge $status={data?.payoutTransactionStatus}>
          <StyledText>
            {t(`payout_${data?.payoutTransactionStatus}_title`)}
          </StyledText>
        </StyledBadge>
        {data?.payoutTransactionStatus === PayoutStatuses.REJECTED && (
          <StyledButton
            type="ghost"
            onClick={startTransaction}
            disabled={isLoading}
          >
            {t('payout_try_again_button')}
            <StyledRoundArrowsIcon />
          </StyledButton>
        )}
      </StyledInfoWrapper>
    );
  }

  return (
    <StyledInfoWrapper>
      {data?.postContestRequestId ? (
        <StyledLink to={''} target="_blank">
          <StyledButton type="ghost">
            {t('payout_go_to_request_button')}
            <StyledArrowRightIcon />
          </StyledButton>
        </StyledLink>
      ) : (
        <StyledTransferButton
          type="primary"
          onClick={startTransaction}
          disabled={
            data?.campaignStatus === CampaignStatuses.DELIVERY_FINISHED ||
            isLoading
          }
        >
          {t('payout_start_transaction_button')}
        </StyledTransferButton>
      )}
    </StyledInfoWrapper>
  );
};

export default PayoutActionsBadges;
