import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApiError } from '@/models/apiError.model';
import { DATA_EXPORTS_SLICE_NAME } from './models';

import { DataExportsAPI } from '@/api';
import { formatApiError } from '@/utils';
import {
  IDataExportsResponse,
  IGetEntityListExportDTO,
  IGetPostsExportDTO,
} from '@/models/dataExports.model';

export const exportAllPayouts = createAsyncThunk<
  IDataExportsResponse,
  undefined,
  { serializedErrorType: IApiError }
>(
  `${DATA_EXPORTS_SLICE_NAME}/exportPayouts`,
  async () => {
    const response = await DataExportsAPI.exportAllPayouts();
    return response.data;
  },
  { serializeError: formatApiError },
);

export const exportFundingRoundsPayouts = createAsyncThunk<
  IDataExportsResponse,
  undefined,
  { serializedErrorType: IApiError }
>(
  `${DATA_EXPORTS_SLICE_NAME}/exportFundingRoundsPayouts`,
  async () => {
    const response = await DataExportsAPI.exportFundingRoundsPayouts();
    return response.data;
  },
  { serializeError: formatApiError },
);

export const exportFundingRoundsRefunds = createAsyncThunk<
  IDataExportsResponse,
  undefined,
  { serializedErrorType: IApiError }
>(
  `${DATA_EXPORTS_SLICE_NAME}/exportFundingRoundsRefunds`,
  async () => {
    const response = await DataExportsAPI.exportFundingRoundsRefunds();
    return response.data;
  },
  { serializeError: formatApiError },
);

export const exportPayoutsByCampaignId = createAsyncThunk<
  IDataExportsResponse,
  string,
  { serializedErrorType: IApiError }
>(
  `${DATA_EXPORTS_SLICE_NAME}/exportPayoutsByCampaignId`,
  async (campaignId: string) => {
    const response = await DataExportsAPI.exportPayoutsByCampaignId(campaignId);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const exportCampaignsParticipantsOverviewById = createAsyncThunk<
  IDataExportsResponse,
  string,
  { serializedErrorType: IApiError }
>(
  `${DATA_EXPORTS_SLICE_NAME}/exportCampaignsParticipantsOverviewById`,
  async (id: string) => {
    const response =
      await DataExportsAPI.exportCampaignsParticipantsOverviewById(id);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const exportFundingRefundById = createAsyncThunk<
  IDataExportsResponse,
  string,
  { serializedErrorType: IApiError }
>(
  `${DATA_EXPORTS_SLICE_NAME}/exportFundingRefundById`,
  async (fundingRoundId: string) => {
    const response = await DataExportsAPI.exportFundingRefundById(
      fundingRoundId,
    );
    return response.data;
  },
  { serializeError: formatApiError },
);

export const exportKolsList = createAsyncThunk<
  IDataExportsResponse,
  IGetEntityListExportDTO,
  { serializedErrorType: IApiError }
>(
  `${DATA_EXPORTS_SLICE_NAME}/exportPayoutsByCampaignId`,
  async (data: IGetEntityListExportDTO) => {
    const response = await DataExportsAPI.exportKolsList(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const exportProjectsList = createAsyncThunk<
  IDataExportsResponse,
  IGetEntityListExportDTO,
  { serializedErrorType: IApiError }
>(
  `${DATA_EXPORTS_SLICE_NAME}/exportPayoutsByCampaignId`,
  async (data: IGetEntityListExportDTO) => {
    const response = await DataExportsAPI.exportProjectsList(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const exportKOLsRankingList = createAsyncThunk<
  IDataExportsResponse,
  string,
  { serializedErrorType: IApiError }
>(
  `${DATA_EXPORTS_SLICE_NAME}/exportKOLsRankingList`,
  async (data: string) => {
    const response = await DataExportsAPI.exportKOLsRankingList(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const exportFundingReport = createAsyncThunk<
  IDataExportsResponse,
  string,
  { serializedErrorType: IApiError }
>(
  `${DATA_EXPORTS_SLICE_NAME}/exportFundingReport`,
  async (data: string) => {
    const response = await DataExportsAPI.exportFundingReport(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const exportCampaignReport = createAsyncThunk<
  IDataExportsResponse,
  string,
  { serializedErrorType: IApiError }
>(
  `${DATA_EXPORTS_SLICE_NAME}/exportCampaignReport`,
  async (data: string) => {
    const response = await DataExportsAPI.exportCampaignReport(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const exportPostsByCampaignId = createAsyncThunk<
  IDataExportsResponse,
  IGetPostsExportDTO,
  { serializedErrorType: IApiError }
>(
  `${DATA_EXPORTS_SLICE_NAME}/exportPostsByCampaignId`,
  async (data: IGetPostsExportDTO) => {
    const response = await DataExportsAPI.exportPostsByCampaignId(data);
    return response.data;
  },
  { serializeError: formatApiError },
);
