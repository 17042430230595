// Globals
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  Wrapper,
  StyledInfoTitles,
  StyledTitle,
  StyledTitles,
  StyledHeader,
  StyledButtonWrapper,
  StyledButton,
  StyledDeliveryWrapper,
  StyledSelect,
} from './MarketingDeliveryInfoStyles';
import { Link } from '@/ui';
import { ExportIcon } from '@/icons';
import { IFormInstance } from '@/components';
import MarketingDeliveryInfoCard from './MarketingDeliveryInfoCard';
import MarketingDeliveryHeader from './MarketingDeliveryHeader';
import AddCampaignPostModal from './AddCampaignPostModal';

// Models
import {
  IUpdateCampaignPostDTO,
  IDeliveryStatistics,
  IMarketingDeliveryInfo,
} from '@/models/marketingDelivery.model';
import { ICampaignKols } from '@/models/campaigns.model';
import { IProject } from '@/models/projects.model';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';
import { useExportPosts } from '@/modules/DataExports';
import { ProjectTabKeys } from '@/modules/Projects/constants';

// Helpers
import { ROUTES_PATHS } from '@/router';
import { useAppDispatch, useInfiniteScroll } from '@/hooks';
import { getCampaignKolsOptions } from '../../helpers';
import { setKolNameFilter } from '../../feature/slice';

interface IMarketingDeliveryInfos {
  projectId?: string;
  campaignId: string;
  kolIdFilter?: string;
  formInstance: IFormInstance;
  campaignKols?: ICampaignKols[];
  deliveryStatistics: IDeliveryStatistics | null;
  data: {
    items: IMarketingDeliveryInfo[];
    hasMore: boolean;
  };
  currentPostData: IMarketingDeliveryInfo;
  projectData: IProject;
  getNextData: () => void;
  handleSubmit: (values: IUpdateCampaignPostDTO) => void;
  handleDeleteCampaignPost: (proofId: string, sk: string) => void;
  isOpened: boolean;
  openModal: () => void;
  handleClose: () => void;
  openUpdateModal: () => void;
  isLoading: boolean;
  isCampaign?: boolean;
}

const MarketingDeliveryInfo = ({
  projectId,
  formInstance,
  campaignId,
  kolIdFilter,
  campaignKols,
  data,
  deliveryStatistics,
  isOpened,
  isLoading,
  isCampaign,
  currentPostData,
  projectData,
  getNextData,
  handleSubmit,
  handleDeleteCampaignPost,
  openModal,
  handleClose,
  openUpdateModal,
}: IMarketingDeliveryInfos) => {
  const { t } = useTranslation(['projectsList']);
  const dispatch = useAppDispatch();
  const loaderRef = useRef<HTMLDivElement>(null);
  const { isLoading: postsExportIsLoading, handler: handleExportPosts } =
    useExportPosts(campaignId, kolIdFilter);
  const optionCampaignKols = getCampaignKolsOptions(
    campaignKols as ICampaignKols[],
  );

  const mappedDeliveryInfoList = useMemo(() => {
    return data?.items?.map((data) => {
      return (
        <MarketingDeliveryInfoCard
          isLoading={isLoading}
          handleDeleteCampaignPost={handleDeleteCampaignPost}
          openUpdateModal={openUpdateModal}
          data={data}
          key={data.sk}
        />
      );
    });
  }, [data]);

  useInfiniteScroll({
    callback: getNextData,
    ref: loaderRef,
    isLoading,
    hasMore: data.hasMore,
  });

  const handleKolNameFilter = (v: unknown) => {
    dispatch(setKolNameFilter(v));
  };

  return (
    <Wrapper>
      <MarketingDeliveryHeader deliveryStatistics={deliveryStatistics} />
      <StyledDeliveryWrapper>
        <StyledHeader>
          <StyledTitle>{deliveryStatistics?.campaignTitle}</StyledTitle>
          <StyledButtonWrapper>
            <StyledSelect
              options={optionCampaignKols}
              onChange={handleKolNameFilter}
              allowClear
            />
            <StyledButton type="primary" onClick={openModal}>
              {t('proof_of_delivery_add_post')}
            </StyledButton>
            <StyledButton
              type="primary"
              onClick={handleExportPosts}
              disabled={postsExportIsLoading}
            >
              {t('proof_of_delivery_export')}
              <ExportIcon />
            </StyledButton>
            {!isCampaign && (
              <Link
                to={`${ROUTES_PATHS.PROJECTS_LIST}/${encodeURIComponent(
                  projectId ?? '',
                )}/${ProjectTabKeys.PROOF_OF_DELIVERY}`}
              >
                <StyledButton type="primary">
                  {t('proof_of_delivery_all_campaigns')}
                </StyledButton>
              </Link>
            )}
          </StyledButtonWrapper>
        </StyledHeader>
        <DesktopOnly>
          <StyledInfoTitles>
            <StyledTitles>{t('proof_of_delivery_admin')}</StyledTitles>
            <StyledTitles>{t('proof_of_delivery_kol')}</StyledTitles>
            <StyledTitles>{t('proof_of_delivery_cw')}</StyledTitles>
            <StyledTitles>{t('proof_of_delivery_date')}</StyledTitles>
            <StyledTitles>{t('proof_of_delivery_link')}</StyledTitles>
            <StyledTitles>{t('proof_of_delivery_price')}</StyledTitles>
            <StyledTitles>{t('proof_of_delivery_engagement')}</StyledTitles>
            <StyledTitles>{t('proof_of_delivery_reach')}</StyledTitles>
            <StyledTitles>{t('proof_of_delivery_actions')}</StyledTitles>
          </StyledInfoTitles>
        </DesktopOnly>
        {mappedDeliveryInfoList}
      </StyledDeliveryWrapper>
      <div ref={loaderRef} />

      <AddCampaignPostModal
        handleSubmit={handleSubmit}
        optionCampaignKols={optionCampaignKols}
        currentPost={currentPostData}
        isLoading={isLoading}
        formInstance={formInstance}
        isOpened={isOpened}
        handleClose={handleClose}
        projectData={projectData}
      />
    </Wrapper>
  );
};

export default MarketingDeliveryInfo;
