import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { ROUTES_PATHS } from '@/router';

// Components
import {
  StyledButton,
  StyledArrowRightIcon,
  StyledStatusWrapper,
  StyledStatus,
} from './PartnerDetailsContainerStyles';
import { Title, StyledPageWrapper } from '@/ui';
import { StatusChangeBlock, Tabs } from '@/components';
import { GeneralInfo } from '../../components';

// Models
import { RequestersRoles } from '@/models/requests.model';
import { LogEntities } from '@/models/logs.model';
import {
  LegalAgreementRoles,
  SeedHunterCreditRoles,
} from '@/models/sharedProfile.model';
import { AdminActionsEnum } from '@/models/adminsList.model';
import { UserRoles } from '@/models/admin.model';

// Modules
import {
  resetPartnersListState,
  updatePartnerDetails,
} from '@/modules/PartnersList/feature/slice';
import { partnersListSliceSelector } from '@/modules/PartnersList/feature/selectors';
import {
  AffiliateFundingContainer,
  InvitedUsers,
  Affiliate,
} from '@/modules/SharedAffiliate';
import {
  blockPartner,
  getPartnerAffiliateData,
  getPartnerDetails,
  getPartnerInvitedUsers,
} from '@/modules/PartnersList/feature/actionCreators';
import { useGetRequestCount } from '@/modules/Requests';
import { statusesConfigs } from '@/modules/KolsList';
import {
  AffiliateWithdrawalHistoryContainer,
  getStatusesConfig,
  LegalAgreementsContainer,
  SeedHunterCreditContainer,
  UserForceBlockSwitch,
} from '@/modules/SharedProfile';
import { getLogs } from '@/modules/Logs';
import { usePermissions } from '@/modules/Permissions';

// Helpers
import { PartnerDetailsTabKeys, partnersTabsConfig } from '../../constants';
import { useAppDispatch, useAppState, useTabs } from '@/hooks';
import { getUserId } from '@/utils';
import { AccountStatuses } from '@/constants';

const PartnerDetailsContainer = () => {
  const { t } = useTranslation(['partnersList']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id, section } = useParams();
  const getRedirectUrl = (key: string) =>
    `${ROUTES_PATHS.HUNTERS_LIST}/${encodeURIComponent(id ?? '')}/${key}`;
  const { onChange } = useTabs(
    PartnerDetailsTabKeys.GENERAL_INFO,
    getRedirectUrl,
  );
  const { partnerDetails, affiliateData, invitedUsers, isLoading } =
    useAppState(partnersListSliceSelector);
  const count = useGetRequestCount(RequestersRoles.PARTNER, partnerDetails?.id);
  const [permissionToEdit] = usePermissions(AdminActionsEnum.WRITE_PARTNER);

  const navigateToPartnersList = () => {
    navigate(ROUTES_PATHS.HUNTERS_LIST);
  };

  const onForceBlockSuccess = useCallback((status: string) => {
    dispatch(updatePartnerDetails({ status }));
  }, []);

  const getNextAffiliateData = useCallback(
    debounce(() => {
      if (isLoading || !affiliateData?.hasMore) return;
      const lastItem = affiliateData?.items?.at(-1);
      dispatch(
        getPartnerAffiliateData({
          startSk: lastItem?.sk,
          partnerId: getUserId(id ?? ''),
        }),
      );
    }, 1000),
    [isLoading, affiliateData?.hasMore, dispatch],
  );

  const getNextInvitedUsers = useCallback(
    debounce(() => {
      if (isLoading || !invitedUsers?.hasMore) return;
      const lastItem = invitedUsers?.items?.at(-1);
      dispatch(
        getPartnerInvitedUsers({
          startId: lastItem?.id as string,
          userId: getUserId(id ?? ''),
        }),
      );
    }, 1000),
    [isLoading, invitedUsers?.hasMore, dispatch],
  );

  useEffect(() => {
    if (id) {
      dispatch(getPartnerDetails(getUserId(id)));
      getNextAffiliateData();
      getNextInvitedUsers();
    }
  }, [id, partnerDetails?.status]);

  useEffect(
    () => (): void => {
      dispatch(resetPartnersListState());
    },
    [dispatch],
  );

  const handleBlockUser = useCallback(
    async (description: string) => {
      await dispatch(
        blockPartner({
          id: getUserId(partnerDetails?.id ?? ''),
          reason: description,
        }),
      ).unwrap();
      dispatch(
        getLogs({
          entity: LogEntities.PARTNER,
          id: getUserId(partnerDetails?.id ?? ''),
        }),
      );
    },
    [dispatch, partnerDetails],
  );

  const getSectionContent = (section?: string) => {
    switch (section) {
      case PartnerDetailsTabKeys.GENERAL_INFO:
        return (
          <GeneralInfo partnerDetails={partnerDetails} isLoading={isLoading} />
        );
      case PartnerDetailsTabKeys.LEGAL_AGREEMENTS:
        return <LegalAgreementsContainer role={LegalAgreementRoles.PARTNERS} />;
      case PartnerDetailsTabKeys.AFFILIATE:
        return (
          <>
            <Affiliate
              affiliateData={affiliateData}
              isLoading={isLoading}
              getNextAffiliateData={getNextAffiliateData}
              overallBalance={partnerDetails?.totalAffiliateCommissionAmount}
            />
            <InvitedUsers
              invitedUsers={invitedUsers?.items}
              getNextInvitedUsers={getNextInvitedUsers}
              isLoading={isLoading}
              hasMore={invitedUsers?.hasMore}
            />
            <AffiliateWithdrawalHistoryContainer
              id={getUserId(id ?? '')}
              role={LegalAgreementRoles.PARTNERS}
            />
          </>
        );
      case PartnerDetailsTabKeys.AFFILIATE_FUNDING:
        return (
          <AffiliateFundingContainer
            id={getUserId(id ?? '')}
            role={LegalAgreementRoles.PARTNERS}
          />
        );
      case PartnerDetailsTabKeys.SEED_HUNTER_CREDITS:
        return (
          <SeedHunterCreditContainer
            isLoading={isLoading}
            role={SeedHunterCreditRoles.PARTNERS}
            id={getUserId(id ?? '')}
          />
        );
    }
  };

  return (
    <StyledPageWrapper>
      <StyledStatusWrapper>
        <Title $type="h3">{`${partnerDetails?.firstName} ${partnerDetails?.lastName}`}</Title>
        <StyledStatus>
          <Title $type="h2">{t('hunters_general_info_force_block')}</Title>
          <UserForceBlockSwitch
            id={getUserId(partnerDetails?.id ?? '')}
            role={UserRoles.PARTNER}
            disabled={!permissionToEdit}
            initialIsBlocked={
              partnerDetails?.status === AccountStatuses.BLOCKED
            }
            onSuccess={onForceBlockSuccess}
          />

          <Title $type="h2">{t('hunters_general_info_status')}</Title>
          <StatusChangeBlock
            status={partnerDetails?.status}
            isEditable={permissionToEdit}
            handleRequest={handleBlockUser}
            statusesConfigs={getStatusesConfig(
              partnerDetails?.status ?? '',
              statusesConfigs,
            )}
          />
        </StyledStatus>
      </StyledStatusWrapper>
      <StyledButton type="ghost" onClick={navigateToPartnersList}>
        <StyledArrowRightIcon />
        {t('hunters_list_details_go_back')}
      </StyledButton>
      <Tabs
        count={count}
        sections={partnersTabsConfig}
        activeTab={section}
        onChange={onChange}
        withCount
      />
      {getSectionContent(section)}
    </StyledPageWrapper>
  );
};

export default PartnerDetailsContainer;
