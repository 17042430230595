// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

// Components
import { Checkbox, Title } from '@/ui';
import {
  StyledWrapper,
  StyledActionButton,
  StyledModal,
  StyledDocumentBody,
  StyledCheckboxWrap,
  StyledCheckboxText,
  StyledCloseWrap,
} from './DocumentSignModalStyles';

// Helpers
import { useBoolean } from '@/hooks';

interface IDocumentSignModal {
  isOpened: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  title: string;
  documentHtml?: string;
  isPreview?: boolean;
  loading?: boolean;
}

const DocumentSignModal = ({
  isOpened,
  title,
  documentHtml,
  onConfirm,
  onClose,
  isPreview = false,
  loading,
}: IDocumentSignModal) => {
  const { t } = useTranslation(['common']);
  const { value: agreed, setValue: setAgreed } = useBoolean();

  const onAgreementCheckboxChange = (e: CheckboxChangeEvent) => {
    setAgreed(e.target.checked);
  };

  const handleSubmit = () => {
    setAgreed(false);
    onConfirm?.();
  };

  const closeModal = () => {
    setAgreed(false);
    onClose();
  };

  return (
    <StyledModal visible={isOpened} onCancel={closeModal} footer={null}>
      <Title $type="h2">{title}</Title>
      <StyledDocumentBody>
        <div
          dangerouslySetInnerHTML={{
            __html: documentHtml ? String(documentHtml) : '',
          }}
        />
      </StyledDocumentBody>

      {isPreview ? (
        <StyledCloseWrap>
          <StyledActionButton type="default" onClick={closeModal}>
            {t('close')}
          </StyledActionButton>
        </StyledCloseWrap>
      ) : (
        <>
          <StyledCheckboxWrap>
            <Checkbox checked={agreed} onChange={onAgreementCheckboxChange} />
            <StyledCheckboxText>
              {t('contract_agreement', { ns: 'common' })}
            </StyledCheckboxText>
          </StyledCheckboxWrap>

          <StyledWrapper>
            <StyledActionButton
              type="primary"
              onClick={handleSubmit}
              disabled={!agreed}
              loading={loading}
            >
              {t('agree')}
            </StyledActionButton>
            <StyledActionButton type="default" onClick={closeModal}>
              {t('cancel')}
            </StyledActionButton>
          </StyledWrapper>
        </>
      )}
    </StyledModal>
  );
};

export default DocumentSignModal;
