import {
  IParticipation,
  ISettingsDTO,
  ISocialNetworkPrice,
} from '@/models/settings.model';
import { SettingsFieldsNames, TSettingsValues } from '../helpers/types';

class SettingsDTO implements ISettingsDTO {
  FOLLOWERS_MULTIPLIER;
  INVESTMENT_TARGET_BIAS_PERCENT;
  FIRST_STAGE_DAYS;
  CAMPAIGN_COMMISSION;
  HOURS_FOR_CONTRACT_SIGNING;
  DAYS_FOR_MONEY_TRANSFERRING;
  DAYS_FOR_MONEY_TRANSFERRING_BEFORE_MARKETING_START;
  DAYS_FOR_LABEL_NEW;
  MARKETING_AFFILIATE_COMMISSION;
  DOCUSIGN_DOCUMENTS_TEMPLATES;
  CAMPAIGN_SIGN_UP_PERIOD_OFFSET;
  FUNDING_ROUND_MAXIMUM_PARTICIPATION_FOR_NFT_HOLDERS;
  SOCIAL_NETWORK_PRICE: ISocialNetworkPrice | undefined;
  PARTICIPATION: IParticipation | undefined;

  constructor(values: TSettingsValues) {
    this.FOLLOWERS_MULTIPLIER =
      values[SettingsFieldsNames.FOLLOWERS_MULTIPLIER];
    this.INVESTMENT_TARGET_BIAS_PERCENT =
      values[SettingsFieldsNames.INVESTMENT_TARGET_BIAS_PERCENT];
    this.FIRST_STAGE_DAYS = values[SettingsFieldsNames.FIRST_STAGE_DAYS];
    this.CAMPAIGN_COMMISSION = values[SettingsFieldsNames.CAMPAIGN_COMMISSION];
    this.SOCIAL_NETWORK_PRICE =
      values[SettingsFieldsNames.SOCIAL_NETWORK_PRICE];
    this.HOURS_FOR_CONTRACT_SIGNING =
      values[SettingsFieldsNames.HOURS_FOR_CONTRACT_SIGNING];
    this.DAYS_FOR_MONEY_TRANSFERRING =
      values[SettingsFieldsNames.DAYS_FOR_MONEY_TRANSFERRING];
    this.DAYS_FOR_MONEY_TRANSFERRING_BEFORE_MARKETING_START =
      values[
        SettingsFieldsNames.DAYS_FOR_MONEY_TRANSFERRING_BEFORE_MARKETING_START
      ];
    this.DAYS_FOR_LABEL_NEW = values[SettingsFieldsNames.DAYS_FOR_LABEL_NEW];
    this.DOCUSIGN_DOCUMENTS_TEMPLATES =
      values[SettingsFieldsNames.DOCUSIGN_DOCUMENTS_TEMPLATES];
    this.MARKETING_AFFILIATE_COMMISSION =
      values[SettingsFieldsNames.MARKETING_AFFILIATE_COMMISSION];
    this.CAMPAIGN_SIGN_UP_PERIOD_OFFSET =
      values[SettingsFieldsNames.CAMPAIGN_SIGN_UP_PERIOD_OFFSET];
    this.PARTICIPATION = values[SettingsFieldsNames.PARTICIPATION];
    this.FUNDING_ROUND_MAXIMUM_PARTICIPATION_FOR_NFT_HOLDERS =
      values[
        SettingsFieldsNames.FUNDING_ROUND_MAXIMUM_PARTICIPATION_FOR_NFT_HOLDERS
      ];
  }
}

export default SettingsDTO;
