import {
  createSlice,
  isPending,
  isRejected,
  isFulfilled,
} from '@reduxjs/toolkit';
import {
  PARTNERS_LIST_SLICE_NAME,
  initialState,
  PartnersListState,
} from './models';
import {
  getPartnerAffiliateData,
  getPartnerDetails,
  getPartnersList,
  updatePartner,
  blockPartner,
  getPartnerInvitedUsers,
} from './actionCreators';
import { showApiErrors } from '@/utils';
import { IPartner, IPartnerAffiliateData } from '@/models/partnersList.model';

export const slice = createSlice({
  name: PARTNERS_LIST_SLICE_NAME,
  initialState,
  reducers: {
    setPartnersFilters(state: PartnersListState, action) {
      state.partnersFilters = { ...state.partnersFilters, ...action.payload };
      state.partnersListData = initialState.partnersListData;
    },
    resetPartnersListState() {
      return { ...initialState };
    },
    resetPartnersList(state) {
      state.partnersListData = {
        items: [],
        hasMore: true,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getPartnersList.fulfilled,
        (state: PartnersListState, action) => {
          state.partnersListData = {
            items: (action.meta.arg.startId
              ? [
                  ...(state.partnersListData?.items ?? []),
                  ...action.payload.items,
                ]
              : [...action.payload.items]) as IPartner[],
            hasMore: action.payload.hasMore,
          };
          state.isLoading = false;
        },
      )
      .addCase(
        getPartnerDetails.fulfilled,
        (state: PartnersListState, action) => {
          state.partnerDetails = action.payload.data;
          state.isLoading = false;
        },
      )
      .addCase(
        getPartnerAffiliateData.fulfilled,
        (state: PartnersListState, action) => {
          state.affiliateData = {
            items: (action.meta.arg.startId
              ? [...(state.affiliateData?.items ?? []), ...action.payload.items]
              : [...action.payload.items]) as IPartnerAffiliateData[],
            hasMore: action.payload.hasMore,
          };
          state.isLoading = false;
        },
      )
      .addCase(
        getPartnerInvitedUsers.fulfilled,
        (state: PartnersListState, action) => {
          state.invitedUsers = {
            items: (action.meta.arg.startId
              ? [...(state.invitedUsers?.items ?? []), ...action.payload.items]
              : [...action.payload.items]) as IPartnerAffiliateData[],
            hasMore: action.payload.hasMore,
          };
          state.isLoading = false;
        },
      )
      .addMatcher(
        isFulfilled(blockPartner, updatePartner),
        (state: PartnersListState, action) => {
          state.partnerDetails = action.payload;
          state.isLoading = false;
        },
      )
      .addMatcher(
        isPending(
          getPartnerDetails,
          getPartnersList,
          getPartnerAffiliateData,
          getPartnerInvitedUsers,
          updatePartner,
        ),
        (state: PartnersListState) => {
          state.isLoading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isRejected(
          getPartnerDetails,
          getPartnersList,
          getPartnerAffiliateData,
          getPartnerInvitedUsers,
          updatePartner,
        ),
        (state: PartnersListState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;

          showApiErrors(error);
        },
      );
  },
});

export const { resetPartnersListState, resetPartnersList, setPartnersFilters } =
  slice.actions;
export default slice.reducer;
