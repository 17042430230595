// Globals
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledBadge,
  StyledButton,
  StyledInfoWrapper,
  StyledRoundArrowsIcon,
  StyledTransferButton,
} from './FundingRoundDetailsCardStyles';
import PayoutTransferModal from './PayoutTransferModal';
import { StyledText } from '../Styled';
import { showErrorToast } from '@/components';

// Models
import { IFundingPayoutItem, PayoutStatuses } from '@/models/payout.model';
import { RequestStatuses } from '@/models/requests.model';
import { WalletNetworksEnum } from '@/models/web3.model';

// Helpers
import { useAppDispatch, useModal } from '@/hooks';
import { payoutFundingRoundTransaction } from '../../feature/actionCreators';
import { FundingRoundStatuses } from '@/models/fundingRound.model';

interface IPayoutActionsBadges {
  data: IFundingPayoutItem;
  isLoading: boolean;
}

const PayoutActionsBadges = ({ data, isLoading }: IPayoutActionsBadges) => {
  const { t } = useTranslation(['payout']);
  const dispatch = useAppDispatch();
  const { isOpened, openModal, closeModal } = useModal();
  const { fundingRoundId, id: payoutId, sk: payoutSk } = data;
  const isCreditEthereumUsed = data?.network === WalletNetworksEnum.ETHEREUM;

  const startTransaction = useCallback(() => {
    if (data?.payoutRequest?.status !== RequestStatuses.IN_PROGRESS) {
      return showErrorToast({ message: t('payout_status_error') });
    }
    dispatch(
      payoutFundingRoundTransaction({ fundingRoundId, payoutId, payoutSk }),
    );
  }, [data]);

  const transactionButtonAction = isCreditEthereumUsed
    ? openModal
    : startTransaction;

  if (data?.safeTransaction?.status) {
    return (
      <StyledInfoWrapper>
        <StyledBadge $status={data?.safeTransaction?.status}>
          <StyledText>
            {t(`payout_${data?.safeTransaction?.status}_title`)}
          </StyledText>
        </StyledBadge>
        {data?.safeTransaction?.status === PayoutStatuses.REJECTED && (
          <StyledButton
            type="ghost"
            onClick={startTransaction}
            disabled={isLoading}
          >
            {t('payout_try_again_button')}
            <StyledRoundArrowsIcon />
          </StyledButton>
        )}
      </StyledInfoWrapper>
    );
  }

  return (
    <StyledInfoWrapper>
      <StyledTransferButton
        type="primary"
        onClick={transactionButtonAction}
        disabled={
          data.fundingRoundStatus !== FundingRoundStatuses.COMPLETED ||
          isLoading
        }
      >
        {t('payout_start_transaction_button')}
      </StyledTransferButton>

      <PayoutTransferModal
        isOpened={isOpened}
        isLoading={isLoading}
        handleClose={closeModal}
        startTransaction={startTransaction}
        walletAddress={data?.walletAddress}
      />
    </StyledInfoWrapper>
  );
};

export default PayoutActionsBadges;
