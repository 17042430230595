// Globals
import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Store
import { payoutSliceSelector } from '../../feature/selectors';
import { getFundingPayoutDetailsList } from '../../feature/actionCreators';

// Components
import { Title } from '@/ui';
import { GoBackButton, showErrorToast, showSuccessToast } from '@/components';
import { FundingRoundPayoutDetails, StyledHeader } from '../../components';

// Modules
import {
  getFundingDetails,
  generateReleaseAgreement,
  selectFundingRoundDetails,
  fundingRoundIsLoading,
} from '@/modules/FundingRounds';

// Models
import { IFundingRound } from '@/models/fundingRound.model';

// Helpers | Hooks
import { getUserId } from '@/utils';
import { useAppDispatch, useAppState } from '@/hooks';

const FundingRoundDetailsContainer = () => {
  const { t } = useTranslation(['payout']);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { fundingPayoutDetailsData, isLoading } =
    useAppState(payoutSliceSelector);
  const fundingRound = useAppState(selectFundingRoundDetails);
  const isFundingRoundLoading = useAppState(fundingRoundIsLoading);

  const handleReleaseAgreement = useCallback(async () => {
    if (!fundingRound?.id) return;

    try {
      await dispatch(generateReleaseAgreement(getUserId(fundingRound?.id)));
      showSuccessToast({
        message: t('payout_funding_generate_release_success_massage'),
      });
    } catch (err) {
      showErrorToast({ message: (err as Error).message });
    }
  }, [fundingRound?.id]);

  useEffect(() => {
    if (id) {
      dispatch(getFundingDetails(getUserId(id)));
    }
    dispatch(
      getFundingPayoutDetailsList({ fundingRoundId: getUserId(id ?? '') }),
    );
  }, [id]);

  return (
    <>
      <StyledHeader>
        <Title $type="h3">{t('payout_title')}</Title>
      </StyledHeader>
      <GoBackButton>{t('payout_go_back_button')}</GoBackButton>
      <FundingRoundPayoutDetails
        fundingPayoutItems={fundingPayoutDetailsData?.payouts}
        fundingRevenueTransfers={fundingPayoutDetailsData?.revenueTransfers}
        fundingRound={fundingRound as IFundingRound}
        handleReleaseAgreement={handleReleaseAgreement}
        isFundingRoundLoading={isFundingRoundLoading}
        isLoading={isLoading}
      />
    </>
  );
};

export default FundingRoundDetailsContainer;
