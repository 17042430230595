import {
  ICompanyInfo,
  IContractInfo,
  VerificationLevel,
} from '@/models/projects.model';
import { IMainContact } from '@/models/sharedProfile.model';
import { SocialChannels } from '@/models/socialChannel.model';

export enum IProjectTabsNames {
  COMPANY_INFO = 'Company Info',
  PROJECT_INFORMATION = 'Project Information',
  SERVICE_AGREEMENT_DETAILS = 'Service Agreement Details',
  AFFILIATE = 'Affiliate Campaign',
  AFFILIATE_FUNDING = 'Affiliate Funding',
  PROOF_OF_DELIVERY = 'Proof of Delivery',
  LEGAL_AGREEMENTS = 'Legal Agreements',
}

export enum ContactPersonFieldsNames {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PHONE = 'phone',
  EMAIL = 'email',
  FORM_OF_ADDRESS = 'formAddress',
  POSITION = 'position',
}

export enum CompanyInfoFieldsNames {
  COMPANY_NAME = 'title',
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  FORM_OF_ADDRESS = 'formAddress',
  PHONE = 'phone',
  COMPANY_TYPE = 'companyType',
  SEED_WALLET_BALANCE = 'seedHunterWalletBalance',
  SEED_WALLET_ADDRESS = 'seedHunterWalletAddress',
  SEED_WALLET_NETWORK = 'seedHunterWalletNetwork',
  ALLOWED_SOCIAL_CHANNELS = 'allowedSocialChannels',
  VERIFICATION_LEVEL = 'verificationLevel',
}

export enum ProjectFieldsNames {
  TITLE = 'title',
  SUB_TITLE = 'subtitle',
  WEBSITE = 'website',
  WHITEPAPER = 'whitepaper',
  WALLET_ADDRESS = 'walletAddress',
  DESCRIPTION = 'description',
  PITCH_DECK = 'pitchDeck',
  IMAGE = 'projectImage',
  SOCIAL_CHANNEL = 'socialChannel',
  MAIN_COMMUNITY = 'mainCommunity',
  SECONDARY_COMMUNITY = 'secondaryCommunity',
  PRIMARY_GEOGRAPHY = 'mainGeography',
  SECONDARY_GEOGRAPHY = 'secondaryGeography',
  CERTIFICATE_OF_INCORPORATION = 'certificateOfIncorporation',
  BENEFICIAL_OWNERSHIP = 'beneficialOwnershipDeclaration',
}

export enum ProjectInformationFieldsNames {
  TITLE = 'title',
  SUB_TITLE = 'subtitle',
  PROJECT_PHASE = 'phase',
  ESTIMATED_TGE = 'estimatedTGE',
  TGE_DATE_STATUS = 'isTGEDateConfirmed',
  MARKETING_START_DATE = 'marketingStartDate',
  MARKETING_END_DATE = 'marketingEndDate',
  WEBSITE = 'website',
  WHITEPAPER = 'whitepaper',
  CERTIFICATE_OF_INCORPORATION = 'certificateOfIncorporation',
  BENEFICIAL_OWNERSHIP = 'beneficialOwnershipDeclaration',
  WALLET_ADDRESS = 'walletAddress',
  DESCRIPTION = 'description',
  SHORT_DESCRIPTION = 'shortDescription',
  SAFT = 'SAFT',
  TOKENOMICS = 'tokenomics',
  PITCH_DECK = 'pitchDeck',
  IMAGE = 'projectImage',
  ICON = 'tokenImage',
  SOCIAL_CHANNEL = 'socialChannel',
  TOKEN_NAME = 'tokenName',
  LOCK_UP_PERIOD = 'lockUpPeriod',
  VESTING_PERIOD = 'vestingPeriod',
}

export enum ContractInfoFieldsNames {
  TITLE = 'title',
  DESCRIPTION = 'description',
  DESCRIPTION_PROJECT = 'descriptionForProject',
  DESCRIPTION_KOL = 'descriptionForKol',
  ITEMS = 'items',
}

export type TServiceAgreement = {
  [ContractInfoFieldsNames.DESCRIPTION_PROJECT]?: string;
  [ContractInfoFieldsNames.DESCRIPTION_KOL]?: string;
  [ContractInfoFieldsNames.ITEMS]?: IContractInfo[];
};

export type TProjectInfo = {
  [CompanyInfoFieldsNames.FIRST_NAME]?: string;
  [CompanyInfoFieldsNames.LAST_NAME]?: string;
  [CompanyInfoFieldsNames.FORM_OF_ADDRESS]?: string;
  [CompanyInfoFieldsNames.EMAIL]?: string;
  [CompanyInfoFieldsNames.VERIFICATION_LEVEL]?: VerificationLevel;
  [CompanyInfoFieldsNames.ALLOWED_SOCIAL_CHANNELS]?: SocialChannels[];
  companyInfo?: ICompanyInfo;
  certificateOfIncorporation?: string;
  beneficialOwnershipDeclaration?: string;
  mainContact?: IMainContact;
};
