// Globals
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import { Title } from '@/ui';
import { GoBackButton } from '@/components';
import {
  FundingRefundDetails,
  StyledButton,
  StyledExportIcon,
  StyledHeader,
} from '../../components';
import { StyledPageWrapper } from './FundtingRefundContainerStyles';

// Helpers | Hooks
import { getUserId } from '@/utils';
import { useAppDispatch, useAppState } from '@/hooks';
import { payoutSliceSelector } from '../../feature/selectors';
import { getFundingRefundDetails } from '../../feature/actionCreators';
import { PayoutTabKeys } from '../../constants';
import { useExportPayoutsData } from '../../hooks';

const FundingRefundDetailsContainer = () => {
  const { t } = useTranslation(['payout']);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { fundingRefundDetailsData, isLoading } =
    useAppState(payoutSliceSelector);
  const { isLoading: isExportLoading, handleExport } = useExportPayoutsData({
    activeTab: PayoutTabKeys.FUNDING_ROUND_REFUND,
    fundingId: id,
  });

  useEffect(() => {
    if (id) {
      dispatch(getFundingRefundDetails({ fundingRoundId: getUserId(id) }));
    }
  }, [id]);

  return (
    <StyledPageWrapper>
      <StyledHeader>
        <Title $type="h3">{t('refund_title')}</Title>
        <StyledButton
          type="primary"
          onClick={handleExport}
          loading={isExportLoading || isLoading}
        >
          {t('payout_export')}
          <StyledExportIcon />
        </StyledButton>
      </StyledHeader>
      <GoBackButton>{t('payout_go_back_button')}</GoBackButton>
      <FundingRefundDetails
        fundingRefundDetailsData={fundingRefundDetailsData}
        isLoading={isLoading}
      />
    </StyledPageWrapper>
  );
};

export default FundingRefundDetailsContainer;
