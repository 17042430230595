// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Title } from '@/ui';
import {
  StyledModal,
  StyledStatusWrapper,
  StyledPreview,
} from './PreviewModalStyles';
import { ModalSize } from '@/components';

// Models
import { IGetDocumentPreviewDataResponse } from '@/models/documents.model';

// Modules
import { highlightVariables } from '@/modules/Documents';

interface IPreviewModal {
  isOpened: boolean;
  loading?: boolean;
  onClose: () => void;
  clickWrapTemplateDetails?: IGetDocumentPreviewDataResponse | null;
}

const PreviewModal = ({
  isOpened,
  clickWrapTemplateDetails,
  onClose,
}: IPreviewModal) => {
  const { t } = useTranslation(['documents']);
  const htmlContent =
    clickWrapTemplateDetails?.agreementHtmlContent ??
    clickWrapTemplateDetails?.htmlContent;

  const content = htmlContent ? String(htmlContent) : '';

  return (
    <StyledModal
      visible={isOpened}
      onCancel={onClose}
      footer={null}
      size={ModalSize.HUGE}
    >
      <StyledStatusWrapper>
        <Title $type="h3">
          {t(clickWrapTemplateDetails?.templateType ?? '')}
        </Title>
      </StyledStatusWrapper>
      <StyledPreview
        dangerouslySetInnerHTML={{ __html: highlightVariables(content) }}
      />
    </StyledModal>
  );
};

export default PreviewModal;
