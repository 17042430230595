// Globals
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  DetailsBlock,
  StyledA,
  StyledFileIcon,
  StyledRow,
  StyledChannelsRow,
} from './FundingOverviewStyles';

// Models
import { IFundingRound } from '@/models/fundingRound.model';

// Modules
import { socialChannelsConfig } from '@/modules/KolsList';

interface ISocialsBlock {
  data: IFundingRound | null;
}

const SocialsBlock = ({ data }: ISocialsBlock) => {
  const { t } = useTranslation(['fundingRound']);

  const mappedChannels = useMemo(() => {
    return data?.projectSocialChannel?.map((item) => (
      <a href={item?.link} target="_blank" key={item.name} rel="noreferrer">
        {socialChannelsConfig[item.name]?.['icon']}
      </a>
    ));
  }, [data]);

  return (
    <DetailsBlock>
      <StyledRow>
        <StyledA href={data?.projectWebsite} target="_blank">
          <StyledFileIcon />
          {t('funding_overview_website')}
        </StyledA>
        {data?.projectWhitepaper && (
          <StyledA href={data?.projectWhitepaper} target="_blank">
            <StyledFileIcon />
            {t('funding_overview_whitepaper')}
          </StyledA>
        )}
        <StyledA href={data?.pitchDeck} target="_blank">
          <StyledFileIcon />
          {t('funding_overview_pitchdeck')}
        </StyledA>
        <StyledA href={data?.tokenomics} target="_blank">
          <StyledFileIcon />
          {t('funding_overview_tokenomics')}
        </StyledA>
      </StyledRow>
      <StyledChannelsRow>{mappedChannels}</StyledChannelsRow>
    </DetailsBlock>
  );
};

export default SocialsBlock;
