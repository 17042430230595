// Globals
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  FormWrapper,
  Wrapper,
  StyledForm,
  StyledButtonWrapper,
  StyledButton,
} from './ContactPersonEditBlockStyles';
import { StyledInput } from '@/ui';
import { FormItemGroup, IFormInstance, NestedFormItem } from '@/components';

// Models
import { IProject } from '@/models/projects.model';

// Helpers | Hooks
import { ContactPersonFieldsNames } from '../../types';
import { useGetFormValidationRules } from '../../hooks';
import { isProjectDisabled } from '../../helpers';

interface IContactPersonEditBlock {
  projectDetails?: IProject | null;
  formInstance: IFormInstance;
  isLoading: boolean;
  handleSubmit: (values: IProject) => void;
}

const ContactPersonEditBlock = ({
  projectDetails,
  isLoading,
  formInstance,
  handleSubmit,
}: IContactPersonEditBlock) => {
  const { t } = useTranslation(['projectsList']);
  const validationRules = useGetFormValidationRules();

  useEffect(() => {
    formInstance.setFieldsValue(projectDetails);
  }, [projectDetails]);

  return (
    <StyledForm
      onFinish={handleSubmit}
      form={formInstance}
      disabled={isProjectDisabled(projectDetails?.status)}
    >
      <FormWrapper>
        <FormItemGroup prefix={['contactPerson']}>
          <Wrapper>
            <NestedFormItem
              name={ContactPersonFieldsNames.FIRST_NAME}
              label={t('projects_general_info_first_name_title')}
              rules={validationRules[ContactPersonFieldsNames.FIRST_NAME]}
            >
              <StyledInput />
            </NestedFormItem>
            <NestedFormItem
              name={ContactPersonFieldsNames.LAST_NAME}
              label={t('projects_general_info_last_name_title')}
              rules={validationRules[ContactPersonFieldsNames.LAST_NAME]}
            >
              <StyledInput />
            </NestedFormItem>
          </Wrapper>
        </FormItemGroup>
      </FormWrapper>
      <StyledButtonWrapper>
        <StyledButton type="primary" htmlType="submit" disabled={isLoading}>
          {t('projects_general_info_save')}
        </StyledButton>
      </StyledButtonWrapper>
    </StyledForm>
  );
};

export default ContactPersonEditBlock;
