import {
  ITopBarBase,
  IParticipation,
  ISocialNetworkPrice,
  ITopBar,
} from '@/models/settings.model';
import { IDocumentTemplateData } from '@/models/documents.model';

export enum SettingsFieldsNames {
  FIRST_STAGE_DAYS = 'firstStageDays',
  INVESTMENT_TARGET_BIAS_PERCENT = 'bias',
  FOLLOWERS_MULTIPLIER = 'followers',
  FACEBOOK_PRICE = 'facebook',
  INSTAGRAM_PRICE = 'instagram',
  TWITTER_PRICE = 'twitter',
  YOUTUBE_PRICE = 'youtube',
  SOCIAL_NETWORK_PRICE = 'socialNetwork',
  HOURS_FOR_CONTRACT_SIGNING = 'contractSigning',
  DAYS_FOR_MONEY_TRANSFERRING = 'moneyTransferring',
  DAYS_FOR_MONEY_TRANSFERRING_BEFORE_MARKETING_START = 'moneyTransferringBeforeMarketingStart',
  DAYS_FOR_LABEL_NEW = 'daysForLabelNew',
  SAFT_CLICK = 'saft:click',
  SUPLIMENTARY_AGREEMENT_CLICK = 'supplementary_agreement:click',
  SUPLIMENTARY_AGREEMENT_SIGN = 'supplementary_agreement:sign',
  SERVICE_AGREEMENT_SIGN = 'service_agreement:sign',
  DOCUSIGN_DOCUMENTS_TEMPLATES = 'docusignTemplates',
  CAMPAIGN_COMMISSION = 'campaignCommission',
  AVERAGE_DATA_TWITTER = 'twitter',
  AVERAGE_DATA_FACEBOOK = 'facebook',
  AVERAGE_DATA_INSTAGRAM = 'instagram',
  AVERAGE_DATA_YOUTUBE = 'youtube',
  CAMPAIGN_SIGN_UP_PERIOD_OFFSET = 'participationThresholdDays',
  MARKETING_AFFILIATE_COMMISSION = 'marketingAffiliateCommission',
  PARTICIPATION = 'participation',
  PARTICIPATION_MAX = 'max',
  PARTICIPATION_MIN = 'min',
  FUNDING_BUCKET = 'nftCreditsBucketBalance',
  FUNDING_ROUND_MAXIMUM_PARTICIPATION_FOR_NFT_HOLDERS = 'fundingRoundParticipationNFT',
  MAX_COORDINATED_FOLLOWERS = 'maxCoordinatedFollowers',
  MAX_MONTH_REPORT = 'maxMonthsReportValidity',
  MIN_ENGAGEMENT_RATE = 'minEngagementRate',
  MIN_FOLLOWERS = 'minFollowers',
}

export enum TopBarsSettingsFieldsNames {
  NFT = 'nft',
  MARKETING = 'marketing',
  FUNDING_ROUND = 'fundingRound',
  TEXT = 'text',
  ACTIVE = 'active',
  PRIORITY = 'priority',
}

export type TSettingsValues = {
  [SettingsFieldsNames.FIRST_STAGE_DAYS]?: number;
  [SettingsFieldsNames.INVESTMENT_TARGET_BIAS_PERCENT]?: number;
  [SettingsFieldsNames.FOLLOWERS_MULTIPLIER]?: number;
  [SettingsFieldsNames.FACEBOOK_PRICE]?: number;
  [SettingsFieldsNames.INSTAGRAM_PRICE]?: number;
  [SettingsFieldsNames.TWITTER_PRICE]?: number;
  [SettingsFieldsNames.YOUTUBE_PRICE]?: number;
  [SettingsFieldsNames.HOURS_FOR_CONTRACT_SIGNING]?: number;
  [SettingsFieldsNames.DAYS_FOR_MONEY_TRANSFERRING]?: number;
  [SettingsFieldsNames.DAYS_FOR_MONEY_TRANSFERRING_BEFORE_MARKETING_START]?: number;
  [SettingsFieldsNames.SAFT_CLICK]?: string;
  [SettingsFieldsNames.SUPLIMENTARY_AGREEMENT_CLICK]?: string;
  [SettingsFieldsNames.SUPLIMENTARY_AGREEMENT_SIGN]?: string;
  [SettingsFieldsNames.SERVICE_AGREEMENT_SIGN]?: string;
  [SettingsFieldsNames.DAYS_FOR_LABEL_NEW]?: number;
  [SettingsFieldsNames.AVERAGE_DATA_TWITTER]?: number;
  [SettingsFieldsNames.AVERAGE_DATA_FACEBOOK]?: number;
  [SettingsFieldsNames.AVERAGE_DATA_YOUTUBE]?: number;
  [SettingsFieldsNames.AVERAGE_DATA_INSTAGRAM]?: number;
  [SettingsFieldsNames.CAMPAIGN_COMMISSION]?: number;
  [SettingsFieldsNames.MARKETING_AFFILIATE_COMMISSION]?: number;
  [SettingsFieldsNames.CAMPAIGN_SIGN_UP_PERIOD_OFFSET]?: number;
  [SettingsFieldsNames.FUNDING_ROUND_MAXIMUM_PARTICIPATION_FOR_NFT_HOLDERS]?: number;
  [SettingsFieldsNames.DOCUSIGN_DOCUMENTS_TEMPLATES]?: IDocumentTemplateData[];
  [SettingsFieldsNames.SOCIAL_NETWORK_PRICE]?: ISocialNetworkPrice;
  [SettingsFieldsNames.PARTICIPATION]?: IParticipation;
};

export type TTopBarsSettingsValues = {
  [TopBarsSettingsFieldsNames.NFT]?: ITopBarBase;
  [TopBarsSettingsFieldsNames.MARKETING]?: ITopBar;
  [TopBarsSettingsFieldsNames.FUNDING_ROUND]?: ITopBar;
};
