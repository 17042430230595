import { TFunction } from 'react-i18next';
import { IMilestone } from '@/models/milestones.model';
import { IFundingRound } from '@/models/fundingRound.model';
import { ITableData } from '@/components';
import { WaitingCircleIcon, SuccessCircleIcon } from '@/icons';
import { getDateString, numberWithCommas } from '@/utils';
import { DATE_FORMATS } from '@/constants';
import { fundingTargetCalculation } from './fundingTargetCalculation';

export const getMilestoneTableData = (data: IMilestone[]) => {
  const tableData = data?.map((item) => {
    return {
      key: item.id,
      title: item.title,
      requirements: item.approvalRequirement,
      items: item.subGoals,
      icon: item.isAchieved ? <SuccessCircleIcon /> : <WaitingCircleIcon />,
    } as ITableData;
  });
  return tableData;
};

export const getTokenomicsTableData = (data: IFundingRound, t: TFunction) => {
  const tableData = {
    fdv: `$ ${numberWithCommas(data?.fdv ?? 0)}`,
    tokenName: data?.tokenName,
    tokenNetwork: data?.tokenNetwork,
    tokenAmount: numberWithCommas(data?.totalTokensSupply ?? 0),
    hardCap: `$ ${numberWithCommas(data?.hardCap ?? 0)}`,
    ...(data?.initialMarketingCap && {
      initialMarketingCap: `$ ${numberWithCommas(data.initialMarketingCap)}`,
    }),
  };
  const table = Object.entries(tableData)?.map(([key, value]) => {
    return {
      title: t(`funding_table_${key}`),
      items: value,
    } as ITableData;
  });
  return table;
};

export const getSeedHunterTableData = (data: IFundingRound, t: TFunction) => {
  const fundingStartDate = getDateString(
    data?.startDate ?? '',
    DATE_FORMATS.DAY_MONTH_WITH_DOTS,
  );
  const fundingEndDate = getDateString(
    data?.endDate ?? '',
    DATE_FORMATS.DATE_WITH_DOTS,
  );

  const tableData = {
    phase: t(`funding_${data?.phase}_phase`),
    plannedTge: getDateString(
      data?.plannedTGEDate ?? '',
      DATE_FORMATS.DATE_WITH_DOTS,
    ),
    fundingGoal: `$ ${numberWithCommas(
      fundingTargetCalculation(data) ?? 0,
    )}   `,
    tokenPrice: `$ ${numberWithCommas(data?.pricePerToken ?? 0, 5)}`,
    tokensAmountToManage: `${numberWithCommas(data.tokensAmountToManage ?? 0)}`,
    lockUp: data?.TGEUnlock,
    vestingPeriod: data?.vestingPeriod,
    fundingPeriod: `${fundingStartDate} - ${fundingEndDate}`,
  };
  const table = Object.entries(tableData)?.map(([key, value]) => {
    return {
      title: t(`funding_table_${key}`),
      items: value,
    } as ITableData;
  });
  return table;
};
