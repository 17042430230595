import { IFundingContractItem } from '@/models/fundingRound.model';

export enum FundingSteps {
  ELEVATOR_PITCH = 1,
  PRESENTATION = 2,
  TOKENOMICS = 3,
  LEGAL_REQUIREMENTS = 4,
  OVERVIEW = 5,
  PARTICIPATION = 6,
}

export enum FundingTabs {
  ELEVATOR_PITCH = 'Elevator Pitch',
  PRESENTATION = 'Presentation',
  TOKENOMICS = 'Tokenomics',
  MILESTONES = 'Milestones',
  LEGAL_REQUIREMENTS = 'Legal Requirements',
  OVERVIEW = 'Overview',
  PARTICIPATION = 'Participation',
}

export type TLinkedStep = {
  key: FundingSteps;
  next: FundingSteps | null;
  prev: FundingSteps | null;
};

export enum IFundingRoundFields {
  TITLE = 'title',
  QUESTIONNAIRE_LINK = 'questionnaireLink',
  ELEVATOR_PITCH = 'description',
  TGE_DATE = 'plannedTGEDate',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  TOKENOMICS = 'tokenomics',
  RESOURCES = 'additionalResources',
  PITCH_DECK = 'pitchDeck',
  INVOLVED = 'involved',
  BANNER_IMAGE = 'fundingRoundBanner',
  TOKEN_IMAGE = 'tokenImage',
  ROADMAP = 'roadMap',
  MARKETING_STRATEGY = 'marketingStrategy',
  IS_INTERNAL = 'isInternal',
  USPS = 'usps',
  TOKEN_METRICS = 'tokenMetrics',
  TOKEN_NAME = 'tokenName',
  TOKEN_NETWORK = 'tokenNetwork',
  PHASE = 'phase',
  TOKEN_SUPPLY = 'totalTokensSupply',
  HARD_CAP = 'hardCap',
  FDV = 'fdv',
  INITIAL_MARKETING_CAP = 'initialMarketingCap',
  TOKEN_AMOUNT_TO_BE_MANAGED = 'tokensAmountToManage',
  PUBLIC_PRICE_PER_TOKEN = 'publicPricePerToken',
  PRICE_PER_TOKEN = 'pricePerToken',
  TOKEN_ALLOCATION_PERCENT = 'seedHunterTokenAllocationPercent',
  TGE_UNLOCK = 'TGEUnlock',
  VESTING_PERIOD = 'vestingPeriod',
  TOKEN_SALE_SUMMARY = 'lockUpVestingSummary',
  WALLET_CURRENCY = 'walletCurrency',
  WALLET_NETWORK = 'walletNetwork',
  SLUG = 'slug',
  WALLET_ADDRESS = 'walletAddress',
  BENEFITS = 'benefits',
  EARLY_ACCESS = 'earlyAccess',
  HIGHER_TICKET = 'higherTicket',
  NFT_ONLY = 'nftOnly',
}

export const fileFields = [
  IFundingRoundFields.BANNER_IMAGE,
  IFundingRoundFields.TOKEN_IMAGE,
  IFundingRoundFields.TOKENOMICS,
  IFundingRoundFields.PITCH_DECK,
  IFundingRoundFields.RESOURCES,
];

export enum FundingContractFieldsNames {
  ADDITIONAL_DEFINITIONS_VALUE = 'additionalDefinitions_value',
  ADDITIONAL_DEFINITIONS_CONFIRMATION = 'additionalDefinitions_confirmation',

  EXCLUDED_JURISDICTIONS_VALUE = 'excludedJurisdictions_value',
  EXCLUDED_JURISDICTIONS_CONFIRMATION = 'excludedJurisdictions_confirmation',

  INTELLECTUAL_PROPERTY_VALUE = 'intellectualProperty_value',
  INTELLECTUAL_PROPERTY_CONFIRMATION = 'intellectualProperty_confirmation',

  GOVERNING_LAW_VALUE = 'governingLaw_value',
  GOVERNING_LAW_CONFIRMATION = 'governingLaw_confirmation',

  DISPUTE_RESOLUTION_VALUE = 'disputeResolution_value',
  DISPUTE_RESOLUTION_CONFIRMATION = 'disputeResolution_confirmation',

  RETENTION_OF_RIGHTS_VALUE = 'retentionOfRights_value',
  RETENTION_OF_RIGHTS_CONFIRMATION = 'retentionOfRights_confirmation',

  NOTICES_VALUE = 'notices_value',
  NOTICES_CONFIRMATION = 'notices_confirmation',

  TITLE = 'title',
  DESCRIPTION = 'description',
  ITEMS = 'items',
}

export type TFundingContractValues = {
  [FundingContractFieldsNames.ADDITIONAL_DEFINITIONS_VALUE]?: string;
  [FundingContractFieldsNames.EXCLUDED_JURISDICTIONS_VALUE]?: string;
  [FundingContractFieldsNames.INTELLECTUAL_PROPERTY_VALUE]?: string;
  [FundingContractFieldsNames.GOVERNING_LAW_VALUE]?: string;
  [FundingContractFieldsNames.DISPUTE_RESOLUTION_VALUE]?: string;
  [FundingContractFieldsNames.RETENTION_OF_RIGHTS_VALUE]?: string;
  [FundingContractFieldsNames.NOTICES_VALUE]?: string;

  [FundingContractFieldsNames.ADDITIONAL_DEFINITIONS_CONFIRMATION]?: boolean;
  [FundingContractFieldsNames.EXCLUDED_JURISDICTIONS_CONFIRMATION]?: boolean;
  [FundingContractFieldsNames.INTELLECTUAL_PROPERTY_CONFIRMATION]?: boolean;
  [FundingContractFieldsNames.GOVERNING_LAW_CONFIRMATION]?: boolean;
  [FundingContractFieldsNames.DISPUTE_RESOLUTION_CONFIRMATION]?: boolean;
  [FundingContractFieldsNames.RETENTION_OF_RIGHTS_CONFIRMATION]?: boolean;
  [FundingContractFieldsNames.NOTICES_CONFIRMATION]?: boolean;

  [FundingContractFieldsNames.ITEMS]?: IFundingContractItem[];
};
