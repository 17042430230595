// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { StyledButton } from './CompleteCampaignStyles';
import { ConfirmationModal } from '@/components';

// Hooks
import { useModal } from '@/hooks';

interface ICompleteCampaign {
  handleComplete: () => void;
  modalTitle: string;
  buttonTitle: string;
  isLoading: boolean;
}

const CompleteCampaign = ({
  handleComplete,
  modalTitle,
  buttonTitle,
  isLoading,
}: ICompleteCampaign) => {
  const { t } = useTranslation(['campaigns']);
  const { isOpened, openModal, closeModal } = useModal();

  const onConfirm = () => {
    handleComplete();
    closeModal();
  };

  return (
    <>
      <StyledButton type="ghost" onClick={openModal}>
        {t(buttonTitle)}
      </StyledButton>
      <ConfirmationModal
        isOpened={isOpened}
        isLoading={isLoading}
        title={t(modalTitle)}
        onClose={closeModal}
        onConfirm={onConfirm}
      />
    </>
  );
};

export default CompleteCampaign;
