import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import {
  FUNDING_ROUND_SLICE_NAME,
  FundingRoundState,
  initialState,
} from './models';
import {
  getFundingRoundList,
  getFundingDetails,
  updateFundingDetails,
  generateReleaseAgreement,
  getFundingContract,
  updateFundingContract,
  getFundingRoundParticipations,
  manualConfirmFundingRoundPayment,
  updateFundingSlug,
} from './actionCreators';
import { showApiErrors } from '@/utils';

export const slice = createSlice({
  name: FUNDING_ROUND_SLICE_NAME,
  initialState,
  reducers: {
    resetFundingRoundList(state) {
      state.fundingRoundsListData = initialState.fundingRoundsListData;
    },
    updateFundingRoundState(state, action) {
      state.fundingRound = {
        ...state.fundingRound,
        ...action.payload,
      };
    },
    resetFundingRound(state) {
      state.fundingRound = initialState.fundingRound;
    },
    resetFundingRoundContract(state) {
      state.fundingContract = initialState.fundingContract;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getFundingRoundList.fulfilled,
        (state: FundingRoundState, action) => {
          state.fundingRoundsListData = {
            items: action.meta.arg.startId
              ? [
                  ...(state.fundingRoundsListData?.items ?? []),
                  ...action.payload.items,
                ]
              : [...action.payload.items],
            hasMore: action.payload.hasMore,
          };
          state.isLoading = false;
        },
      )
      .addCase(
        getFundingDetails.fulfilled,
        (state: FundingRoundState, action) => {
          state.fundingRound = action.payload;
          state.isLoading = false;
        },
      )
      .addCase(
        getFundingRoundParticipations.fulfilled,
        (state: FundingRoundState, action) => {
          state.fundingRoundParticipations = action.payload;
          state.isLoading = false;
        },
      )
      .addCase(
        getFundingContract.fulfilled,
        (state: FundingRoundState, action) => {
          state.fundingContract = action.payload;
          state.isLoading = false;
        },
      )
      .addCase(
        updateFundingContract.fulfilled,
        (state: FundingRoundState, action) => {
          state.fundingContract = action.payload;
          state.isLoading = false;
        },
      )
      .addCase(
        manualConfirmFundingRoundPayment.fulfilled,
        (state: FundingRoundState, action) => {
          state.fundingRoundParticipations = (
            state.fundingRoundParticipations || []
          ).map((item) =>
            item.sk === action.payload.sk ? action.payload : item,
          );
          state.isLoading = false;
        },
      )
      .addCase(getFundingContract.rejected, (state: FundingRoundState) => {
        state.isLoading = false;
      })
      .addMatcher(
        isFulfilled(
          updateFundingSlug,
          updateFundingDetails,
          generateReleaseAgreement,
        ),
        (state: FundingRoundState, action) => {
          state.fundingRound = { ...state.fundingRound, ...action.payload };
          state.isLoading = false;
        },
      )
      .addMatcher(
        isPending(
          getFundingRoundList,
          getFundingContract,
          getFundingDetails,
          updateFundingDetails,
          updateFundingSlug,
          getFundingRoundParticipations,
          manualConfirmFundingRoundPayment,
          generateReleaseAgreement,
        ),
        (state: FundingRoundState) => {
          state.isLoading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isRejected(
          getFundingRoundList,
          getFundingDetails,
          updateFundingContract,
          updateFundingDetails,
          updateFundingSlug,
          getFundingRoundParticipations,
          manualConfirmFundingRoundPayment,
          generateReleaseAgreement,
        ),
        (state: FundingRoundState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;

          showApiErrors(error);
        },
      );
  },
});

export const {
  resetFundingRoundList,
  resetFundingRound,
  updateFundingRoundState,
  resetFundingRoundContract,
} = slice.actions;

export default slice.reducer;
