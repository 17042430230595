// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledArrowRightIcon,
  StyledButton,
  StyledExportWrap,
  StyledWrapper,
} from './CampaignResultsStyles';
import CampaignOverview from './CampaignOverview';
import PerformanceMetrics from './PerformanceMetrics';

// Models
import {
  ICampaign,
  IParticipationOverview,
  IParticipationSummary,
} from '@/models/campaigns.model';
import { IDeliveryStatistics } from '@/models/marketingDelivery.model';

type TActiveCampaignDetails = {
  campaign: ICampaign;
  participationSummary: IParticipationSummary;
  deliveryStatistics: IDeliveryStatistics;
  isLoading: boolean;
  exportLoading: boolean;
  participants: {
    items: IParticipationOverview[] | null;
    hasMore: boolean;
  };
  getNextParticipantsOverview: () => void;
  handleCampaignResultsExport: () => void;
};

const CampaignResults = ({
  campaign,
  participationSummary,
  deliveryStatistics,
  isLoading,
  exportLoading,
  participants,
  getNextParticipantsOverview,
  handleCampaignResultsExport,
}: TActiveCampaignDetails) => {
  const { t } = useTranslation(['campaigns']);

  return (
    <StyledWrapper>
      <StyledExportWrap>
        <StyledButton
          loading={exportLoading}
          onClick={handleCampaignResultsExport}
        >
          {t('campaign_metrics_export')}
          <StyledArrowRightIcon />
        </StyledButton>
      </StyledExportWrap>
      <CampaignOverview
        campaign={campaign}
        participationSummary={participationSummary}
        deliveryStatistics={deliveryStatistics}
      />
      <PerformanceMetrics
        participants={participants}
        getNextParticipantsOverview={getNextParticipantsOverview}
        isLoading={isLoading}
      />
    </StyledWrapper>
  );
};

export default CampaignResults;
