// Globals
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import { Title, StyledPageWrapper } from '@/ui';
import {
  StyledArrowRightIcon,
  StyledButton,
} from './RequestsDetailsContainerStyles';
import ProfileRequestContainer from '../ProfileRequestContainer/ProfileRequestContainer';
import SubmitContentPlanRequestContainer from '../SubmitContentPlanRequestContainer/SubmitContentPlanRequestContainer';
import CheckInvestmentRequestContainer from '../CheckInvestmentRequestContainer/CheckInvestmentRequestContainer';
import TgeDateChangeRequestContainer from '../TgeDateChangeRequestContainer/TgeDateChangeRequestContainer';
import ContactSupportTgeRequestContainer from '../ContactSupportTgeRequestContainer/ContactSupportTgeRequestContainer';
import PostsContestRequestContainer from '../PostsContestRequestContainer/PostsContestRequestContainer';
import RefundRequestContainer from '../RefundRequestContainer/RefundRequestContainer';
import AffiliateWithdrawalRequestContainer from '../AffiliateWithdrawalRequestContainer/AffiliateWithdrawalRequestContainer';
import FundingRoundApplicationRequestContainer from '../FundingRoundApplicationRequestContainer/FundingRoundApplicationRequestContainer';
import FundingRoundLegalApprovalRequestContainer from '../FundingRoundLegalApprovalRequestContainer/FundingRoundLegalApprovalRequestContainer';
import FundingRoundCreditsContainer from '../FundingRoundCreditsContainer/FundingRoundCreditsContainer';
import PayoutRequestContainer from '../PayoutRequestContainer/PayoutRequestContainer';
import FundingRoundRefundContainer from '../FundingRoundRefundContainer/FundingRoundRefundContainer';
import CampaignEthereumSwapContainer from '../CampaignEthereumSwapContainer/CampaignEthereumSwapContainer';

// Modules
import { RequestsTypesKeys } from '@/models/requests.model';

// Hooks | Helpers
import { useAppDispatch, useAppState } from '@/hooks';
import { requestsDetailsSliceSelector } from '../../feature/selectors';
import { getRequestDetails } from '../../feature/actionCreators';
import { getUserId } from '@/utils';
import { resetRequestData } from '../../feature/slice';

const RequestsDetailsContainer = () => {
  const { t } = useTranslation(['requestsList']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { type, id } = useParams();

  const { currentRequest, isLoading } = useAppState(
    requestsDetailsSliceSelector,
  );

  const navigateToRequests = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (id) {
      dispatch(getRequestDetails(getUserId(id)));
    }
  }, [id]);

  const getSectionContent = (section?: string) => {
    switch (section) {
      case RequestsTypesKeys.PROFILE_VERIFICATION:
      case RequestsTypesKeys.CAMPAIGN_VERIFICATION:
        return (
          <ProfileRequestContainer
            isLoading={isLoading}
            request={currentRequest}
            withVerification={true}
          />
        );
      case RequestsTypesKeys.PROFILE_CHANGE:
        return (
          <ProfileRequestContainer
            request={currentRequest}
            isLoading={isLoading}
          />
        );
      case RequestsTypesKeys.SUBMIT_CONTENT_PLAN:
        return (
          <SubmitContentPlanRequestContainer
            request={currentRequest}
            isLoading={isLoading}
          />
        );
      case RequestsTypesKeys.INVESTMENT_REJECTED:
      case RequestsTypesKeys.CAMPAIGN_PARTICIPATION_REJECT:
      case RequestsTypesKeys.CHECK_INVESTMENT:
        return (
          <CheckInvestmentRequestContainer
            request={currentRequest}
            isLoading={isLoading}
          />
        );
      case RequestsTypesKeys.TGE_DATE_CHANGE:
        return (
          <TgeDateChangeRequestContainer
            request={currentRequest}
            isLoading={isLoading}
          />
        );
      case RequestsTypesKeys.CONTACT_SUPPORT_TGE:
        return (
          <ContactSupportTgeRequestContainer
            request={currentRequest}
            isLoading={isLoading}
          />
        );
      case RequestsTypesKeys.POSTS_CONTEST:
        return (
          <PostsContestRequestContainer
            request={currentRequest}
            isLoading={isLoading}
          />
        );
      case RequestsTypesKeys.REFUND:
        return (
          <RefundRequestContainer
            request={currentRequest}
            isLoading={isLoading}
          />
        );
      case RequestsTypesKeys.FUNDING_ROUND_REFUND:
        return (
          <FundingRoundRefundContainer
            request={currentRequest}
            isLoading={isLoading}
          />
        );
      case RequestsTypesKeys.AFFILIATE_WITHDRAWAL:
        return (
          <AffiliateWithdrawalRequestContainer
            request={currentRequest}
            isLoading={isLoading}
          />
        );
      case RequestsTypesKeys.FUNDING_ROUND_APPLICATION:
        return (
          <FundingRoundApplicationRequestContainer
            request={currentRequest}
            isLoading={isLoading}
          />
        );
      case RequestsTypesKeys.FUNDING_LEGAL_APPROVAL:
        return (
          <FundingRoundLegalApprovalRequestContainer
            id={id ?? ''}
            request={currentRequest}
            isLoading={isLoading}
          />
        );
      case RequestsTypesKeys.FUNDING_ROUND_PAYOUT:
      case RequestsTypesKeys.PAYOUT:
        return (
          <PayoutRequestContainer
            request={currentRequest}
            isLoading={isLoading}
          />
        );
      case RequestsTypesKeys.FUNDING_ROUND_CREDITS_AND_ETHEREUM:
        return (
          <FundingRoundCreditsContainer
            request={currentRequest}
            isLoading={isLoading}
          />
        );
      case RequestsTypesKeys.CAMPAIGN_ETHEREUM_SWAP:
        return (
          <CampaignEthereumSwapContainer
            request={currentRequest}
            isLoading={isLoading}
          />
        );
    }
  };

  useEffect(
    () => (): void => {
      dispatch(resetRequestData());
    },
    [dispatch],
  );

  return (
    <StyledPageWrapper>
      <Title $type="h3">{t('requests_information_title')}</Title>
      <StyledButton type="ghost" onClick={navigateToRequests}>
        <StyledArrowRightIcon />
        {t('requests_go_back')}
      </StyledButton>
      {getSectionContent(type)}
    </StyledPageWrapper>
  );
};

export default RequestsDetailsContainer;
