import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { ROUTES_PATHS } from '@/router';

// Components
import {
  StyledArrowRightIcon,
  StyledButton,
  StyledStatus,
  StyledStatusWrapper,
} from './KOlDetailsContainerStyles';
import { StyledPageWrapper, Title } from '@/ui';
import { StatusChangeBlock, Tabs } from '@/components';
import { GeneralInfo, InfluencerData } from '../../components';

// Models
import { RequestersRoles } from '@/models/requests.model';
import { AppStage } from '@/models/global.model';
import {
  LegalAgreementRoles,
  SeedHunterCreditRoles,
} from '@/models/sharedProfile.model';
import { LogEntities } from '@/models/logs.model';
import { AdminActionsEnum } from '@/models/adminsList.model';
import { UserRoles } from '@/models/admin.model';

// Modules
import { kolsListSliceSelector } from '@/modules/KolsList/feature/selectors';
import {
  resetKolsListState,
  updateKolDetails,
} from '@/modules/KolsList/feature/slice';
import { useGetRequestCount } from '@/modules/Requests';
import {
  AffiliateWithdrawalHistoryContainer,
  getStatusesConfig,
  LegalAgreementsContainer,
  SeedHunterCreditContainer,
  UserForceBlockSwitch,
} from '@/modules/SharedProfile';
import {
  Affiliate,
  AffiliateFundingContainer,
  InvitedUsers,
} from '@/modules/SharedAffiliate';
import { getLogs } from '@/modules/Logs';
import { usePermissions } from '@/modules/Permissions';

// Helpers
import {
  KOLDetailsTabKeys,
  projectParticipantsTabsConfig,
  statusesConfigs,
} from '../../constants';
import { useAppDispatch, useAppStage, useAppState, useTabs } from '@/hooks';
import { getUserId } from '@/utils';
import {
  blockKOL,
  getInvitedUsers,
  getKOLAffiliateData,
  getKOLDetails,
} from '../../feature/actionCreators';
import { AccountStatuses } from '@/constants';

const KOlDetailsContainer = () => {
  const { t } = useTranslation(['kolsList']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id, section } = useParams();
  const { appStage } = useAppStage();
  const getRedirectUrl = (key: string) =>
    `${ROUTES_PATHS.KOL_LIST}/${encodeURIComponent(id ?? '')}/${key}`;
  const { onChange } = useTabs(KOLDetailsTabKeys.GENERAL_INFO, getRedirectUrl);
  const [permissionToEdit] = usePermissions(AdminActionsEnum.WRITE_KOL);
  const { kolDetails, affiliateData, isLoading, invitedUsers } = useAppState(
    kolsListSliceSelector,
  );
  const count = useGetRequestCount(RequestersRoles.KOL, kolDetails?.id);

  const navigateToKolList = () => {
    navigate(ROUTES_PATHS.KOL_LIST);
  };

  const getNextAffiliateData = useCallback(
    debounce(() => {
      if (isLoading || !affiliateData?.hasMore) return;
      const lastItem = affiliateData?.items?.at(-1);
      dispatch(
        getKOLAffiliateData({
          startId: lastItem?.sk,
          kolId: getUserId(id ?? ''),
        }),
      );
    }, 1000),
    [isLoading, affiliateData?.hasMore, dispatch],
  );

  const getNextInvitedUsers = useCallback(
    debounce(() => {
      if (isLoading || !invitedUsers?.hasMore) return;
      const lastItem = invitedUsers?.items?.at(-1);
      dispatch(
        getInvitedUsers({
          startId: lastItem?.id as string,
          userId: getUserId(id ?? ''),
        }),
      );
    }, 1000),
    [isLoading, invitedUsers?.hasMore, dispatch],
  );

  const onForceBlockSuccess = useCallback((status: string) => {
    dispatch(updateKolDetails({ status }));
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getKOLDetails(getUserId(id)));
      getNextAffiliateData();
      getNextInvitedUsers();
    }
  }, [id, kolDetails?.status]);

  useEffect(
    () => (): void => {
      dispatch(resetKolsListState());
    },
    [dispatch],
  );

  const handleBlockUser = useCallback(
    async (description: string) => {
      await dispatch(
        blockKOL({
          id: getUserId(kolDetails?.id ?? ''),
          reason: description,
        }),
      ).unwrap();
      dispatch(
        getLogs({
          entity: LogEntities.KOL,
          id: getUserId(kolDetails?.id ?? ''),
        }),
      );
    },
    [dispatch, kolDetails],
  );

  const getSectionContent = (section?: string) => {
    switch (section) {
      case KOLDetailsTabKeys.GENERAL_INFO:
        return <GeneralInfo kolDetails={kolDetails} isLoading={isLoading} />;
      case KOLDetailsTabKeys.INFLUENCER_DATA:
        return <InfluencerData kolDetails={kolDetails} isLoading={isLoading} />;
      case KOLDetailsTabKeys.AFFILIATE:
        return (
          <>
            <Affiliate
              affiliateData={affiliateData}
              isLoading={isLoading}
              getNextAffiliateData={getNextAffiliateData}
              overallBalance={kolDetails?.totalAffiliateCommissionAmount}
            />
            <InvitedUsers
              invitedUsers={invitedUsers?.items}
              hasMore={!!invitedUsers?.hasMore}
              isLoading={isLoading}
              getNextInvitedUsers={getNextInvitedUsers}
            />
            <AffiliateWithdrawalHistoryContainer
              id={getUserId(id ?? '')}
              role={LegalAgreementRoles.KOL}
            />
          </>
        );
      case KOLDetailsTabKeys.AFFILIATE_FUNDING:
        return (
          <AffiliateFundingContainer
            id={getUserId(id ?? '')}
            role={LegalAgreementRoles.KOL}
          />
        );
      case KOLDetailsTabKeys.SEED_HUNTER_CREDITS:
        return (
          <SeedHunterCreditContainer
            isLoading={isLoading}
            role={SeedHunterCreditRoles.KOL}
            id={getUserId(id ?? '')}
          />
        );
      case KOLDetailsTabKeys.LEGAL_AGREEMENTS:
        return <LegalAgreementsContainer role={LegalAgreementRoles.KOL} />;
    }
  };

  const tabs = projectParticipantsTabsConfig.filter((tab) =>
    tab.stages.includes(appStage as AppStage),
  );

  return (
    <StyledPageWrapper>
      <StyledStatusWrapper>
        <Title $type="h3">{`${kolDetails?.firstName} ${kolDetails?.lastName}`}</Title>
        <StyledStatus>
          <Title $type="h2">{t('kols_general_info_force_block')}</Title>
          <UserForceBlockSwitch
            id={getUserId(kolDetails?.id ?? '')}
            role={UserRoles.KOL}
            disabled={!permissionToEdit}
            initialIsBlocked={kolDetails?.status === AccountStatuses.BLOCKED}
            onSuccess={onForceBlockSuccess}
          />

          <Title $type="h2">{t('kols_general_info_status')}</Title>
          <StatusChangeBlock
            status={kolDetails?.status}
            isEditable={permissionToEdit}
            handleRequest={handleBlockUser}
            statusesConfigs={getStatusesConfig(
              kolDetails?.status ?? '',
              statusesConfigs,
            )}
          />
        </StyledStatus>
      </StyledStatusWrapper>
      <StyledButton type="ghost" onClick={navigateToKolList}>
        <StyledArrowRightIcon />
        {t('kols_list_details_go_back')}
      </StyledButton>
      <Tabs
        count={count}
        sections={tabs}
        activeTab={section}
        onChange={onChange}
        withCount
      />
      {getSectionContent(section)}
    </StyledPageWrapper>
  );
};

export default KOlDetailsContainer;
