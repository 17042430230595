// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledTitles,
  StyledButtonsWrapper,
  StyledButton,
  StyledModal,
} from './InfluencerDataStyles';
import { IFormInstance, ModalSize } from '@/components';
import { Link, Title } from '@/ui';

// Helpers
import { ROUTES_PATHS } from '@/router';

interface IAcceptModal {
  formInstance: IFormInstance;
  isOpened: boolean;
  isLoading: boolean;
  handleClose: () => void;
}

const AcceptModal = ({
  isOpened,
  handleClose,
  formInstance,
  isLoading,
}: IAcceptModal) => {
  const { t } = useTranslation(['kolsList']);

  const handleSubmit = () => {
    formInstance.submit();
    handleClose();
  };

  return (
    <StyledModal
      visible={isOpened}
      onCancel={handleClose}
      size={ModalSize.LARGE}
      modalRender={(modalNode) => modalNode}
    >
      <StyledTitles>
        <Title $type="h2">{t('modal_title')}</Title>
        <Link to={ROUTES_PATHS.SOCIAL_CHANNEL}>
          {t('kols_list_button_channel')}
        </Link>
      </StyledTitles>
      <StyledButtonsWrapper>
        <StyledButton
          disabled={isLoading}
          onClick={handleSubmit}
          type="primary"
        >
          {t('edit_save')}
        </StyledButton>
        <StyledButton type="default" onClick={handleClose}>
          {t('cancel')}
        </StyledButton>
      </StyledButtonsWrapper>
    </StyledModal>
  );
};

export default AcceptModal;
