// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  MetricsCardWrapper,
  StyledInfoWrapper,
  StyledTitle,
  StyledText,
  StyledChannelLink,
} from './CampaignResultsStyles';

// modules
import { MobileOnly } from '@/modules/Adaptive';
import { socialChannelsConfig } from '@/modules/KolsList';

// Models
import { IParticipationOverview } from '@/models/campaigns.model';
import { SocialChannels } from '@/models/socialChannel.model';

// Helpers
import { numberWithCommas } from '@/utils';

type TPerformanceMetricsCard = {
  data: IParticipationOverview;
};

const PerformanceMetricsCard = ({ data }: TPerformanceMetricsCard) => {
  const { t } = useTranslation(['campaigns']);

  return (
    <MetricsCardWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('campaign_metrics_kol_alias')}</StyledTitle>
        </MobileOnly>
        <StyledText>{data?.kolAlias}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('campaign_metrics_profile')}</StyledTitle>
        </MobileOnly>
        <StyledChannelLink
          href={data?.socialNetworkLink}
          target={'_blank'}
          key={SocialChannels.TWITTER}
        >
          {socialChannelsConfig[SocialChannels.TWITTER]?.icon}
        </StyledChannelLink>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('campaign_metrics_followers')}</StyledTitle>
        </MobileOnly>
        <StyledText>{numberWithCommas(data?.followers ?? 0)}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('campaign_metrics_engagement')}</StyledTitle>
        </MobileOnly>
        <StyledText>{numberWithCommas(data?.engagement ?? 0)}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('campaign_metrics_reach')}</StyledTitle>
        </MobileOnly>
        <StyledText>{numberWithCommas(data?.reach ?? 0)}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('campaign_metrics_planned_posts')}</StyledTitle>
        </MobileOnly>
        <StyledText>{numberWithCommas(data?.bookedPosts ?? 0)}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('campaign_metrics_delivered_posts')}</StyledTitle>
        </MobileOnly>
        <StyledText $isColored>
          {numberWithCommas(data?.deliveredPosts ?? 0)}
        </StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('campaign_metrics_total_participation')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          {t('campaign_metrics_total_participation_value', {
            deliveredAmount: numberWithCommas(
              data?.deliveredParticipation ?? 0,
            ),
            bookedAmount: numberWithCommas(data?.bookedParticipation ?? 0),
          })}
        </StyledText>
      </StyledInfoWrapper>
    </MetricsCardWrapper>
  );
};

export default PerformanceMetricsCard;
