// Global
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Spinner } from '@/ui';
import {
  FundingRoundLegalApprovalRequestDetails,
  StyledPageWrapper,
} from '../../components';

// Models
import { IRequestDetails } from '@/models/requests.model';
import { LogEntities } from '@/models/logs.model';

// Modules
import { LogContainer } from '@/modules/Logs';
import {
  currentContractDataSelector,
  DocumentSignModal,
  getFundingContractInformationDocument,
  selectDocumentsIsLoading,
  signClickContract,
} from '@/modules/Documents';

// Hooks
import { useAppDispatch, useAppState, useModal } from '@/hooks';
import { DocumentTypes, SigningTypes } from '@/models/documents.model';
import { showErrorToast } from '@/components';
import { getUserId } from '@/utils';
import { getRequestDetails } from '../../feature/actionCreators';

interface IFundingRoundLegalApprovalRequestContainer {
  request: IRequestDetails | null;
  isLoading: boolean;
  id?: string;
}

const FundingRoundLegalApprovalRequestContainer = ({
  request,
  isLoading,
  id,
}: IFundingRoundLegalApprovalRequestContainer) => {
  const { t } = useTranslation(['requestsList']);
  const dispatch = useAppDispatch();
  const contractData = useAppState(currentContractDataSelector);
  const isDocumentLoading = useAppState(selectDocumentsIsLoading);
  const {
    isOpened: isSignModalOpen,
    openModal: openSignModal,
    closeModal: closeSignModal,
  } = useModal();

  const onClickSign = useCallback(
    async (type: DocumentTypes) => {
      const document = request?.fundingRound?.adminDocuments.find(
        (doc) => doc.type === type,
      );

      if (!document?.id || !request?.fundingRound?.id) return;

      try {
        await dispatch(
          getFundingContractInformationDocument({
            id: getUserId(request?.fundingRound?.id),
            documentId: document?.id,
            signingType: SigningTypes.CLICK,
          }),
        );

        openSignModal();
      } catch (err) {
        showErrorToast({ message: (err as Error).message });
      }
    },
    [request],
  );

  const signCurrentClickContract = useCallback(async () => {
    if (!contractData?.id) return;

    try {
      await dispatch(
        signClickContract({
          documentId: contractData?.id ?? '',
          termsAccepted: true,
        }),
      ).unwrap();
      dispatch(getRequestDetails(getUserId(id ?? '')));
      closeSignModal();
    } catch (e) {
      console.warn(e);
    }
  }, [contractData, id]);

  return (
    <StyledPageWrapper>
      <FundingRoundLegalApprovalRequestDetails
        request={request}
        onClickSign={onClickSign}
        isLoading={isLoading}
      />
      {isLoading ? (
        <Spinner />
      ) : (
        <LogContainer entity={LogEntities.REQUESTS} id={request?.id} />
      )}
      <DocumentSignModal
        onClose={closeSignModal}
        onConfirm={signCurrentClickContract}
        documentHtml={contractData?.agreementHtmlContent}
        isOpened={isSignModalOpen}
        loading={isDocumentLoading}
        title={t(`contract_types_${contractData?.templateType}`, {
          ns: 'common',
        })}
      />
    </StyledPageWrapper>
  );
};

export default FundingRoundLegalApprovalRequestContainer;
