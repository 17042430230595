// Globals
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

// Components
import { Title, StyledPageWrapper } from '@/ui';
import {
  PayoutList,
  StyledButton,
  StyledHeader,
  StyledExportIcon,
  FundingRoundRefundList,
  FundingRoundPayoutList,
} from '../../components';
import { showSuccessToast, TableTabs } from '@/components';
import { StyledTabsWrapper } from './PayoutListContainerStyles';

// Store
import { useAppDispatch, useAppState, useTabs } from '@/hooks';
import { payoutSliceSelector } from '../../feature/selectors';
import {
  completeCampaign,
  completeFundingRound,
  getFundingPayoutList,
  getFundingRefundList,
  getPayoutList,
  sendFundingPayoutRequest,
  sendPayoutRequest,
} from '../../feature/actionCreators';

// Helpers
import { resetPayoutList } from '../../feature/slice';
import { getUserId } from '@/utils';
import { PayoutTabKeys, payoutTabsConfig } from '../../constants';
import { useExportPayoutsData } from '../../hooks';

const PayoutListContainer = () => {
  const { t } = useTranslation(['payout', 'campaigns', 'fundingRound']);
  const dispatch = useAppDispatch();
  const { section } = useParams();
  const { payoutList, fundingPayoutList, fundingRefundList, isLoading } =
    useAppState(payoutSliceSelector);
  const { activeTab, onChange } = useTabs(section ?? PayoutTabKeys.CAMPAIGN);
  const { isLoading: isExportLoading, handleExport } = useExportPayoutsData({
    activeTab: activeTab as PayoutTabKeys,
  });

  const handleCompleteCampaign = useCallback(
    async (id: string, isRequest?: boolean) => {
      if (isRequest) {
        await dispatch(sendPayoutRequest(getUserId(id ?? ''))).unwrap();
      } else {
        await dispatch(completeCampaign(getUserId(id ?? ''))).unwrap();
      }

      showSuccessToast({
        message: t(
          isRequest
            ? 'campaign_complete_request_success'
            : 'campaign_complete_success',
          {
            ns: 'campaigns',
          },
        ),
      });
    },
    [],
  );

  const handleCompleteFundingRound = useCallback(
    async (id: string, isRequest?: boolean) => {
      if (isRequest) {
        await dispatch(sendFundingPayoutRequest(getUserId(id ?? ''))).unwrap();
      } else {
        await dispatch(completeFundingRound(getUserId(id ?? ''))).unwrap();
      }
      showSuccessToast({
        message: t('funding_rounds_complete_success', {
          ns: 'fundingRound',
        }),
      });
    },
    [],
  );

  const getPayoutPageContent = () => {
    switch (activeTab) {
      case PayoutTabKeys.CAMPAIGN:
        return (
          <PayoutList
            payoutList={payoutList}
            isLoading={isLoading}
            completeCampaign={handleCompleteCampaign}
          />
        );
      case PayoutTabKeys.FUNDING_ROUND:
        return (
          <FundingRoundPayoutList
            payoutList={fundingPayoutList}
            isLoading={isLoading}
            completeFundingRound={handleCompleteFundingRound}
          />
        );
      case PayoutTabKeys.FUNDING_ROUND_REFUND:
        return (
          <FundingRoundRefundList
            refundList={fundingRefundList}
            isLoading={isLoading}
          />
        );
    }
  };

  useEffect(() => {
    dispatch(getPayoutList());
    dispatch(getFundingPayoutList());
    dispatch(getFundingRefundList());

    return (): void => {
      dispatch(resetPayoutList());
    };
  }, [dispatch]);

  return (
    <StyledPageWrapper>
      <StyledHeader>
        <Title $type="h3">{t('payout_title')}</Title>
        <StyledButton
          type="primary"
          onClick={handleExport}
          loading={isExportLoading}
          disabled={isExportLoading || isLoading}
        >
          {t('payout_export')}
          <StyledExportIcon />
        </StyledButton>
      </StyledHeader>

      <StyledTabsWrapper>
        <TableTabs
          currentTab={activeTab}
          config={payoutTabsConfig}
          onTabChange={onChange}
        />
      </StyledTabsWrapper>
      {getPayoutPageContent()}
    </StyledPageWrapper>
  );
};

export default PayoutListContainer;
