// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledRatioWrap,
  StyledLabel,
  StyledRatioBlock,
} from './CampaignResultsStyles';

// Models
import { IParticipationSummary } from '@/models/campaigns.model';

// Helpers
import { numberWithCommas } from '@/utils';

type TCampaignRates = {
  participationSummary: IParticipationSummary;
};

const CampaignRates = ({ participationSummary }: TCampaignRates) => {
  const { t } = useTranslation(['campaigns']);

  return (
    <StyledRatioWrap>
      <StyledRatioBlock>
        <div>{t('campaign_overview_total_engagement')}</div>
        <StyledLabel $isBold>
          {numberWithCommas(participationSummary?.totalEngagement ?? 0)}
        </StyledLabel>
      </StyledRatioBlock>
      <StyledRatioBlock>
        <div>{t('campaign_overview_engagement_ratio')}</div>
        <StyledLabel $isBold>
          {t('campaign_overview_percent_amount', {
            amount: numberWithCommas(
              participationSummary?.engagementRatio ?? 0,
              4,
            ),
          })}
        </StyledLabel>
      </StyledRatioBlock>
      <StyledRatioBlock>
        <div>{t('campaign_overview_reach')}</div>
        <StyledLabel $isBold>
          {numberWithCommas(participationSummary?.totalReach ?? 0)}
        </StyledLabel>
      </StyledRatioBlock>
      <StyledRatioBlock>
        <div>{t('campaign_overview_reach_ratio')}</div>
        <StyledLabel $isBold>
          {t('campaign_overview_percent_amount', {
            amount: numberWithCommas(participationSummary?.reachRatio ?? 0, 4),
          })}
        </StyledLabel>
      </StyledRatioBlock>
      <StyledRatioBlock>
        <div>{t('campaign_overview_CPM')}</div>
        <StyledLabel $isBold>
          {t('campaign_overview_amount', {
            amount: numberWithCommas(participationSummary?.cpm ?? 0),
          })}
        </StyledLabel>
      </StyledRatioBlock>
      <StyledRatioBlock>
        <div>{t('campaign_overview_CPE')}</div>
        <StyledLabel $isBold>
          {t('campaign_overview_amount', {
            amount: numberWithCommas(participationSummary?.cpe ?? 0),
          })}
        </StyledLabel>
      </StyledRatioBlock>
      <StyledRatioBlock>
        <div>{t('campaign_overview_kol_participation')}</div>
        <StyledLabel $isBold>
          {numberWithCommas(participationSummary?.amountOfKOLs ?? 0)}
        </StyledLabel>
      </StyledRatioBlock>
    </StyledRatioWrap>
  );
};

export default CampaignRates;
