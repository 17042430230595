export const COLORS = {
  WHITE: '#ffffff',
  WHITE_SMOKE: '#F7FAFC',
  WHITE_SMOKER: '#F7F9FD',
  ALICE_BLUE: '#F6F9FB',
  BLACK: '#121212',
  LIQUORICE_BLACK: '#27272E',
  TUNDORA: '#494949',
  GREY: '#4A5568',
  BLUE_PRIMARY: '#4C6FFF',
  BLUE_PRIMARY_HOVER: '#36bdda',
  BLUE_PRIMARY_ACTIVE: '#1e909f',
  CHABLIS_PEACH: '#FFEDE3',
  YELLOW_SEA: '#F59735',
  LAVENDER_BLUSH: '#FFEEF1',
  DEEP_PURPLE: '#170F49',
  DUSTY_LAVENDER: '#6F6C90',
  GOSSAMER: '#4AAE8C',
  PINK_SALMON: '#FF92AE',
  SOLITUDE: '#E1E8FF',
  ATOMIC_TANGERINE: '#F7936F',
  PARIS_DAISY: '#EEEE4E',
  PEACH_LIGHT: '#FCE0D3',
  PAPAYA: '#FFEFD6',
  ROSE: '#FF3C38',
  ORANGE_PRIMARY: '#FEAD15',
  ORANGE_PRIMARY_HOVER: '#ffb63d',
  ORANGE_PRIMARY_ACTIVE: '#e79b11',
  ORANGE_PUMPKIN: '#FB7325',
  ORANGE_SUPERNOVA: '#FCAB33',
  CHIFFON: '#FBFFB6',
  NEON_CARROT: '#F98E2C',
  PALE_ORANGE: '#ffe3c1',
  PATTERNS_BLUE: '#C9E2FF',
  CYAN_LIGHT: '#E5FDFF',
  PEACH: 'rgba(242, 99, 37, 0.2)',
  LIGHT_GREY: '#E2E8F0',
  LIGHT_BLUE: '#E5FDFF',
  SOFT_GREY: '#b2b5b9',
  LIGHT_PEACH: 'rgba(253, 174, 51, 0.2)',
  LIGHTER_PEACH: 'rgba(253, 174, 51, 0.3)',
  DARKEST_GRAY: '#333532',
  DARK_GRAY: '#808080',
  GRAY: '#718096',
  SHADOW_MOUNTAIN: '#585858',
  LIGHTEST_GRAY: '#EDF2F7',
  LIGHT_GRAY: '#EDEDED',
  WHISPER_GRAY: '#D3D3D3',
  SOLITUDE_GRAY: '#E4ECF7',
  SOLITUDE_DARK: '#505780',
  SNOW: '#FAFAFA',
  SOFT_GRAY: '#B5B5BD',
  DIVIDER: '#D6D6D6',
  EGG_SOUR: '#FBE3BE',
  RED: '#EB5757',
  YELLOW: '#F6C16B',
  GREEN: '#27AE60',
  LIGHT_GREEN: '#66CB9F',
  CORN_FLOWER_BLUE: '#425466',
  DARK_GREY: '#7A828A',
  GREEN_LIGHT: '#DFFFEE',
  GREEN_MINT: '#66CB9F',
  GREEN_BUBBLES: '#DEFFEE',
  DARK_BLUE: '#0F132B',
  SAMBUCUS: '#16192C',
  BRIGHT_ORANGE: '#FDAE33',
  LIGHT_ORANGE: '#FDAE3399',
  HIGHLIGHTER_ORANGE: '#F49535',
  GRAYISH_BLUE: '#A6B7D4',
  GRAYISH_BLUE_LIGHT: '#CBD5E0',
  AZUL: '#3843D0',
  PURPLE_BLUE: '#5924EB',
  PICH_FROLY: '#F16063',
  CERULEAN: '#03A9F4',
  COQUELICOT: '#FF3D00',
  TURQUOISE_BLUE: '#68DBF2',
  ALICE_BLUE_LIGHT: '#EBF2FA',
  LAVENDER_LIGHT: '#E6E0F6',
  MISTY_ROSE: '#FFE6E4',
};

export const FONT_WEIGHTS = {
  BOLD: 700,
  SEMI_BOLD: 600,
  SEMI_NORMAL: 500,
  NORMAL: 400,
  SEMI_SMALL: 300,
  SMALL: 200,
};

export const FONT_SIZES = {
  '10': '0.625rem',
  '11': '0.688rem',
  '12': '0.75rem',
  '13': '0.8125rem',
  '14': '0.875rem',
  '15': '0.938rem',
  '16': '1rem',
  '18': '1.125rem',
  '20': '1.25rem',
  '22': '1.375rem',
  '24': '1.5rem',
  '25': '1.563',
  '26': '1.6rem',
  '28': '1.75rem',
  '30': '1.875rem',
  '32': '2rem',
  '36': '2.25rem',
  '38': '2.375',
  '40': '2.5rem',
  '46': '2.9rem',
  '48': '3rem',
  '56': '3.5rem',
};

export const MOBILE_LAYOUT_MAX_WIDTH = 1024;

const SCREEN_SIZES = {
  MOBILE_S: '320px',
  MOBILE: '375px',
  MOBILE_L: '425px',
  MOBILE_XL: '480px',
  TABLET_S: '641px',
  TABLET: '834px',
  LAPTOP_S: '1025px',
  LAPTOP_M: '1366px',
  LAPTOP: '1440px',
  DESKTOP: '1920px',
  DESKTOP_L: '2560px',
};

export const DEVICES = {
  MOBILE_S: `(min-width: ${SCREEN_SIZES.MOBILE_S})`,
  MOBILE: `(min-width: ${SCREEN_SIZES.MOBILE})`,
  MOBILE_L: `(min-width: ${SCREEN_SIZES.MOBILE_L})`,
  MOBILE_XL: `(min-width: ${SCREEN_SIZES.MOBILE_XL})`,
  TABLET_S: `(min-width: ${SCREEN_SIZES.TABLET_S})`,
  TABLET: `(min-width: ${SCREEN_SIZES.TABLET})`,
  LAPTOP_S: `(min-width: ${SCREEN_SIZES.LAPTOP_S})`,
  LAPTOP_M: `(min-width: ${SCREEN_SIZES.LAPTOP_M})`,
  LAPTOP: `(min-width: ${SCREEN_SIZES.LAPTOP})`,
  DESKTOP: `(min-width: ${SCREEN_SIZES.DESKTOP})`,
  DESKTOP_L: `(min-width: ${SCREEN_SIZES.DESKTOP_L})`,
};

export const SHADOWS = {
  LIGHTEST:
    '0px 3px 8px -1px rgba(50, 50, 71, 0.05), 0px 0px 1px 0px rgba(12, 26, 75, 0.24);',
  LIGHTER:
    '0px 4px 20px -2px rgba(50, 50, 71, 0.08), 0px 0px 1px 0px rgba(12, 26, 75, 0.10);',
  LIGHT:
    '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
  MEDIUM: `0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05)`,
  BIG: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
};
