// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Skeleton, Title, Tooltip } from '@/ui';
import {
  StyledFormWrapper,
  StyledBlockWrapper,
  TitleBlock,
  StyledText,
  FieldsWrapper,
  StyledTitle,
  DetailsBlock,
  StyledTextArea,
  FormFieldsWrapper,
  StyledForm,
  FormFields,
  DocumentLinksBlock,
  StyledCheckbox,
  StyledButton,
  StyledA,
  StyledBadge,
  StyledLink,
} from './FundingContractInformationStyles';
import ContractInformationItems from '../ContractInformationItems/ContractInformationItems';
import { FormItem, IFormInstance } from '@/components';

// Models
import { IFundingContract, IFundingRound } from '@/models/fundingRound.model';
import { DocumentTypes } from '@/models/documents.model';

// Helpers | Hooks
import { useGetContractFormValidationRules } from '../../hooks';
import { TextLength } from '@/constants';
import {
  FundingContractFieldsNames,
  TFundingContractValues,
} from '../../types';
import { contractInfoAdapter } from '../../helpers';

interface IFundingContractInformation {
  contractInfo: IFundingContract | null;
  handleSubmit: (
    values: TFundingContractValues,
    allValues: TFundingContractValues,
  ) => void;
  onContractPreview: (type: DocumentTypes) => void;
  handleReleaseAgreement: () => Promise<void>;
  formInstance: IFormInstance;
  fundingRound?: IFundingRound;
  isFRCompleted: boolean;
  isLoading: boolean;
  disabled: boolean;
}

const FundingContractInformation = ({
  formInstance,
  contractInfo,
  handleSubmit,
  onContractPreview,
  handleReleaseAgreement,
  isFRCompleted,
  fundingRound,
  isLoading,
  disabled,
}: IFundingContractInformation) => {
  const { t } = useTranslation(['fundingRound', 'projectsList']);
  const validationRules = useGetContractFormValidationRules();

  const renderFormItem = (
    fieldName: FundingContractFieldsNames,
    checkBoxFieldName: FundingContractFieldsNames,
    clauseNumber: number,
    label: string,
  ) => (
    <>
      <FormItem
        name={fieldName}
        rules={validationRules[fieldName]}
        label={`${t('funding_details_clause_title', {
          number: clauseNumber,
        })}: ${label}`}
      >
        <StyledTextArea
          autoSize={{ minRows: 2, maxRows: 5 }}
          showCount
          maxLength={TextLength.large}
        />
      </FormItem>

      <FormItem name={checkBoxFieldName} valuePropName="checked">
        <StyledCheckbox>
          {t('funding_details_legal_confirmation_label')}
        </StyledCheckbox>
      </FormItem>
    </>
  );

  if (!contractInfo) return <Skeleton />;

  return (
    <StyledFormWrapper>
      <StyledBlockWrapper>
        <DetailsBlock>
          <StyledTitle>{t('funding_details_terms_and_conditions')}</StyledTitle>
          <StyledText>
            {t('funding_details_terms_and_conditions_description')}
          </StyledText>
        </DetailsBlock>
        <DocumentLinksBlock>
          <StyledA
            onClick={() =>
              onContractPreview(DocumentTypes.FUNDING_ROUND_GENERAL_TERMS)
            }
          >
            <StyledBadge>
              {t(`${DocumentTypes.FUNDING_ROUND_GENERAL_TERMS}_title`)}
            </StyledBadge>
          </StyledA>
          <StyledA
            onClick={() =>
              onContractPreview(
                DocumentTypes.FUNDING_ROUND_SALE_AND_PURCHASE_AGREEMENT,
              )
            }
          >
            <StyledBadge>
              {t(
                `${DocumentTypes.FUNDING_ROUND_SALE_AND_PURCHASE_AGREEMENT}_title`,
              )}
            </StyledBadge>
          </StyledA>
          <StyledA
            onClick={() =>
              onContractPreview(DocumentTypes.FUNDING_ROUND_SERVICE_AGREEMENT)
            }
          >
            <StyledBadge>
              {t(`${DocumentTypes.FUNDING_ROUND_SERVICE_AGREEMENT}_title`)}
            </StyledBadge>
          </StyledA>
          <StyledA
            onClick={() =>
              onContractPreview(DocumentTypes.FUNDING_ROUND_RELEASE_AGREEMENT)
            }
          >
            <StyledBadge>
              {t(`${DocumentTypes.FUNDING_ROUND_RELEASE_AGREEMENT}_title`)}
            </StyledBadge>
          </StyledA>
        </DocumentLinksBlock>
      </StyledBlockWrapper>
      <StyledBlockWrapper>
        <DetailsBlock>
          <StyledTitle>{t('funding_details_release_agreement')}</StyledTitle>
        </DetailsBlock>
        <DocumentLinksBlock>
          <Tooltip
            getPopupContainer={(triggerNode) => triggerNode}
            title={
              !isFRCompleted && t('funding_details_release_agreement_tooltip')
            }
            placement={'top'}
          >
            {fundingRound?.releaseAgreementLink ? (
              <StyledLink
                to={fundingRound?.releaseAgreementLink}
                target="_blank"
              >
                {t('funding_details_release_agreement')}
              </StyledLink>
            ) : (
              <StyledButton
                onClick={handleReleaseAgreement}
                disabled={!isFRCompleted}
                loading={isLoading}
                type="primary"
              >
                {t('funding_details_generate_release_agreement')}
              </StyledButton>
            )}
          </Tooltip>
        </DocumentLinksBlock>
      </StyledBlockWrapper>
      <FormFieldsWrapper>
        <StyledForm
          form={formInstance}
          initialValues={contractInfoAdapter(contractInfo || {})}
          onValuesChange={handleSubmit}
          disabled={disabled}
        >
          <FieldsWrapper>
            <TitleBlock>
              <Title $type="h2">{t('funding_details_definitions_title')}</Title>
            </TitleBlock>
            <FormFields>
              {renderFormItem(
                FundingContractFieldsNames.ADDITIONAL_DEFINITIONS_VALUE,
                FundingContractFieldsNames.ADDITIONAL_DEFINITIONS_CONFIRMATION,
                4,
                t('funding_details_additional_definitions_title'),
              )}

              {renderFormItem(
                FundingContractFieldsNames.EXCLUDED_JURISDICTIONS_VALUE,
                FundingContractFieldsNames.EXCLUDED_JURISDICTIONS_CONFIRMATION,
                4,
                t('funding_details_additional_jurisdictions_title'),
              )}

              {renderFormItem(
                FundingContractFieldsNames.INTELLECTUAL_PROPERTY_VALUE,
                FundingContractFieldsNames.INTELLECTUAL_PROPERTY_CONFIRMATION,
                5,
                t('funding_details_legal_intellectual_property_title'),
              )}

              {renderFormItem(
                FundingContractFieldsNames.GOVERNING_LAW_VALUE,
                FundingContractFieldsNames.GOVERNING_LAW_CONFIRMATION,
                6,
                t('funding_details_legal_governing_law_title'),
              )}

              {renderFormItem(
                FundingContractFieldsNames.DISPUTE_RESOLUTION_VALUE,
                FundingContractFieldsNames.DISPUTE_RESOLUTION_CONFIRMATION,
                7,
                t('funding_details_legal_dispute_resolution_title'),
              )}

              {renderFormItem(
                FundingContractFieldsNames.RETENTION_OF_RIGHTS_VALUE,
                FundingContractFieldsNames.RETENTION_OF_RIGHTS_CONFIRMATION,
                8,
                t('funding_details_legal_retention_of_rights_title'),
              )}

              {renderFormItem(
                FundingContractFieldsNames.NOTICES_VALUE,
                FundingContractFieldsNames.NOTICES_CONFIRMATION,
                9,
                t('funding_details_legal_notices_title'),
              )}
            </FormFields>
          </FieldsWrapper>
          <FieldsWrapper>
            <TitleBlock>
              <Title $type="h2">
                {t('funding_details_additional_clauses_title')}
              </Title>
            </TitleBlock>

            <ContractInformationItems disabled={disabled} />
          </FieldsWrapper>
        </StyledForm>
      </FormFieldsWrapper>
    </StyledFormWrapper>
  );
};

export default FundingContractInformation;
