import { IApiError } from '@/models/apiError.model';
import {
  IClickWrapTemplateData,
  IGetDocumentPreviewDataResponse,
} from '@/models/documents.model';

export const DOCUMENTS_SLICE_NAME = 'documents';

export interface DocumentsState {
  isLoading: boolean;
  clickWrapTemplates: IClickWrapTemplateData[] | null;
  clickWrapTemplateDetails: IClickWrapTemplateData | null;
  currentContractData: IGetDocumentPreviewDataResponse | null;
  error: IApiError | null;
}

export const initialState: DocumentsState = {
  isLoading: false,
  clickWrapTemplates: null,
  clickWrapTemplateDetails: null,
  currentContractData: null,
  error: null,
};
