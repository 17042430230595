import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { PROJECTS_SLICE_NAME, ProjectsState, initialState } from './models';
import { showApiErrors } from '@/utils';
import {
  getProjectsList,
  getProjectDetails,
  updateProjectDetails,
  getContractInfo,
  getContractPreviewUrl,
  getProjectAffiliateData,
  getProjectInvitedUsers,
} from './actionCreators';
import { IProject } from '@/models/projects.model';
import { MarketingAffiliateCommission } from '@/models/kolsList.model';

export const slice = createSlice({
  name: PROJECTS_SLICE_NAME,
  initialState,
  reducers: {
    resetProjectsState() {
      return { ...initialState };
    },
    resetProjectsList(state) {
      state.projectsData = {
        items: [],
        hasMore: true,
      };
    },
    setProjectsFilters(state, action) {
      state.projectsFilters = action.payload;
      state.projectsData = initialState.projectsData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjectsList.fulfilled, (state: ProjectsState, action) => {
        state.projectsData = {
          items: (action.meta.arg.startId
            ? [...(state.projectsData?.items ?? []), ...action.payload.items]
            : [...action.payload.items]) as IProject[],
          hasMore: action.payload.hasMore,
        };
        state.isLoading = false;
      })
      .addCase(
        getProjectAffiliateData.fulfilled,
        (state: ProjectsState, action) => {
          state.affiliateData = {
            items: (action.meta.arg.startSk
              ? [...(state.affiliateData?.items ?? []), ...action.payload.items]
              : [...action.payload.items]) as MarketingAffiliateCommission[],
            hasMore: action.payload.hasMore,
          };
          state.isLoading = false;
        },
      )
      .addCase(
        getProjectInvitedUsers.fulfilled,
        (state: ProjectsState, action) => {
          state.invitedUsers = {
            items: action.meta.arg.startId
              ? [...(state.invitedUsers?.items ?? []), ...action.payload.items]
              : [...action.payload.items],
            hasMore: action.payload.hasMore,
          };
          state.isLoading = false;
        },
      )
      .addCase(getContractInfo.fulfilled, (state: ProjectsState, action) => {
        state.serviceAgreementInfo = action.payload;
      })
      .addCase(
        getContractPreviewUrl.fulfilled,
        (state: ProjectsState, action) => {
          state.serviceAgreementPreview = action.payload.url;
        },
      )
      .addCase(getProjectDetails.fulfilled, (state: ProjectsState, action) => {
        state.projectsDetails = action.payload.data;
        state.isLoading = false;
      })
      .addCase(
        updateProjectDetails.fulfilled,
        (state: ProjectsState, action) => {
          state.projectsDetails = action.payload;
          state.isLoading = false;
        },
      )
      .addMatcher(
        isPending(
          getProjectsList,
          getProjectDetails,
          getContractInfo,
          getContractPreviewUrl,
          getProjectInvitedUsers,
        ),
        (state: ProjectsState) => {
          state.isLoading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isRejected(
          getProjectsList,
          getProjectDetails,
          getContractInfo,
          getContractPreviewUrl,
          getProjectInvitedUsers,
          updateProjectDetails,
        ),
        (state: ProjectsState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;

          showApiErrors(error);
        },
      );
  },
});

export const { resetProjectsState, resetProjectsList, setProjectsFilters } =
  slice.actions;
export default slice.reducer;
