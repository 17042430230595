// Globals
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import omit from 'lodash.omit';

// Components
import {
  StyledInfoWrapper,
  StyledInfo,
  StyledButton,
} from './GeneralInfoStyles';
import { Text } from '@/ui';
import {
  EditButton,
  showErrorToast,
  showSuccessToast,
  useForm,
} from '@/components';
import GeneralInfoDetailsBlock from '../GeneralInfoDetailsBlock/GeneralInfoDetailsBlock';
import GeneralInfoEditBlock from '../GeneralInfoEditBlock/GeneralInfoEditBlock';

// Modules
import { ProfileRequestList } from '@/modules/Requests';
import { DesktopOnly, MobileOnly } from '@/modules/Adaptive';
import { getOwnRequests } from '@/modules/Requests/feature/actionCreators';
import { LogContainer, getLogs } from '@/modules/Logs';
import { ContractInfo } from '@/modules/SharedProfile';
import { usePermissions } from '@/modules/Permissions';

// Models
import { IPartner } from '@/models/partnersList.model';
import { RequestersRoles } from '@/models/requests.model';
import { LogEntities } from '@/models/logs.model';
import { AdminActionsEnum } from '@/models/adminsList.model';

// Hooks | Utils
import { checkFieldValidation, getUserId, validateWalletFields } from '@/utils';
import { useAppDispatch, useBoolean } from '@/hooks';

// Helpers
import { updatePartner } from '../../feature/actionCreators';
import { GeneralInfoFieldsNames } from '../../helpers';
import { ROUTES_PATHS } from '@/router';

interface IGeneralInfo {
  partnerDetails: IPartner | null;
  isLoading: boolean;
}

const GeneralInfo = ({ partnerDetails, isLoading }: IGeneralInfo) => {
  const { t } = useTranslation(['partnersList']);
  const navigate = useNavigate();
  const { value: isEditActive, toggle: setIsEditActive } = useBoolean();
  const [permissionToEdit] = usePermissions(AdminActionsEnum.WRITE_PARTNER);
  const [form] = useForm();
  const dispatch = useAppDispatch();

  const navigateToRequests = () => {
    navigate(ROUTES_PATHS.REQUESTS);
  };

  useEffect(() => {
    partnerDetails &&
      dispatch(
        getOwnRequests({
          role: RequestersRoles.PARTNER,
          id: getUserId(partnerDetails?.id ?? ''),
        }),
      );
  }, [partnerDetails]);

  const handleSubmit = useCallback(
    async (values: IPartner) => {
      await checkFieldValidation(values, form);
      const isWalletFieldsFilled =
        !!values[GeneralInfoFieldsNames.WALLET_ADDRESS] &&
        !!values[GeneralInfoFieldsNames.WALLET_CURRENCY];

      if (isWalletFieldsFilled) {
        const isValid = await validateWalletFields(
          form,
          dispatch,
          partnerDetails?.id,
        );

        if (!isValid) return;
      }

      try {
        partnerDetails &&
          (await dispatch(
            updatePartner({
              partner: omit(values, 'email'),
              id: getUserId(partnerDetails.id ?? ''),
            }),
          ).unwrap());
        setIsEditActive();
        dispatch(
          getLogs({
            entity: LogEntities.PARTNER,
            id: getUserId(partnerDetails?.id ?? ''),
          }),
        );
        showSuccessToast({ message: t('hunters_general_success_toast') });
      } catch (e: unknown) {
        showErrorToast({ message: t('hunters_general_error_toast') });
      }
    },
    [form, dispatch, partnerDetails],
  );

  return (
    <>
      <StyledInfoWrapper>
        {isEditActive ? (
          <GeneralInfoEditBlock
            partnerDetails={partnerDetails}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            formInstance={form}
          />
        ) : (
          <GeneralInfoDetailsBlock partnerDetails={partnerDetails} />
        )}
        <StyledInfo>
          {permissionToEdit && (
            <DesktopOnly>
              <EditButton
                isEditActive={isEditActive}
                setIsEditActive={setIsEditActive}
              />
            </DesktopOnly>
          )}
        </StyledInfo>
        <StyledInfo>
          <StyledButton type="ghost" onClick={navigateToRequests}>
            <Text>{t('hunters_go_to_requests')}</Text>
          </StyledButton>
          <ProfileRequestList />
        </StyledInfo>
        {permissionToEdit && (
          <MobileOnly>
            <EditButton
              isEditActive={isEditActive}
              setIsEditActive={setIsEditActive}
              position={'1rem'}
            />
          </MobileOnly>
        )}
      </StyledInfoWrapper>
      <ContractInfo userData={partnerDetails} />
      <LogContainer entity={LogEntities.PARTNER} id={partnerDetails?.id} />
    </>
  );
};

export default GeneralInfo;
