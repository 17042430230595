// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Title } from '@/ui';
import {
  StyledWrapper,
  StyledActionButton,
  StyledModal,
} from './ConfirmationModalStyles';

interface IConfirmationModal {
  isOpened: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  id?: string;
}

const ConfirmationModal = ({
  isLoading,
  isOpened,
  title,
  onConfirm,
  onClose,
}: IConfirmationModal) => {
  const { t } = useTranslation(['common']);

  return (
    <StyledModal visible={isOpened} onCancel={onClose} footer={null}>
      <Title $type="h2">{title}</Title>
      <StyledWrapper>
        <StyledActionButton
          loading={isLoading}
          type="primary"
          onClick={onConfirm}
          disabled={isLoading}
        >
          {t('approve')}
        </StyledActionButton>
        <StyledActionButton type="default" onClick={onClose}>
          {t('cancel')}
        </StyledActionButton>
      </StyledWrapper>
    </StyledModal>
  );
};

export default ConfirmationModal;
