import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { showApiErrors } from '@/utils';
import {
  getClickWrapTemplates,
  getClickWrapTemplate,
  createClickWrapTemplate,
  getFundingContractInformationDocument,
  signClickContract,
} from './actionCreators';
import { DOCUMENTS_SLICE_NAME, DocumentsState, initialState } from './models';

export const slice = createSlice({
  name: DOCUMENTS_SLICE_NAME,
  initialState,
  reducers: {
    resetDocumentsState() {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getClickWrapTemplates.fulfilled,
        (state: DocumentsState, action) => {
          state.clickWrapTemplates = action.payload;
          state.isLoading = false;
        },
      )
      .addCase(
        getFundingContractInformationDocument.fulfilled,
        (state: DocumentsState, action) => {
          state.currentContractData = action.payload;
          state.isLoading = false;
        },
      )
      .addCase(
        getClickWrapTemplate.fulfilled,
        (state: DocumentsState, action) => {
          state.clickWrapTemplateDetails = action.payload;
          state.isLoading = false;
        },
      )
      .addMatcher(
        isPending(
          getClickWrapTemplates,
          getClickWrapTemplate,
          createClickWrapTemplate,
          getFundingContractInformationDocument,
          signClickContract,
        ),
        (state: DocumentsState) => {
          state.isLoading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isRejected(
          getClickWrapTemplates,
          getClickWrapTemplate,
          createClickWrapTemplate,
          getFundingContractInformationDocument,
          signClickContract,
        ),
        (state: DocumentsState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;

          showApiErrors(error);
        },
      );
  },
});

export const { resetDocumentsState } = slice.actions;
export default slice.reducer;
