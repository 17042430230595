// Globals
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Spinner, StyledSpinnerWrap, Link, Tooltip } from '@/ui';
import {
  StyledTitles,
  PayoutListWrapper,
  StyledButtonWrap,
  StyledLinkButton,
  StyledButton,
  StyledLink,
} from './FundingRoundPayoutDetailsStyles';
import { StyledDividerBlock, StyledInfoTitles } from '../Styled';
import FundingRoundDetailsCard from '../FundingRoundDetailsCard/FundingRoundDetailsCard';
import RevenueTransferDetailsCard from '../FundingRoundDetailsCard/RevenueTransferDetailsCard';

// Modules
import { IFundingPayoutItem, RevenueTransferItem } from '@/models/payout.model';
import {
  FundingRoundStatuses,
  IFundingRound,
} from '@/models/fundingRound.model';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';
import { IReportsTabsNames } from '@/modules/Reports';

// Helpers
import { ROUTES_PATHS } from '@/router';

interface IFundingRoundPayoutDetails {
  fundingPayoutItems?: IFundingPayoutItem[] | null;
  fundingRevenueTransfers?: RevenueTransferItem[] | null;
  handleReleaseAgreement: () => Promise<void>;
  fundingRound: IFundingRound;
  isFundingRoundLoading: boolean;
  isLoading: boolean;
}

const FundingRoundPayoutDetails = ({
  isLoading,
  isFundingRoundLoading,
  fundingRound,
  handleReleaseAgreement,
  fundingPayoutItems,
  fundingRevenueTransfers,
}: IFundingRoundPayoutDetails) => {
  const { t } = useTranslation(['payout']);
  const loaderRef = useRef<HTMLDivElement>(null);
  const isFundingRoundFinished =
    fundingRound?.status === FundingRoundStatuses.FINISHED;

  const mappedPayoutItems = useMemo(() => {
    return fundingPayoutItems?.map((payoutDetails) => (
      <FundingRoundDetailsCard
        data={payoutDetails}
        key={payoutDetails?.id}
        isLoading={isLoading}
      />
    ));
  }, [fundingPayoutItems]);

  const mappedRevenueTransferItems = useMemo(() => {
    return fundingRevenueTransfers?.map((revenueTransfer) => (
      <RevenueTransferDetailsCard
        data={revenueTransfer}
        key={`${revenueTransfer.transferType}_${revenueTransfer.network}_${revenueTransfer.currency}`}
      />
    ));
  }, [fundingPayoutItems]);

  return (
    <PayoutListWrapper>
      <StyledButtonWrap>
        <Tooltip
          getPopupContainer={(triggerNode) => triggerNode}
          title={
            isFundingRoundFinished && t('payout_release_agreement_tooltip')
          }
          placement={'bottom'}
        >
          {fundingRound?.releaseAgreementLink ? (
            <StyledLink to={fundingRound?.releaseAgreementLink} target="_blank">
              {t('payout_funding_details_release_agreement')}
            </StyledLink>
          ) : (
            <StyledButton
              onClick={handleReleaseAgreement}
              disabled={isFundingRoundFinished}
              loading={isFundingRoundLoading}
              type="primary"
            >
              {t('payout_funding_details_generate_release_agreement')}
            </StyledButton>
          )}
        </Tooltip>
        <Link
          to={`${ROUTES_PATHS.REPORTS}/${
            IReportsTabsNames.FUNDING_ROUND
          }/${encodeURIComponent(
            fundingPayoutItems?.[0]?.fundingRoundId ?? '',
          )}`}
          target="_blank"
        >
          <StyledLinkButton type="primary">
            {t('payout_funding_go_to_report')}
          </StyledLinkButton>
        </Link>
      </StyledButtonWrap>

      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('revenue_transfer_type')}</StyledTitles>
          <StyledTitles>{t('revenue_transfer_amount_title')}</StyledTitles>
          <StyledTitles>{t('payout_funding_round_network')}</StyledTitles>
          <StyledTitles>{t('payout_funding_round_currency')}</StyledTitles>
          <StyledTitles>{t('revenue_transfer_status_title')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedRevenueTransferItems}
      <StyledDividerBlock />

      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('payout_project_title')}</StyledTitles>
          <StyledTitles>{t('payout_funding_round_contact_title')}</StyledTitles>
          <StyledTitles>{t('payout_funding_round_wallet_title')}</StyledTitles>
          <StyledTitles>
            {t('payout_funding_round_transfer_title')}
          </StyledTitles>
          <StyledTitles>{t('payout_funding_round_network')}</StyledTitles>
          <StyledTitles>{t('payout_funding_round_currency')}</StyledTitles>
          <StyledTitles>{t('payout_action_title')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedPayoutItems}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </PayoutListWrapper>
  );
};

export default FundingRoundPayoutDetails;
