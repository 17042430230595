import styled from 'styled-components';
import { Modal } from 'antd';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { Button } from '@/ui';

export const StyledModal = styled(Modal)`
  &&& {
    display: flex;
    flex-direction: column;

    .ant-modal-content {
      padding: 1.5rem;
      min-width: 15rem;
      margin-bottom: 6rem;
    }
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    &&& {
      .ant-modal-content {
        min-width: 50rem;
        margin-bottom: 0;
      }
    }
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  justify-content: space-between;
`;

export const StyledActionButton = styled(Button)`
  &&& {
    max-width: 12rem;
    height: 3rem;
    ${pickThemeFontStyles('14', '18', 'SEMI_BOLD')};
    align-items: center;
  }
`;

export const StyledDocumentBody = styled.div`
  &&& {
    align-items: center;
    min-height: 30rem;
    min-width: 10rem;
    width: 100%;
    overflow-x: auto;
  }
  @media screen and ${DEVICES.LAPTOP_S} {
    &&& {
      min-width: 20rem;
      width: 100%;
    }
  }
`;

export const StyledCheckboxWrap = styled.div`
  width: 100%;
  gap: 0.75rem;
  display: flex;
`;

export const StyledCheckboxText = styled.div`
  &&& {
    width: 100%;
    ${pickThemeFontStyles('12', '22', 'NORMAL')};
    color: ${COLORS.CORN_FLOWER_BLUE};
    display: inline-block;

    a {
      ${pickThemeFontStyles('12', '16', 'SEMI_BOLD')};
      align-self: center;
      color: ${COLORS.CORN_FLOWER_BLUE};
      margin-left: 0.25rem;
    }
  }
`;

export const StyledCloseWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
