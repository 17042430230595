// Globals
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledInfoWrapper,
  StyledInfo,
  StyledButton,
  StyledButtonWrap,
} from './CompanyInfoStyles';
import { Text } from '@/ui';
import { TableTabs } from '@/components';
import ContactPerson from '../ContactPerson/ContactPerson';
import CompanyInfoBlock from '../CompanyInfoBlock/CompanyInfoBlock';

// Models
import { IProject } from '@/models/projects.model';
import { RequestersRoles } from '@/models/requests.model';
import { LogEntities } from '@/models/logs.model';

// Modules
import { ProfileRequestList, getOwnRequests } from '@/modules/Requests';
import { LogContainer } from '@/modules/Logs';
import { ContractInfoProjects, WalletInfo } from '@/modules/SharedProfile';

// Hooks | Helpers
import { useAppDispatch, useTabs } from '@/hooks';
import {
  CompanyInfoTableTabKeys,
  companyInfoTabsConfig,
} from '../../constants';
import { ROUTES_PATHS } from '@/router';
import { getUserId } from '@/utils';
import { createProjectWallet } from '../../feature/actionCreators';

interface ICompanyInfo {
  projectDetails: IProject | null;
  isLoading: boolean;
  permissionToEdit?: boolean;
  handleStartFundingRound: () => void;
}

const CompanyInfo = ({
  projectDetails,
  isLoading,
  permissionToEdit,
  handleStartFundingRound,
}: ICompanyInfo) => {
  const { t } = useTranslation(['projectsList']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { activeTab, onChange } = useTabs(CompanyInfoTableTabKeys.COMPANY_INFO);

  useEffect(() => {
    projectDetails &&
      dispatch(
        getOwnRequests({
          role: RequestersRoles.PROJECT,
          id: getUserId(projectDetails?.id ?? ''),
        }),
      );
  }, [projectDetails]);

  const navigateToRequests = () => {
    navigate(ROUTES_PATHS.REQUESTS);
  };

  return (
    <>
      <TableTabs
        currentTab={activeTab}
        config={companyInfoTabsConfig}
        onTabChange={onChange}
      />
      <StyledInfoWrapper>
        {activeTab === CompanyInfoTableTabKeys.COMPANY_INFO ? (
          <CompanyInfoBlock
            projectDetails={projectDetails}
            isLoading={isLoading}
            permissionToEdit={permissionToEdit}
          />
        ) : (
          <ContactPerson
            projectDetails={projectDetails}
            isLoading={isLoading}
            permissionToEdit={permissionToEdit}
          />
        )}
        <StyledInfo>
          <StyledButton type="ghost" onClick={navigateToRequests}>
            <Text>{t('projects_go_to_requests')}</Text>
          </StyledButton>
          <ProfileRequestList />
          {projectDetails?.displayInitialFundingRoundButton && (
            <StyledButtonWrap>
              <StyledButton type="ghost" onClick={handleStartFundingRound}>
                <Text>{t('projects_start_funding_round')}</Text>
              </StyledButton>
            </StyledButtonWrap>
          )}
        </StyledInfo>
      </StyledInfoWrapper>
      <WalletInfo
        projectData={projectDetails}
        isLoading={isLoading}
        walletCreation={createProjectWallet}
      />
      <ContractInfoProjects userData={projectDetails} />
      <LogContainer entity={LogEntities.PROJECT} id={projectDetails?.id} />
    </>
  );
};

export default CompanyInfo;
