// Globals
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledForm,
  StyledFormItem,
  Wrapper,
  FormWrapper,
  StyledButton,
  StyledButtonWrapper,
} from './CompanyInfoEditBlockStyles';
import { Checkbox, CheckboxGroup, StyledInput, StyledSelect } from '@/ui';
import { FormItemGroup, IFormInstance, NestedFormItem } from '@/components';
import CompanyDocumentation from '../CompanyInfo/CompanyDocumentation';

// Modules
import { selectCompanyType } from '@/modules/DataLists';
import { ContactInfoBlock, FormOfAddressConfig } from '@/modules/SharedProfile';

// Models
import { IProject } from '@/models/projects.model';

// Helpers | Hooks
import { getSelectOptions } from '@/utils';
import { useAppState } from '@/hooks';
import { CompanyInfoFieldsNames } from '../../types';
import { useGetFormValidationRules } from '../../hooks';
import { isProjectDisabled, getVerificationLevelOptions } from '../../helpers';
import { availableSocialChannelsList } from '../../constants';

interface ICompanyInfoEditBlock {
  projectDetails: IProject | null;
  isLoading: boolean;
  formInstance: IFormInstance;
  handleSubmit: (values: IProject) => void;
  onValuesChange: (values: IProject) => void;
}

const CompanyInfoEditBlock = ({
  projectDetails,
  isLoading,
  formInstance,
  handleSubmit,
}: ICompanyInfoEditBlock) => {
  const { t } = useTranslation(['projectsList']);
  const companyType = useAppState(selectCompanyType);
  const validationRules = useGetFormValidationRules();

  useEffect(() => {
    formInstance.setFieldsValue(projectDetails);
  }, [projectDetails]);

  return (
    <StyledForm
      onFinish={handleSubmit}
      form={formInstance}
      initialValues={projectDetails ?? undefined}
      disabled={isProjectDisabled(projectDetails?.status)}
    >
      <FormWrapper>
        <Wrapper>
          <StyledFormItem
            name={CompanyInfoFieldsNames.FIRST_NAME}
            label={t('projects_general_info_first_name_title')}
            rules={validationRules[CompanyInfoFieldsNames.FIRST_NAME]}
          >
            <StyledInput />
          </StyledFormItem>
          <StyledFormItem
            name={CompanyInfoFieldsNames.LAST_NAME}
            label={t('projects_general_info_last_name_title')}
            rules={validationRules[CompanyInfoFieldsNames.LAST_NAME]}
          >
            <StyledInput />
          </StyledFormItem>
          <StyledFormItem
            name={CompanyInfoFieldsNames.FORM_OF_ADDRESS}
            label={t('projects_general_info_person_title')}
            rules={validationRules[CompanyInfoFieldsNames.FORM_OF_ADDRESS]}
          >
            <StyledSelect options={FormOfAddressConfig} />
          </StyledFormItem>
        </Wrapper>

        <Wrapper>
          <FormItemGroup prefix={['companyInfo']}>
            <NestedFormItem
              name={CompanyInfoFieldsNames.COMPANY_TYPE}
              label={t('projects_general_info_company_type_label')}
            >
              <StyledSelect options={getSelectOptions(companyType ?? [])} />
            </NestedFormItem>
          </FormItemGroup>

          <StyledFormItem
            name={CompanyInfoFieldsNames.ALLOWED_SOCIAL_CHANNELS}
            label={t('projects_general_youtube_available_title')}
          >
            <CheckboxGroup>
              {availableSocialChannelsList?.map((option) => (
                <Checkbox key={option} value={option} />
              ))}
            </CheckboxGroup>
          </StyledFormItem>

          <StyledFormItem
            name={CompanyInfoFieldsNames.VERIFICATION_LEVEL}
            label={t('projects_verification_level_label')}
          >
            <StyledSelect options={getVerificationLevelOptions(t)} />
          </StyledFormItem>
        </Wrapper>
      </FormWrapper>

      <ContactInfoBlock formInstance={formInstance} />
      <CompanyDocumentation
        disabled={isLoading}
        formInstance={formInstance}
        projectId={projectDetails?.id}
      />

      <StyledButtonWrapper>
        <StyledButton type="primary" htmlType="submit" disabled={isLoading}>
          {t('projects_general_info_save')}
        </StyledButton>
      </StyledButtonWrapper>
    </StyledForm>
  );
};

export default CompanyInfoEditBlock;
