// Globals
import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

// Store
import { proofOfDeliverySliceSelector } from '../../feature/selectors';
import {
  addCampaignPost,
  deleteCampaignPost,
  getDeliveryStatistics,
  getMarketingDeliveryData,
  getProofOfDeliveryDataDetails,
  updateCampaignPost,
} from '../../feature/actionCreators';
import { resetCurrentPostData, resetMarketingList } from '../../feature/slice';

// Components
import { MarketingDeliveryInfo, ProofOfDelivery } from '../../components';
import { showErrorToast, showSuccessToast, useForm } from '@/components';

// Modules
import { getCampaignKols, selectCampaignKols } from '@/modules/Campaigns';
import { domainValidation } from '@/modules/SharedProfile';
import {
  getProjectDetails,
  resetProjectsState,
  selectProjectDetails,
} from '@/modules/Projects';

// Models
import { ICampaignKols } from '@/models/campaigns.model';
import {
  IMarketingDeliveryInfo,
  initialPost,
  IUpdateCampaignPostDTO,
} from '@/models/marketingDelivery.model';
import { IProject } from '@/models/projects.model';

// Helpers
import { useAppDispatch, useAppState, useModal } from '@/hooks';
import { checkFieldValidation, getUserId } from '@/utils';
import { CampaignPostDTO } from '../../dtos';

type TProofOfDeliveryContainer = {
  projectId?: string;
  isCampaign?: boolean;
};

const ProofOfDeliveryContainer = ({
  projectId,
  isCampaign = false,
}: TProofOfDeliveryContainer) => {
  const { t } = useTranslation(['projectsList']);
  const dispatch = useAppDispatch();
  const { id, campaignId: projectCampaignId } = useParams();
  const { isOpened, openModal, closeModal } = useModal();
  const [form] = useForm();
  const {
    isLoading,
    marketingDelivery,
    marketingDeliveryInfo,
    deliveryStatistics,
    kolIdFilter,
    currentPostData,
  } = useAppState(proofOfDeliverySliceSelector);
  const projectDetails = useAppState(selectProjectDetails);
  const campaignKols = useAppState(selectCampaignKols);
  const projectsId = projectId ?? id;
  const campaignId = isCampaign ? getUserId(id ?? '') : projectCampaignId;

  const handleCloseModal = () => {
    dispatch(resetCurrentPostData());
    closeModal();
  };

  const getNextMarketingDelivery = useCallback(
    debounce(() => {
      if (isLoading || !marketingDelivery?.hasMore) return;
      dispatch(getMarketingDeliveryData(getUserId(projectsId ?? '')));
    }, 1000),
    [isLoading, marketingDelivery?.hasMore, dispatch, projectsId],
  );

  const getProofOfDeliveryDetailsData = useCallback(
    debounce(() => {
      if (isLoading || !marketingDeliveryInfo?.hasMore) return;
      const lastItem =
        marketingDeliveryInfo?.items?.[
          marketingDeliveryInfo?.items?.length - 1
        ];
      dispatch(
        getProofOfDeliveryDataDetails({
          id: getUserId(projectsId ?? ''),
          campaignId: campaignId ?? '',
          kolId: kolIdFilter,
          startId: lastItem?.id,
          startSk: lastItem?.sk,
        }),
      );
    }, 1000),
    [
      isLoading,
      marketingDeliveryInfo?.hasMore,
      marketingDeliveryInfo?.items,
      dispatch,
      kolIdFilter,
      projectsId,
    ],
  );

  const handleDeleteCampaignPost = useCallback(
    async (proofId: string, sk: string) => {
      await dispatch(deleteCampaignPost({ campaignId, id: proofId, sk }));
      dispatch(
        getProofOfDeliveryDataDetails({
          id: getUserId(projectsId ?? ''),
          campaignId: campaignId ?? '',
        }),
      );
    },
    [campaignId, projectsId],
  );

  const handleSubmitCampaignPosts = useCallback(
    async (values: IUpdateCampaignPostDTO) => {
      await checkFieldValidation(values, form);
      const campaignPostDTO = new CampaignPostDTO(values);
      const isDomainIncorrect = !domainValidation(
        values.socialChannel ?? '',
        values.link ?? '',
      );

      if (isDomainIncorrect) {
        return showErrorToast({
          message: t('proof_of_delivery_domain_channels_error_toast'),
        });
      }

      try {
        if (currentPostData) {
          await dispatch(
            updateCampaignPost({
              ...campaignPostDTO,
              campaignId,
              id: currentPostData?.id,
              sk: currentPostData?.sk,
            }),
          );
        } else {
          await dispatch(
            addCampaignPost({
              ...campaignPostDTO,
              id: campaignId,
            }),
          );
        }

        handleCloseModal();

        await dispatch(
          getProofOfDeliveryDataDetails({
            id: getUserId(projectsId ?? ''),
            campaignId: campaignId ?? '',
          }),
        );

        form.resetFields();
        showSuccessToast({
          message: t('success_toast'),
        });
      } catch (e: unknown) {
        showErrorToast({
          message: t('error_toast'),
        });
      }
    },
    [campaignId, projectsId, currentPostData],
  );

  useEffect(() => {
    if (projectsId && campaignId) {
      dispatch(
        getDeliveryStatistics({
          id: getUserId(projectsId),
          campaignId,
        }),
      );
      dispatch(getCampaignKols(campaignId));
    }
  }, [projectsId, campaignId]);

  useEffect(() => {
    if (isCampaign && projectsId) {
      dispatch(getProjectDetails(getUserId(projectsId)));
    }
  }, [projectsId, isCampaign]);

  useEffect(
    () => (): void => {
      dispatch(resetMarketingList());
      dispatch(resetProjectsState());
    },
    [dispatch],
  );

  if (campaignId || isCampaign)
    return (
      <MarketingDeliveryInfo
        projectId={projectsId}
        formInstance={form}
        campaignId={campaignId as string}
        kolIdFilter={kolIdFilter}
        data={marketingDeliveryInfo}
        campaignKols={campaignKols as ICampaignKols[]}
        getNextData={getProofOfDeliveryDetailsData}
        deliveryStatistics={deliveryStatistics}
        projectData={projectDetails as IProject}
        handleSubmit={handleSubmitCampaignPosts}
        handleDeleteCampaignPost={handleDeleteCampaignPost}
        isLoading={isLoading}
        isCampaign={isCampaign}
        isOpened={isOpened}
        openModal={openModal}
        handleClose={handleCloseModal}
        currentPostData={
          (currentPostData as IMarketingDeliveryInfo) ?? initialPost
        }
        openUpdateModal={openModal}
      />
    );

  return (
    <ProofOfDelivery
      marketingDelivery={marketingDelivery}
      getNextData={getNextMarketingDelivery}
      isLoading={isLoading}
      projectId={projectsId}
    />
  );
};

export default ProofOfDeliveryContainer;
