import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles, SHADOWS } from '@/theme';
import { BlockLayout } from '@/ui';

export const DetailsWrapper = styled(BlockLayout)`
  display: flex;
  width: auto;
  background: ${COLORS.WHITE};
  margin-bottom: 0.5rem;
  border-radius: 1rem;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  margin-bottom: 1.5rem;
  align-items: flex-start;
  box-shadow: ${SHADOWS.BIG};
`;

export const StyledBlockTitle = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
    gap: 1.5rem;
  }
`;

export const StyledTitle = styled.div<{ $isColored?: boolean }>`
  ${pickThemeFontStyles('18', '25', 'BOLD')};
  color: ${({ $isColored }) =>
    $isColored ? COLORS.ORANGE_PRIMARY : COLORS.LIQUORICE_BLACK};
`;

export const StyledText = styled.div<{ $isBold?: boolean }>`
  margin: 0.5rem 0rem;
  ${({ $isBold }) =>
    $isBold
      ? pickThemeFontStyles('14', '20', 'BOLD')
      : pickThemeFontStyles('14', '20', 'SEMI_NORMAL')};
`;

export const StyledInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${pickThemeFontStyles('14', '25', 'NORMAL')};
  border-bottom: 1px solid ${COLORS.SOLITUDE_GRAY};
  color: ${COLORS.CORN_FLOWER_BLUE};

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }
`;

export const StyledA = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${COLORS.SOLITUDE_DARK};
  width: 100%;
  border-bottom: 1px solid ${COLORS.SOLITUDE_GRAY};
  ${pickThemeFontStyles('14', '16', 'SEMI_NORMAL')};

  &:last-child {
    border-bottom: 0;
  }
`;

export const StyledResources = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;
  svg {
    transform: scale(0.6);
  }
`;

export const StyledDangerousHtml = styled.div`
  width: 100%;
  img {
    width: 100%;
  }
  iframe {
    width: 100%;
    height: 100%;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    iframe {
      height: 42rem;
    }
  }
`;
