// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'antd';

// Components
import { TextEditorWithMedia, Title } from '@/ui';
import {
  StyledFormItemWrapper,
  StyledUploadFilePlaceholder,
  StyledResources,
} from './PresentationStyles';
import {
  StyledWrapper,
  StyledFrom,
  StyledFormItem,
  StyledText,
} from '../Styles/Styles';
import { IFormInstance } from '@/components';
import { UploadIcon } from '@/icons';
import FileListUploader from './FileListUploader';

// Modules
import {
  DOCS_MIME_TYPES,
  FileUpload,
  ImageUpload,
  MAX_IMAGE_SIZE,
} from '@/modules/Uploaders';

// Models
import { IFundingRound } from '@/models/fundingRound.model';

// Helpers
import { IFundingRoundFields } from '../../types';
import { useGetFormValidationRules } from '../../hooks';

interface IPresentation {
  fundingRound: IFundingRound | null;
  formInstance: IFormInstance;
  handleChange?: (value: IFundingRound, values: IFundingRound) => void;
  handleFileUpload: (value: File) => Promise<string | undefined>;
  forceUpdateValue: (fieldName: string, value: string) => void;
  isLoading?: boolean;
  disabled?: boolean;
  fileLoading?: boolean;
}

const Presentation = ({
  formInstance,
  fundingRound,
  handleChange,
  handleFileUpload,
  forceUpdateValue,
  disabled,
  fileLoading,
}: IPresentation) => {
  const { t } = useTranslation(['fundingRound']);
  const validationRules = useGetFormValidationRules();

  if (!fundingRound) return <Skeleton />;

  return (
    <StyledWrapper>
      <StyledFrom
        form={formInstance}
        initialValues={fundingRound}
        onValuesChange={handleChange}
        disabled={disabled}
      >
        <StyledFormItemWrapper>
          <Title $type="h3">
            {t('funding_details_presentation_resources_title')}
          </Title>
          <StyledText>
            {t('funding_details_presentation_resources_description')}
          </StyledText>
          <StyledResources>
            <StyledFormItem
              name={IFundingRoundFields.TOKENOMICS}
              rules={validationRules[IFundingRoundFields.TOKENOMICS]}
              label={t('funding_details_presentation_tokenomics_title')}
            >
              <FileUpload
                fileMaxSize={MAX_IMAGE_SIZE}
                acceptance={DOCS_MIME_TYPES}
                isLoading={fileLoading}
              >
                <StyledUploadFilePlaceholder>
                  <UploadIcon />
                  {t('file_upload_placeholder', { ns: 'common' })}
                </StyledUploadFilePlaceholder>
              </FileUpload>
            </StyledFormItem>
            <StyledFormItem
              name={IFundingRoundFields.PITCH_DECK}
              rules={validationRules[IFundingRoundFields.PITCH_DECK]}
              label={t('funding_details_presentation_pitch_deck_title')}
            >
              <FileUpload
                fileMaxSize={MAX_IMAGE_SIZE}
                acceptance={DOCS_MIME_TYPES}
                isLoading={fileLoading}
              >
                <StyledUploadFilePlaceholder>
                  <UploadIcon />
                  {t('file_upload_placeholder', { ns: 'common' })}
                </StyledUploadFilePlaceholder>
              </FileUpload>
            </StyledFormItem>

            <StyledFormItem name={IFundingRoundFields.RESOURCES} hidden />
            {t('funding_details_presentation_additional_resources_title')}
            <FileListUploader formInstance={formInstance} />
          </StyledResources>
        </StyledFormItemWrapper>

        <StyledFormItemWrapper>
          <Title $type="h3">
            {t('funding_details_presentation_banner_image')}
          </Title>
          <StyledText>
            {t('funding_details_presentation_banner_image_description')}
          </StyledText>
          <StyledText>
            {t('funding_details_presentation_banner_image_size')}
          </StyledText>
          <StyledFormItem
            name={IFundingRoundFields.BANNER_IMAGE}
            rules={validationRules[IFundingRoundFields.BANNER_IMAGE]}
            label={t('funding_details_presentation_banner_image')}
          >
            <ImageUpload isLoading={fileLoading} />
          </StyledFormItem>
        </StyledFormItemWrapper>

        <StyledFormItemWrapper>
          <Title $type="h3">
            {t('funding_details_presentation_token_image')}
          </Title>
          <StyledText>
            {t('funding_details_presentation_token_image_description')}
          </StyledText>
          <StyledFormItem
            name={IFundingRoundFields.TOKEN_IMAGE}
            rules={validationRules[IFundingRoundFields.TOKEN_IMAGE]}
            label={t('funding_details_presentation_token_image')}
          >
            <ImageUpload isLoading={fileLoading} />
          </StyledFormItem>
        </StyledFormItemWrapper>

        <StyledFormItemWrapper>
          <Title $type="h3">{t('funding_details_presentation_roadmap')}</Title>
          <StyledText>
            {t('funding_details_presentation_roadmap_description')}
          </StyledText>
          <StyledFormItem
            name={IFundingRoundFields.ROADMAP}
            rules={validationRules[IFundingRoundFields.ROADMAP]}
          >
            <TextEditorWithMedia
              handleFileUpload={handleFileUpload}
              forceUpdateValue={(value: string) =>
                forceUpdateValue(IFundingRoundFields.ROADMAP, value)
              }
            />
          </StyledFormItem>
        </StyledFormItemWrapper>

        <StyledFormItemWrapper>
          <Title $type="h3">
            {t('funding_details_presentation_who_involved')}
          </Title>
          <StyledText>
            {t('funding_details_presentation_who_involved_description')}
          </StyledText>
          <StyledFormItem
            name={IFundingRoundFields.INVOLVED}
            rules={validationRules[IFundingRoundFields.INVOLVED]}
          >
            <TextEditorWithMedia
              handleFileUpload={handleFileUpload}
              forceUpdateValue={(value: string) =>
                forceUpdateValue(IFundingRoundFields.INVOLVED, value)
              }
            />
          </StyledFormItem>
        </StyledFormItemWrapper>

        <StyledFormItemWrapper>
          <Title $type="h3">{t('funding_details_presentation_strategy')}</Title>
          <StyledText>
            {t('funding_details_presentation_strategy_description')}
          </StyledText>
          <StyledFormItem
            name={IFundingRoundFields.MARKETING_STRATEGY}
            rules={validationRules[IFundingRoundFields.MARKETING_STRATEGY]}
          >
            <TextEditorWithMedia
              handleFileUpload={handleFileUpload}
              forceUpdateValue={(value: string) =>
                forceUpdateValue(IFundingRoundFields.MARKETING_STRATEGY, value)
              }
            />
          </StyledFormItem>
        </StyledFormItemWrapper>

        <StyledFormItemWrapper>
          <Title $type="h3">
            {t('funding_details_presentation_token_metrics')}
          </Title>
          <StyledText>
            {t('funding_details_presentation_token_metrics_description')}
          </StyledText>
          <StyledFormItem
            name={IFundingRoundFields.TOKEN_METRICS}
            rules={validationRules[IFundingRoundFields.TOKEN_METRICS]}
          >
            <TextEditorWithMedia
              handleFileUpload={handleFileUpload}
              forceUpdateValue={(value: string) =>
                forceUpdateValue(IFundingRoundFields.TOKEN_METRICS, value)
              }
            />
          </StyledFormItem>
        </StyledFormItemWrapper>

        <StyledFormItemWrapper>
          <Title $type="h3">{t('funding_details_presentation_usps')}</Title>
          <StyledText>
            {t('funding_details_presentation_usps_description')}
          </StyledText>
          <StyledFormItem
            name={IFundingRoundFields.USPS}
            rules={validationRules[IFundingRoundFields.USPS]}
          >
            <TextEditorWithMedia
              handleFileUpload={handleFileUpload}
              forceUpdateValue={(value: string) =>
                forceUpdateValue(IFundingRoundFields.USPS, value)
              }
            />
          </StyledFormItem>
        </StyledFormItemWrapper>
      </StyledFrom>
    </StyledWrapper>
  );
};

export default Presentation;
