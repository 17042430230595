import React from 'react';
import { ProgressProps } from 'antd';
import {
  StyledProgress,
  Wrapper,
  PercentsWrap,
  StyledPercent,
} from './ProgressStyles';

interface IProgress extends ProgressProps {
  percent?: number;
  isPercentShown?: boolean;
}

const Progress = ({
  percent,
  isPercentShown = true,
  ...props
}: IProgress): JSX.Element => (
  <Wrapper>
    <StyledProgress percent={percent} {...props} showInfo={false} />
    <PercentsWrap>
      {isPercentShown && (
        <>
          <StyledPercent>0%</StyledPercent>
          <StyledPercent>100%</StyledPercent>
        </>
      )}
    </PercentsWrap>
  </Wrapper>
);

export default Progress;
