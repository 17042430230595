import React, { useCallback } from 'react';
import debounce from 'lodash.debounce';

// Components
import { KOLsRanking } from '../../components';

// Store
import { campaignsSliceSelector } from '../../feature/selectors';
import {
  getKolsRankingData,
  simulateKolsRanking,
} from '../../feature/actionCreators';

// Modules
import {
  exportKOLsRankingList,
  selectDataExportsIsLoading,
} from '@/modules/DataExports';

// Helpers
import { useAppDispatch, useAppState } from '@/hooks';
import { getUserId } from '@/utils';

const KOLsRankingContainer = () => {
  const dispatch = useAppDispatch();
  const { campaignDetails, kolsRankingData, isLoading } = useAppState(
    campaignsSliceSelector,
  );
  const exportLoading = useAppState(selectDataExportsIsLoading);

  const getNextKolsRanking = useCallback(
    debounce(() => {
      if (isLoading || !kolsRankingData?.hasMore) return;
      const lastItem = kolsRankingData?.items?.at(-1);

      dispatch(
        getKolsRankingData({
          startSk: lastItem?.sk,
          projectId: getUserId(campaignDetails?.projectId ?? ''),
        }),
      );
    }, 1000),
    [isLoading, kolsRankingData?.hasMore, campaignDetails],
  );

  const exportKolsRankingList = async () => {
    dispatch(
      exportKOLsRankingList(getUserId(campaignDetails?.projectId ?? '')),
    );
  };

  const refreshKolsRanking = useCallback(
    debounce(() => {
      if (isLoading) return;

      dispatch(
        getKolsRankingData({
          projectId: getUserId(campaignDetails?.projectId ?? ''),
        }),
      );
    }, 1000),
    [isLoading, kolsRankingData?.hasMore, campaignDetails],
  );

  const simulateKOLsRankingCallback = useCallback(
    debounce(async () => {
      await dispatch(
        simulateKolsRanking(getUserId(campaignDetails?.projectId ?? '')),
      ).unwrap();
      refreshKolsRanking();
    }, 1000),
    [isLoading, kolsRankingData?.hasMore, campaignDetails],
  );

  return (
    <KOLsRanking
      getNextData={getNextKolsRanking}
      refreshKolsRanking={refreshKolsRanking}
      simulateKOLsRankingCallback={simulateKOLsRankingCallback}
      isLoading={isLoading}
      kolsRankingData={kolsRankingData}
      exportKolsRankingList={exportKolsRankingList}
      exportLoading={exportLoading}
    />
  );
};

export default KOLsRankingContainer;
