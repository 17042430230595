// Globals
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { StyledTitles } from './PayoutListStyles';
import { PayoutListWrapper, StyledInfoTitles } from '../Styled';
import { Spinner, StyledSpinnerWrap } from '@/ui';
import PayoutCard from '../PayoutCard/PayoutCard';

// Modules
import { IPayoutList } from '@/models/payout.model';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

interface IPayout {
  payoutList?: IPayoutList[] | null;
  isLoading: boolean;
  completeCampaign: (campaignId: string, isRequest?: boolean) => void;
}

const PayoutList = ({ payoutList, isLoading, completeCampaign }: IPayout) => {
  const { t } = useTranslation(['payout']);
  const loaderRef = useRef<HTMLDivElement>(null);

  const mappedPayoutList = useMemo(() => {
    return payoutList?.map((data) => {
      return (
        <PayoutCard
          data={data}
          key={data?.id}
          isLoading={isLoading}
          completeCampaign={completeCampaign}
        />
      );
    });
  }, [payoutList]);

  return (
    <PayoutListWrapper>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('payout_campaign_title')}</StyledTitles>
          <StyledTitles>{t('payout_marketing_end_title')}</StyledTitles>
          <StyledTitles>{t('payout_project_title')}</StyledTitles>
          <StyledTitles>{t('payout_planned_deliveries_title')}</StyledTitles>
          <StyledTitles>{t('payout_confirmed_deliveries_title')}</StyledTitles>
          <StyledTitles>{t('payout_total_payout_title')}</StyledTitles>
          <StyledTitles>{t('payout_account_manager_title')}</StyledTitles>
          <StyledTitles>{t('payout_finance_manager_title')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedPayoutList}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </PayoutListWrapper>
  );
};

export default PayoutList;
