import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import {
  MARKETING_DELIVERY_SLICE_NAME,
  MarketingDeliveryState,
} from './models';

export const proofOfDeliverySliceSelector = (
  state: RootState,
): MarketingDeliveryState => state[MARKETING_DELIVERY_SLICE_NAME];

export const selectDeliveryStatistics = createSelector(
  proofOfDeliverySliceSelector,
  (state: MarketingDeliveryState) => state.deliveryStatistics,
);
