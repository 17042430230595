import { IDocumentTemplateData } from '@/models/documents.model';
import { IGetListParamsBase } from '@/models/api.model';

export enum SettingsEndpoints {
  GET_SETTINGS = '/admin/settings',
  GET_TOP_BARS_SETTINGS = '/admin/settings/top-bars',
  GET_AVERAGE_SOCIAL_DATA = '/admin/settings/average-social-data',
  NFT_CREDITS_BUCKET = '/admin/settings/nft-credits-bucket',
  AUTOMATIC_APPROVAL_SOCIALS_THRESHOLDS = '/admin/settings/automatic-approval-socials-thresholds',
}

export enum Settings {
  FOLLOWERS_MULTIPLIER = 'FOLLOWERS_MULTIPLIER',
  INVESTMENT_TARGET_BIAS_PERCENT = 'INVESTMENT_TARGET_BIAS_PERCENT',
  FIRST_STAGE_DAYS = 'FIRST_STAGE_DAYS',
  SOCIAL_NETWORK_PRICE = 'SOCIAL_NETWORK_PRICE',
  AFFILIATE_COMMISSION = 'AFFILIATE_COMMISSION',
  HOURS_FOR_CONTRACT_SIGNING = 'HOURS_FOR_CONTRACT_SIGNING',
  DAYS_FOR_MONEY_TRANSFERRING = 'DAYS_FOR_MONEY_TRANSFERRING',
  DAYS_FOR_MONEY_TRANSFERRING_BEFORE_MARKETING_START = 'DAYS_FOR_MONEY_TRANSFERRING_BEFORE_MARKETING_START',
  DAYS_FOR_LABEL_NEW = 'DAYS_FOR_LABEL_NEW',
  DOCUSIGN_DOCUMENTS_TEMPLATES = 'DOCUSIGN_DOCUMENTS_TEMPLATES',
  PARTICIPATION = 'PARTICIPATION',
  CAMPAIGN_COMMISSION = 'CAMPAIGN_COMMISSION',
  MARKETING_AFFILIATE_COMMISSION = 'MARKETING_AFFILIATE_COMMISSION',
  CAMPAIGN_SIGN_UP_PERIOD_OFFSET = 'CAMPAIGN_SIGN_UP_PERIOD_OFFSET',
  FUNDING_ROUND_MAXIMUM_PARTICIPATION_FOR_NFT_HOLDERS = 'FUNDING_ROUND_MAXIMUM_PARTICIPATION_FOR_NFT_HOLDERS',
}

export enum TopBarsTypes {
  NFT = 'NFT',
  MARKETING = 'MARKETING',
  FUNDING_ROUND = 'FUNDING_ROUND',
}

export interface ISocialNetworkPrice {
  youtube: number;
  twitter: number;
  instagram: number;
  facebook: number;
}

export interface ITopBarBase {
  text: string;
  active: boolean;
}

export interface ITopBar extends ITopBarBase {
  priority: boolean;
}

export interface IParticipation {
  max: number;
  min: number;
}

export enum AverageSocialDataTypes {
  POST_PRICES = 'POST_PRICES',
  IMPRESSION = 'IMPRESSION',
}

export enum FundingRoundsDataTypes {
  PARTICIPATION = 'participation',
}

export interface IAverageSocialDataItem {
  [key: string]: number;
  youtube: number;
  twitter: number;
  instagram: number;
  facebook: number;
  reddit: number;
  tiktok: number;
  telegram: number;
  linkedin: number;
}

export interface INftCreditsBucketHistoryItem {
  sk: string;
  id?: string;
  balanceBefore?: number;
  balanceAfter?: number;
  usedAmount?: number;
  usedBy?: string;
  created?: string;
  title?: string;
}

// ============== Responses ==============

export interface INftCreditsBucketHistoryResponse {
  items: INftCreditsBucketHistoryItem[];
  hasMore: boolean;
}

// ============== DTO ==============

export interface ISettingsDTO {
  [Settings.FOLLOWERS_MULTIPLIER]?: number;
  [Settings.MARKETING_AFFILIATE_COMMISSION]?: number;
  [Settings.INVESTMENT_TARGET_BIAS_PERCENT]?: number;
  [Settings.FIRST_STAGE_DAYS]?: number;
  [Settings.SOCIAL_NETWORK_PRICE]?: ISocialNetworkPrice;
  [Settings.HOURS_FOR_CONTRACT_SIGNING]?: number;
  [Settings.DAYS_FOR_MONEY_TRANSFERRING]?: number;
  [Settings.DAYS_FOR_MONEY_TRANSFERRING_BEFORE_MARKETING_START]?: number;
  [Settings.DAYS_FOR_LABEL_NEW]?: number;
  [Settings.CAMPAIGN_COMMISSION]?: number;
  [Settings.CAMPAIGN_SIGN_UP_PERIOD_OFFSET]?: number;
  [Settings.FUNDING_ROUND_MAXIMUM_PARTICIPATION_FOR_NFT_HOLDERS]?: number;
  [Settings.DOCUSIGN_DOCUMENTS_TEMPLATES]?: IDocumentTemplateData[];
  [Settings.PARTICIPATION]?: IParticipation;
}

export interface ITopBarSettingsDTO {
  [TopBarsTypes.NFT]?: ITopBarBase;
  [TopBarsTypes.MARKETING]?: ITopBar;
  [TopBarsTypes.FUNDING_ROUND]?: ITopBar;
}

export interface AutomaticApprovalDTO {
  minFollowers: number;
  minEngagementRate: number;
  minReachRate: number;
  maxCoordinatedFollowers: number;
  maxMonthsReportValidity: number;
}

export interface IAverageSocialData {
  [AverageSocialDataTypes.POST_PRICES]: IAverageSocialDataItem;
  [AverageSocialDataTypes.IMPRESSION]: IAverageSocialDataItem;
}

export interface INFTCreditsBucketData {
  nftCreditsBucketBalance: number;
}

export interface IGetNFTCreditsBucketHistoryDTO extends IGetListParamsBase {
  startSk?: string;
}
